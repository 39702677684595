import {useCallback, useEffect, useState} from "react";
import {
    getTeacherCompetencyDocuments,
    deletePersonalDocuments,
    createPersonalDocuments
} from "../../../../../services/CompetencyService";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";
import s from "../Profile.module.scss";
import f from '../../../../../static/fonts.module.scss'
import Fancybox from "../../../../_BASE/UI/Fancybox/Fancybox";
import Button from "../../../../_BASE/UI/Button/Button";
import FileInput from "../../../../_BASE/UI/Input/FileInput/FileInput";
import {notifications} from "../../../../../index";
import {uploadImage} from "../../../../../services/FileService";

const DocumentModal = ({closeFunc, id}) => {
    const [urls, setUrls] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteDocuments = async () => {
        try {
            setIsDeleting(true)
            await deletePersonalDocuments(id)
            await getUrl()
            setDeleteModal(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsDeleting(false)
        }
    }

    const getUrl = useCallback(async () => {
        try {
            setIsLoading(true)
            const data = await getTeacherCompetencyDocuments(id)
            setUrls(data)
        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    }, [id])

    useEffect(() => {
        getUrl()
    }, [getUrl])


    return <ModalWindow closeFunc={closeFunc} title={"Документы"}>
        <div className={s.documentsWrap}>
            {
                isLoading
                    ? <Preloader/>
                    : !urls?.photoOneLink && !urls?.photoTwoLink
                        ? <>
                            <EmptyList title={'Документы не найдены'}/>
                            {
                                <div>
                                    <Button text={'Добавить персональные документы'} click={() => setAddModal(true)}/>
                                </div>
                            }
                            {
                                addModal && <AddDocumentsModal updateData={getUrl} userID={id} setAddModal={setAddModal}/>
                            }
                        </>
                        : <div className={s.documentsWrap}>
                            <div className={s.documentModal}>
                                <Fancybox>
                                    {
                                        urls?.photoOneLink &&
                                        <button className={s.docPreview} data-fancybox="gallery"
                                                data-src={urls?.photoOneLink}>
                                            <img className={s.docPreview} src={urls?.photoOneLink} alt={''}/>
                                        </button>
                                    }
                                    {
                                        urls?.photoTwoLink &&
                                        <button className={s.docPreview} data-fancybox="gallery"
                                                data-src={urls?.photoTwoLink}>
                                            <img className={s.docPreview} src={urls?.photoTwoLink} alt={''}/>
                                        </button>
                                    }

                                </Fancybox>
                            </div>
                            <div>
                                <Button text={'Удалить персональные документы'} click={() => setDeleteModal(true)}/>
                            </div>
                            {
                                deleteModal && <ModalWindow closeFunc={setDeleteModal}
                                                            title={'Удаление персональных документов'}
                                                            type={'small'}
                                                            primaryAction={() => deleteDocuments()}
                                                            primaryButtonTitle={'Удалить'}
                                                            primaryButtonIsDisabled={isDeleting}
                                >
                                    <p>Вы действительно хотите удалить персональные документы?</p>
                                </ModalWindow>
                            }
                        </div>
            }
        </div>
    </ModalWindow>
}

const AddDocumentsModal = ({setAddModal, userID, updateData}) => {
    const [document1, setDocument1] = useState()
    const [doc1URL, setDoc1URL] = useState()
    const [document2, setDocument2] = useState()
    const [doc2URL, setDoc2URL] = useState()

    const [isLoading, setIsLoading] = useState(false)

    const uploadDocuments = async () => {
        try {
            setIsLoading(true)
            // загрузка фото
            const photoObject1 = await uploadImage(document1)
            const photoObject2 = await uploadImage(document2)
            if (photoObject1 && photoObject2) {
                await createPersonalDocuments(photoObject1.fileId, photoObject1.fileLink, photoObject2.fileId, photoObject2.fileLink, userID)
            }
            await updateData()
        } catch (e){
            console.log(e)
            notifications.addNotification('Что-то пошло не так...')
        } finally {
            setAddModal(false)
        }
    }

    return <ModalWindow closeFunc={setAddModal}
                        title={'Добавление персональных документов'}
                        type={'small'}
                        primaryAction={uploadDocuments}
                        primaryButtonTitle={'Загрузить документы'}
                        primaryButtonIsDisabled={!(document1 && document2) || isLoading}
    >
        <div className={s.createWrapper}>
            <div className={s.createBlock}>
                <p className={f.boldH6}>Фотография диплома</p>
                <FileInput
                    imageUrl={doc1URL} setImageUrl={setDoc1URL}
                    imageObject={document1} setImageObject={setDocument1}

                />
            </div>
            <div className={s.createBlock}>
                <p className={f.boldH6}>Фотография с паспортом</p>
                <FileInput
                    imageUrl={doc2URL} setImageUrl={setDoc2URL}
                    imageObject={document2} setImageObject={setDocument2}
                />
            </div>
        </div>
    </ModalWindow>
}

export default DocumentModal
