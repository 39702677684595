import $auth from "../http";
import {notifications} from "../index";

//Получение статистики по самых популярных категорий обращений для системы или агента ТП
export const getPopularCategoriesByAgent = async (period = "DAY") => {
    try {
        const data = await $auth.get(`/v1/statistic/support/s1?period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по количеству обращений в разные временные периоды
export const getTicketsCount = async (period, type) => {
    try {
        const data = await $auth.get(`/v1/statistic/support/s2?period=${period}&type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по самому работоспособному агенту ТПx
export const getAgentsActivity = async (period) => {
    try {
        const data = await $auth.get(`/v1/statistic/support/s3?period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получить количество заказов для системы или пользователя
export const getOrdersCount = async (period, statusCode) => {
    try {
        const data = await $auth.get(`/v1/statistic/order/s1?period=${period}&orderStatusCode=${statusCode}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получить ТОП-10 заказчиков или исполнителей
export const getTopUsers = async (userType, period) => {
    try {
        const data = await $auth.get(`/v1/statistic/order/s2?userType=${userType}&period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по cамым частым компетенциям для которых не был найден исполнитель
export const getNotFoundCompetenciesCount = async (period, type) => {
    try {
        const data = await $auth.get(`/v1/statistic/order/s4?period=${period}&type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по количеству заказов для которых не был найден исполнитель
export const getNotFoundOrdersCount = async (period) => {
    try {
        const data = await $auth.get(`/v1/statistic/order/s3?period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по cамым частым компетенциям для которых идет поиск исполнителей
export const getPopularCategories = async (period, type) => {
    try {
        const data = await $auth.get(`/v1/statistic/order/s5?period=${period}&type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получение количества регистрируемых пользователей
export const getRegisteredUsers = async (period, userType) => {
    try {
        const data = await $auth.get(`/v1/statistic/auth/s1?period=${period}&userType=${userType}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получить количество активных пользователей
export const getActiveUsers = async (period) => {
    try {
        const data = await $auth.get(`/v1/statistic/auth/s2?period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получить количество активных пользователей
export const getActiveUsersInfo = async () => {
    try {
        const data = await $auth.get(`/v1/statistic/auth/s3`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получить статистику по возрастному распределению пользователей
export const getAgeGroups = async (dateFrom, dateTo, userType) => {
    try {
        const data = await $auth.get(`/v1/statistic/auth/s4?firstDate=${dateFrom}&secondDate=${dateTo}&userType=${userType}`)
        console.log(data.data)
        return data.data.array
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получение статистики по самым часто используемым реферальным промокодам
export const getReferalStats = async (period) => {
    try {
        const data = await $auth.get(`/v1/statistic/payment/s1?period=${period}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение статистики по заработку для все системы или для учителя
export const getIncomeStats = async (period) => {
    try {
        const data = await $auth.get(`/v1/statistic/payment/s2?period=${period}`)
        return data.data.data ?? 0
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}


//Получения статистики по топу предметов, которые приносят больше всего денег
export const getMostProfitableCategories = async (period, type) => {
    try {
        const data = await $auth.get(`/v1/statistic/payment/s3?period=${period}&type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение суммы стоимости заказов для системы или учителя которые находятся на гарантии или на корректировке
export const getGuaranteeStats = async () => {
    try {
        const data = await $auth.get(`/v1/statistic/payment/s4`)
        return data.data.data ?? 0
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества активных учителей
export const getActiveTeacher = async (type) => {
    try {
        const data = await $auth.get(`/v1/statistic/active-teacher/s1?type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение количества профилей компетенций учеников и учителей
export const getCompetencyProfilesCount = async (type) => {
    try {
        const data = await $auth.get(`/v1/statistic/competency/s1?type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Получение самых популярных компетенций для учителя
export const getPopularCompetencies = async (type) => {
    try {
        const data = await $auth.get(`/v1/statistic/competency/s2?type=${type}`)
        return data.data.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}
