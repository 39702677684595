import s from './Blog.module.scss'
import f from '../../../static/fonts.module.scss'
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {useState, useEffect, useCallback} from "react";
import {deleteRecord, getNewsCount, getNewsList} from "../../../services/NewsService";
import {ReactComponent as EditIcon} from "./icon/editIcon.svg";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import StoryList from "./StoryList/StoryList";
import React from "react";
import Table from "../../_BASE/Layout/Table/Table";
import Tabs from "../../_BASE/Layout/TabsTable/Tabs";

const TableRow = ({data, getCount}) => {
    const [deleteModal, setDeleteModal] = useState(false);

    const deleteRecordClick = async () => {
        try {
            await deleteRecord(data.id)
            await getCount()
            setDeleteModal(false)
        } catch (e) {
            throw e
        }
    }

    const getTag = (type) => {
        switch (type) {
            case 'Помогаем учиться' :
                return <p className={`${f.caption} ${s.tag} ${s.yellow}`}>{type}</p>
            case 'Новости' :
                return <p className={`${f.caption} ${s.tag} ${s.blue}`}>{type}</p>
            case 'Для всех' :
                return <p className={`${f.caption} ${s.tag} ${s.pink}`}>{type}</p>
            default :
                return <p>{type}</p>
        }
    }


    const rowActions = [
        {
            label: 'Изменить',
            type: 'link',
            link: `${data.id}/edit`
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }
    ]

    return <>
        <img className={s.img} src={data.imageLink} alt={''}/>
        <p className={f.subtitle1Bold}>{data.title}</p>
        <p className={f.body2}>{data.description}</p>
        <p className={f.subtitle1Bold}>{data.createDate}</p>
        {getTag(data.cTitle)}
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton nested_styles={{background: "white"}} text={<EditIcon/>} type={"icon"}
                            actions={rowActions}/>
        </div>
        {
            deleteModal && <ModalWindow
                title={'Удаление новости'} closeFunc={setDeleteModal} type={'small'}
                primaryAction={() => deleteRecordClick()}
            >
                <div style={{width: 524}}>
                    <p className={f.body1}>Вы действительно хотите удалить новость <span
                        className={f.subtitle1Bold}>"{data.title}"</span>?</p>
                </div>
            </ModalWindow>
        }
    </>
}

const NewsTable = () => {
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    const getData = useCallback(async () => {
        await setIsLoading(true)
        const data = await getNewsList(paginationObject.page, paginationObject.offset)
        setData(data)
        await setIsLoading(false)
    }, [paginationObject.page, paginationObject.offset])

    const getCount = useCallback(async () => {
        const count = await getNewsCount()
        setTotal(count)
        setIsLoading(false)
    }, [])

    useEffect(() => {
        getCount()
    }, [getCount])

    useEffect(() => {
        if (total !== 0) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, total, getData])

    if (isLoading) {
        return <Preloader type={'big'}/>
    }

    return <div className={s.tableWrap}>
        <Table headsArray={['', 'ЗАГОЛОВОК', 'ТЕКСТ', 'ДАТА СОЗДАНИЯ', "Категория", 'ДЕЙСТВИЕ']}
               gridTemplate={'40px 4fr 5fr 2fr 2fr 92px'}
               rowsArray={data.map((item) => <TableRow data={item}
                                                       key={item.id}
                                                       getCount={getCount}
               />)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total} hide={isLoading}
        />
    </div>
}

const Blog = () => {
    const [activeTab, setActiveTab] = useState('Новости');

    const actions = [
        {
            label: 'Создать новость',
            type: 'link',
            link: 'create-news'
        },
        {
            label: 'Создать историю',
            type: 'link',
            link: 'create-story'
        },
    ]

    const tabs = [
        {
            label: 'Новости',
            content: <NewsTable key={window.location.pathname}/>,
            link: "news"
        },
        {
            label: 'Истории',
            content: <StoryList/>,
            link: "stories"
        },
    ]


    return <Tabs tabs={tabs} sideButtons={<DropdownButton text={"Действие"} actions={actions}/>}
                 activeTab={activeTab} setActiveTab={setActiveTab}
    />


}

export default Blog
