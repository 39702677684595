import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import WebFeedback from "../../components/_PAGES/Feedback/WebFeedback/WebFeedback";


const WebFeedbackPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Обратная связь (лендинг)')
    }, [])
    return <WebFeedback/>
}

export default WebFeedbackPage