import s from './SupportTable.module.scss'
import f from '../../../../static/fonts.module.scss'
import Table from "../../../_BASE/Layout/Table/Table";
import Card from "../../Dashboard/Card/Card";
import card1 from './card1.png'
import card2 from './card2.png'
import card3 from './card3.png'
import {useEffect, useState} from "react";
import {getAgentStats, getAgentTickets, getAgentTicketsCount} from "../../../../services/SupportService";
import {userStore} from "../UserInfo";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import TicketModal from "../../TicketModal/TicketModal";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

const Store = userStore

export const getColor = (statusLabel) => {
    if (
        statusLabel === 'В очереди'
    ) return `${s.yellow} ${s.statusTag}`
    if (
        statusLabel === 'В работе'

    ) return `${s.blue} ${s.statusTag}`
    if (
        statusLabel === 'Закрыт'
    ) return `${s.red} ${s.statusTag}`
}

const TableRow = ({rowData}) => {
    const [viewModal, setViewModal] = useState(false);

    const actions = [{
        label: 'Смотреть',
        func: () => setViewModal(true)
    }]

    return <>
        <div className={s.tableTags}>
            <p className={`${f.body2} ${s.tag}`}>{rowData.sTitle}</p>
            <p className={`${f.body2} ${s.tag}`}>{rowData.cTitle}</p>
        </div>
        <p className={f.body2}>{rowData.question}</p>
        <p className={f.body2}>{new Date(Number(rowData.createDate)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })}</p>
        <p className={`${f.subtitle2Bold} ${getColor(rowData.tsTitle)}`}>{rowData.tsTitle}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            viewModal && <TicketModal closeFunc={setViewModal} id={rowData.id}/>
        }
    </>
}

const SupportTable = () => {
    const [inQueueCounter, setInQueueCounter] = useState(0);
    const [inWorkCounter, setInWorkCounter] = useState(0);
    const [closedCounter, setClosedCounter] = useState(0);

    const [data, setData] = useState([]);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    const [countersLoaded, setCountersLoaded] = useState(false);

    useEffect(() => {
        const getRecordCount = async () => {
            try {
                setCountIsLoaded(false)
                const count = await getAgentTicketsCount(Store.authProfile.userId, 'ALL_RECORD')
                setTotal(count)
                setCountIsLoaded(true)
            } catch (e) {
                throw e
            }
        }

        const getCounter = async (type, setter) => {
            const today = new Date().toISOString().split('T')[0]
            const counter = await getAgentStats(
                Store.authProfile.userId,
                type,
                "2000-01-01",
                today
            )
            setter(counter)
        }

        const getAllCounters = async () => {
            try {
                await getCounter('IN_QUEUE', setInQueueCounter)
                await getCounter('IN_WORK', setInWorkCounter)
                await getCounter('CLOSE', setClosedCounter)
                setCountersLoaded(true)
            } catch (e) {
                throw e
            }
        }

        getAllCounters()
        getRecordCount()
    }, [])

    useEffect(() => {
        const getData = async () => {
            try {
                const data = await getAgentTickets(Store.authProfile.userId, 'ALL_RECORD', paginationObject.page, paginationObject.offset)
                setData(data)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }
        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded])

    return <div className={s.wrap}>
        <div className={s.countersGrid}>
            {
                countersLoaded
                    ? <Card title={'Обращений в очереди'}
                            counter={inQueueCounter}
                            icon={<img src={card1} alt={''}/>}
                            theme={'blue'}
                        // tag={'За сегодня'}
                    />
                    : <Preloader/>
            }
            {
                countersLoaded
                    ? <Card title={'Активные обращения'}
                            counter={inWorkCounter}
                            icon={<img src={card2} alt={''}/>}
                        // tag={'За сегодня'}
                    />
                    : <Preloader/>
            }
            {
                countersLoaded
                    ? <Card title={'Закрытые обращения'}
                            counter={closedCounter}
                            icon={<img src={card3} alt={''}/>}
                        // tag={'За сегодня'}
                    />
                    : <Preloader/>
            }


        </div>
        {
            isLoading
                ? <Preloader/>
                : <Table
                    gridTemplate={'3fr 5fr 4fr 2fr 92px'}
                    headsArray={['Категория', "Тема", "Дата создания", "Статус", "Действие"]}
                    rowsArray={data.map(item => <TableRow rowData={item} key={item.id}/>)}
                />
        }
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </div>
}

export default SupportTable
