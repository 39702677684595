import s from "./OrdersChart.module.scss";
import f from "../../../../static/fonts.module.scss";

import {useEffect, useState} from "react";
import {getOrdersCount} from "../../../../services/StatisticService";

import ChartBar from "../DashboardBlock/ChartBar/ChartBar";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Tooltip from "../../../_BASE/UI/Tooltip/Tooltip";

const formatData = (data, period) => {

    if (period === 'WEEK') {
        return data.map((item) => {
            return {
                name: new Date(item[0]).toLocaleDateString('ru-RU', {
                    weekday: 'short'
                }).toUpperCase(),
                value: item[1],
                label: new Date(item[0]).toLocaleDateString('ru-RU', {
                    weekday: 'long'
                }).toUpperCase()
            }
        })
    }

    if (period === 'MONTH') {
        const delta = 5
        const tmpArr = new Array(...data)
        const groups = []
        while (tmpArr?.length > delta) {
            groups.push(tmpArr.splice(0, delta))
        }
        if (tmpArr?.length > 0) {
            groups.push(tmpArr)
        }
        return groups.map((group) => {
            return {
                name: (group[0][0].slice(3, 5) + '-' + group[group.length - 1][0].slice(3, 5).toUpperCase()),
                value: group.reduce((sum, curr) => sum + curr[1], 0)
            }
        })
    }

    if (period === 'YEAR') {
        const delta = 1
        const tmpArr = new Array(...data)
        const groups = []
        while (tmpArr?.length > delta) {
            groups.push(tmpArr.splice(0, delta))
        }
        if (tmpArr?.length > 0) {
            groups.push(tmpArr)
        }
        return groups.map((group) => {
            const stringDate = (new Date(group[0][0]).toLocaleDateString('ru-RU', {
                month: 'short'
            })).toUpperCase()

            return {
                name: stringDate.slice(0, 2),
                value: group.reduce((sum, curr) => sum + curr[1], 0),
                label: stringDate
            }
        })
    }

}


const OrdersChart = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState({
        text: 'Заказов за неделю',
        code: 'WEEK'
    });
    const [totalCounter, setTotalCounter] = useState(0);

    const actions = [
        {
            label: 'Заказов за неделю',
            func: () => setCurrentPeriod({
                text: 'Заказов за неделю',
                code: 'WEEK'
            })
        },
        {
            label: 'Заказов за месяц',
            func: () => setCurrentPeriod({
                text: 'Заказов за месяц',
                code: 'MONTH'
            })
        },
        {
            label: 'Заказов за год',
            func: () => setCurrentPeriod({
                text: 'Заказов за год',
                code: 'YEAR'
            })
        },
    ]


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrdersCount(currentPeriod.code, 'ORDER_CLOSED')
                const summary = req.reduce((sum, current) => sum + current[1], 0)
                const formatted = await formatData(req, currentPeriod.code)

                setTotalCounter(summary)
                setData(formatted)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [currentPeriod])


    const tooltipText = 'В данном графике учитываются только успешно завершенные заказы, то есть с завершенной гарантией'

    return <div className={s.small}>

        <div className={s.finTop}>
            <div className={s.headerWrapper}>
                <h4 className={f.boldH4}>Заказы</h4>
                <Tooltip text={tooltipText}/>
            </div>

            <div className={s.col}>
                <div className={s.counter}>
                    <p className={f.boldH4} style={{marginBottom: 4}}>{totalCounter}</p>
                    <DropdownButton type={'altIcon'} actions={actions}/>
                </div>
                <p className={f.body1}>{currentPeriod.text}</p>
            </div>
        </div>
        <div style={{marginTop: 24}}>
            <ChartBar isLoading={isLoading} height={356} theme={'blue'} data={data}/>
        </div>

    </div>


}

export default OrdersChart