import s from './DeleteModal.module.scss'
import f from '../../../../../../static/fonts.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useState} from "react";
import {archiveTicketCategories} from "../../../../../../services/SupportService";


const DeleteModal = ({closeFunc, updateData, category}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteCategory = async () => {
        try {
            setIsDeleting(true)
            await archiveTicketCategories(category.id)
            await updateData()
            setIsDeleting(false)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        type={'small'}
                        title={'Удаление категории'}
                        primaryButtonIsDisabled={isDeleting}
                        primaryButtonTitle={'Удалить'}
                        primaryAction={() => deleteCategory()}
    >
        <div className={s.wrap}>
            <p className={f.subtitle1Bold}>Вы действительно хотите удалить категорию <span
                className={s.color}>{category.title}</span>?</p>
        </div>
    </ModalWindow>
}

export default DeleteModal