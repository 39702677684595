import {AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis,} from 'recharts';

import style from './ChartArea.module.scss'

import {CustomTooltip} from "../../ChartHelper/ChartHelper";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";


const ChartArea = ({data, height = 320, unit = '', isLoading = false}) => {
    const formatData = (data) => {
        let res = [{
            name: '',
            value: data[0].value - data[0].value / 10,
        }]
        res.push(...data)
        res.push({
            name: '',
            value: data[data.length - 1].value - data[data.length - 1].value / 10,
        })
        return res
    }

    if (!data || data.length === 0) return <Preloader style={{height: height}}/>

    return (
        <div className={style.wrap}>
            {
                isLoading &&
                <div className={style.loader}>
                    <Preloader/>
                </div>
            }
            <ResponsiveContainer height={height} width="100%">
                <AreaChart
                    height={0}
                    data={formatData(data)}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="20%" stopColor="#3C4AE4" stopOpacity={.8}/>
                            <stop offset="100%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <Tooltip
                        content={<CustomTooltip unit={unit}/>}
                    />
                    <XAxis axisLine={false} tickLine={false} dataKey="name"
                           tickMargin={8}
                    />
                    <YAxis axisLine={false} tickLine={false} type={'number'}
                        // tickFormatter={formatYAxis}
                    />
                    <Area type="monotone" dataKey="value" stroke="#3C4AE4" fillOpacity={1} fill="url(#colorUv)"/>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default ChartArea