import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './static/fonts.module.scss'
import './static/reset.scss'
import Store from "./store/auth";
import SystemNotifications from "./components/SystemNotifications/SystemNotifications";
import NotificationsStore from "./store/notifications";

export const store = new Store()
export const notifications = new NotificationsStore()
export const defaultDelay = 300

export const Context = createContext({
    store,
    notifications
})

ReactDOM.render(
    <Context.Provider value={{store, notifications}}>
        <SystemNotifications/>
        <App />
    </Context.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

