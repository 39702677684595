import $auth from "../http";
import {notifications} from "../index";

//Получение списка шаблонов email писем
export const getMailTemplates = async () => {
    try {
        const req = await $auth.get('/v2/mail/templates')
        return req.data
    } catch (e){
        console.log(e)
    }
}


// Изменение идентификатора шаблона
export const editMailTemplate = async (code, unisenderTemplateID) => {
    try {
        const req = await $auth.put('/v2/mail/templates', {}, {
            params:{
                code:code,
                id: unisenderTemplateID
            }
        })
        notifications.addNotification(req.data.message, "OK")
    } catch (e){
        console.log(e)
    }
}

//Получение количества записей обратной связи
export const getWebFeedbackCount = async (status) => {
    try {
        const req = await $auth.get('/v2/mail/write_to_us',{
            params:{
                code: "NUMBER_OF_PAGE",
                status: status,
                offset:20
            }
        })
        return req.data.recordCount
    } catch (e){
        console.log(e)
    }
}

//Получение количества записей обратной связи
export const getWebFeedbackList = async (status, page, offset) => {
    try {
        const req = await $auth.get('/v2/mail/write_to_us',{
            params:{
                code: "LIST",
                status: status,
                offset:offset,
                page: page
            }
        })
        return req.data
    } catch (e){
        console.log(e)
    }
}

//Обновление статуса архивации для записи обращения
export const archiveWebFeedback = async (id) => {
    try {
        const req = await $auth.put(`/v2/mail/write_to_us/${id}`,{},{
            params:{
                status: 0
            }
        })
        return req.data
    } catch (e){
        console.log(e)
    }
}