import s from './Table.module.scss'
import f from '../../../../static/fonts.module.scss'

import {useState, useEffect, useCallback} from "react";
import {deleteTop6Competencies, getTop6Competencies} from "../../../../services/CompetencyService";
import {ReactComponent as EditIcon} from "../editIcon.svg";

import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Table from "../../../_BASE/Layout/Table/Table";

const Top6Table = ({code}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const getData = useCallback(async () => {
        await setIsLoading(true)
        const data = await getTop6Competencies(code)
        setData(data)
        await setIsLoading(false)
    }, [code])

    useEffect(() => {
        getData()
    }, [getData])

    const TableRow = ({data}) => {
        const [deleteModal, setDeleteModal] = useState(false);

        const handleClick = async () => {
            await deleteTop6Competencies(code, data.recordId)
            await getData()
            setDeleteModal(false)
        }

        const actions = [{
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }]

        return <>
            <img className={s.icon} src={data.iconLink} alt={''}/>
            <p className={f.subtitle1Bold}>{data.subjectTitle}</p>
            <p className={f.subtitle1Bold}>{data.optionTitle}</p>
            <div style={{display: 'flex', justifyContent: "center"}}>
                <DropdownButton actions={actions}
                                type={'icon'}
                                nested_styles={{background: 'white'}}
                                text={<EditIcon/>}
                />
            </div>
            {
                deleteModal && <ModalWindow
                    closeFunc={setDeleteModal}
                    type={'small'}
                    title={'Удаление из ТОП-6'}
                    primaryAction={() => handleClick()}
                >
                    <p className={f.body1}>Вы действительно хотите удалить услугу "{data.subjectTitle} — {data.optionTitle}"
                        из ТОП-6 услуг?</p>
                </ModalWindow>
            }
        </>
    }


    if (isLoading) return <Preloader/>


    return <Table gridTemplate={'40px 1fr 3fr 92px'}
                  headsArray={["", "Предмет", "Тип задачи", "Действие"]}
                  rowsArray={data.map(row => <TableRow data={row} key={row.id}/>)}
    />
}
export default Top6Table