import s from './PopularCategories.module.scss'
import RadialChart from "../../RadialChart/Charts";
import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import {getPopularCategories} from "../../../../../services/StatisticService";
import {useEffect, useState} from "react";
import f from "../../../../../static/fonts.module.scss";
import Tooltip from "../../../../_BASE/UI/Tooltip/Tooltip";

const types = [
    {
        text: 'Школьные',
        code: 'SCHOOL'
    }, {
        text: 'ВУЗ',
        code: 'VUZ'
    },
]

const formatData = (data) => {
    return data.map((item) => {
        return {
            name: item.sTitle + ' / ' + item.oTitle,
            value: item.number
        }
    })
}

const PopularCategories = ({period}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [taskType, setTaskType] = useState(types[0]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getPopularCategories(period, taskType.code)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period, taskType])

const tooltipText = 'На данном графике учитываются все заказы в работе, на гарантии, а также успешно закрытые.'

    return <div className={s.wrap}>
        <div className={s.headerWrapper} style={{alignSelf: "flex-end"}}>
            <p  className={f.subtitle1Bold}>Самые популярные категории</p>
            <Tooltip text={tooltipText}/>
        </div>
        <TabsSelector tabs={types} newTab={taskType} setNewTab={setTaskType}/>
        {
            data.length === 0 && !isLoading
                ? <div className={s.emptyStat}>
                    <p className={f.subtitle1Bold}>Заказов нет :(</p>
                </div>
                : <RadialChart data={formatData(data)} isLoading={isLoading}/>
        }
    </div>
}
export default PopularCategories