import s from './TabsSelector.module.scss'
import f from '../../../../../static/fonts.module.scss'

const TabsSelector = ({tabs, setNewTab, newTab}) => {
    return <div className={s.tabs}>
        {
            tabs.map((period, i) =>
                <button
                    key={i}
                    className={`${s.tabButton} ${f.button} ${newTab.text === period.text ? s.active : ''}`}
                    onClick={() => setNewTab(period)}>
                    {period.text}
                </button>
            )
        }
    </div>
}

export default TabsSelector