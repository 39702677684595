import style from "./Slider.module.scss";

const Slider = ({children, width, currentStage}) => {
    const slideCount = children.length

    const slideIndicatorsLayout = children.map((slide, i) => {
        return <div key={i} className={`${style.stageIndicator} ${
            currentStage === i 
            || (i === slideCount-1 && currentStage>=slideCount) 
            || (i === 0 && currentStage<0) 
                ? style.active 
                : ''}`}/>
    })

    const slidesLayout = children.map((slide, i) => {
        return <div key={i} className={`${style.stageWrap}`}>{slide}</div>
    })

    return <>
        <div className={style.stagesWrap}>
            <div className={style.stages} style={{marginLeft: `-${width * currentStage}px`}}>
                {slidesLayout}
            </div>
        </div>
        <div className={style.stageIndicators}>
            {slideIndicatorsLayout}
        </div>
    </>
}

export default Slider