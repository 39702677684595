import UserList from "../../components/_PAGES/UserList/UserList";
import React, {useEffect, useState} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import CreateNewHelpdeskAgentModal
    from "../../components/_PAGES/UserList/CreateNewHelpdeskAgentModal/CreateNewHelpdeskAgentModal";
import Tabs from "../../components/_BASE/Layout/TabsTable/Tabs";
import BlockedUsers from "../../components/_PAGES/UserList/BlockedUsers/BlockedUsers";

const HelpdeskAgents = () => {
    const [createUserModal, setCreateUserModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Активные Агенты');

    const createNewUserModal = <CreateNewHelpdeskAgentModal
        closeModalFunc={setCreateUserModal}
    />

    const tabs = [
        {
            label: 'Активные Агенты',
            content: <UserList
                name={'агента'}
                modal={createNewUserModal}
                modalIsShown={createUserModal}
                openModalFunc={setCreateUserModal}
                roleTitle={'agent'}
                profile_link={'/agents'}
                status={'NOT_BLOCK'}
                key={'active'}
            />
        },
        {
            label: 'Заблокированные Агенты',
            content: <BlockedUsers role={'agent'}/>
        },
        {
            label: 'Удаленные Агенты',
            content: <UserList
                name={'агента'}
                modal={createNewUserModal}
                modalIsShown={createUserModal}
                openModalFunc={setCreateUserModal}
                roleTitle={'agent'}
                profile_link={'/agents'}
                status={'DELETED'}
                key={'DELETED'}
            />
        }
    ]

    useEffect(() => {
        TopNavbarTitle.setNewTitle('Агенты техподдержки')
    }, [])

    return <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}/>


}

export default HelpdeskAgents