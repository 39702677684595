import  s from './MailSettings.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import Table from "../../../_BASE/Layout/Table/Table";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {editMailTemplate, getMailTemplates} from "../../../../services/MailService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";


const MailSettings =()=>{
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            setIsLoading(true)
            const req = await getMailTemplates()
            setData(req)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) return <Preloader type={'big'}/>

    return <Table
        headsArray={['Название шаблона', "Категория шаблона", 'Значение', "Действие"]}
        gridTemplate={'300px 400px 1fr 92px'}
        rowsArray={data.map(row => <SingleRow key={row.code} rowData={row}
                                              getData={getData}
        />)}
    />
}

const SingleRow = ({rowData, getData}) => {
    const [editModal, setEditModal] = useState(false);

    const actions = [
        {
            label: 'Изменить',
            func: () => setEditModal(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        <p className={f.subtitle1Bold}>{rowData.cTitle}</p>
        <p className={f.subtitle1Bold}>{rowData.templateId}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            editModal && <EditModal data={rowData}
                                    closeFunc={setEditModal}
                                    getData={getData}
            />
        }
    </>
}

const EditModal = ({data, closeFunc, getData}) => {
    const [value, setValue] = useState(data.templateId);
    const [isSaving, setIsSaving] = useState(false);

    const saveValue = async () => {
        try {
            setIsSaving(true)
            await editMailTemplate(data.code, value)
            await getData()
            closeFunc(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsSaving(false)
        }
    }


    return <ModalWindow type={'small'}
                        closeFunc={closeFunc}
                        title={'Изменение параметра'}
                        primaryButtonTitle={'Изменить'}
                        primaryButtonIsDisabled={isSaving}
                        primaryAction={saveValue}
    >
        <div className={s.wrap}>
            <p className={f.boldH6}>Название шаблона: <span className={f.body1}>{data.title}</span></p>
            <Input
                value={value}
                setValue={setValue}
                styles={{maxWidth: "100%"}}
            />
        </div>

    </ModalWindow>
}


export default MailSettings