import s from './TopUsers.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useEffect, useState} from "react";

import {getTopUsers} from "../../../../../services/StatisticService";

import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import Table from "../../../../_BASE/Layout/Table/Table";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";

const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const TopUsers = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);


    return <div className={s.wrap}>
        <div className={s.header}>
            <h4 className={f.boldH4}>Самые активные пользователи</h4>
            <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
        </div>
        <div className={s.tables}>
            <SingleTable type={'STUDENT'} period={currentPeriod.code}/>
            <SingleTable type={'TEACHER'} period={currentPeriod.code}/>
        </div>
    </div>
}

const SingleTable = ({type, period}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getTopUsers(type, period)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }
        getData()
    }, [period, type])
    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.singleTableWrap}>
        <h5 className={f.boldH5}>
            {
                type === 'STUDENT'
                    ? 'Заказчик'
                    : 'Исполнитель'
            }
        </h5>
        <Table
            headsArray={['', 'Имя ученика', "Заказов", "Действие"]}
            gridTemplate={'40px 3fr 1fr 92px'}
            rowsArray={data.map((item) => <SingleTableRow rowData={item} key={`user-${item.userId}`}/>)}
        />
    </div>
}

const SingleTableRow = ({rowData}) => {
    const actions = [{
        label: 'Посмотреть профиль',
        func: () => window.open(`/${rowData.user.titleCode === 'STUDENT' ? 'students' : 'teachers'}/${rowData.userId}`)
    }]

    return <>
        <img className={s.listAvatar} src={rowData.user.avatarLink} alt=""/>
        <p className={f.subtitle1Bold}>{rowData.user.surname} {rowData.user.name} {rowData.user.middlename}</p>
        <p className={f.subtitle1Bold}>{rowData.number}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
    </>
}

export default TopUsers