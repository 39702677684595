import s from './ExportModal.module.scss'
import f from '../../../../static/fonts.module.scss'
import {ReactComponent as Icon} from "./icon.svg";
import {useState} from "react";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import $auth from "../../../../http";
import {convertToFile} from "./generateFile";
import {notifications} from "../../../../index";


const ExportModal = ({api, params, title}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dateFrom, setDateFrom] = useState();
    const [dateTo, setDateTo] = useState();
    const [dataIsLoading, setDataIsLoading] = useState(false);

    const formatDate = (date) => {
        return new Date(date).toISOString().split('T')[0]
    }

    const handleCreateClick = async () => {
        try {
            setDataIsLoading(true)
            const date1 = api === '/auth/s4' ? Date.parse(dateFrom) : formatDate(dateFrom)
            const date2 = api === '/auth/s4' ? Date.parse(dateTo) : formatDate(dateTo)

            const paramString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
            const dates = `firstDate=${date1}&secondDate=${date2}`
            const fileName = `${title} с ${dateFrom} по ${dateTo}`
            const data = await $auth.get(`/v1/statistic${api}?period=CUSTOM_DATE&${dates}${paramString.length > 0 ? '&' + paramString : ''}`)
            convertToFile(data, api, fileName)
        } catch (e) {
            notifications.addNotification('Произошла непредвиденная ошибка, попробуйте позже')
            throw e
        } finally {
            setDataIsLoading(false)
        }
    }

    return <>
        <button className={s.button} onClick={() => setIsOpen(true)}>
            <Icon/>
        </button>
        {
            isOpen &&
            <ModalWindow closeFunc={setIsOpen}
                         title={'Экспорт .xlsx файла'}
                         type={'small'}
                         primaryButtonTitle={'Создать'}
                         primaryAction={() => handleCreateClick()}
                         primaryButtonIsDisabled={!dateFrom || !dateTo || dataIsLoading}

            >
                <div className={s.wrap}>
                    <p className={f.body1}>Вы хотите создать файл статистики <span>{title}</span>. Укажите за какой
                        период вы хотите это сделать?</p>
                    <div className={s.dates}>
                        <Input type={'date'} title={'Начало'} value={dateFrom} setValue={setDateFrom}/>
                        <Input type={'date'} title={'Конец'} value={dateTo} setValue={setDateTo}/>
                    </div>
                    {
                        dataIsLoading &&
                        <p style={{color: 'red'}} className={f.body1}>Идет загрузка данных, не закрывайте окно..</p>
                    }
                </div>
            </ModalWindow>
        }
    </>
}

export default ExportModal
