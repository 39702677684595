import {useEffect} from "react";
import UserInfo from "../../../components/_PAGES/UserInfo/UserInfo";
import TopNavbarTitle from "../../../store/TopNavbarTitle";

import LogsTable from "../../../components/_PAGES/UserInfo/LogsTable/LogsTable";
import SupportTable from "../../../components/_PAGES/UserInfo/SupportTable/SupportTable";
import BonusHistoryTable from "../../../components/_PAGES/UserInfo/BonusHistoryTable/BonusHistoryTable";

const AgentPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Агент ТП')
    }, [])

    const tabs = [
        {
            label: 'Обращения',
            content: <SupportTable/>,
            link: 'tickets'
        },
        {
            label: 'События и Логи',
            content: <LogsTable/>,
            link: 'logs'
        },
        {
            label: 'Запросы на зачисления',
            content: <BonusHistoryTable/>,
            link: 'bonuses'
        }

    ]

    return <UserInfo
        tabs={tabs}
        type={'agent'}
    />
}

export default AgentPage