import s from './AgeGroups.module.scss'
import f from '../../../../../../static/fonts.module.scss'
import {useState, useEffect} from "react";
import DropdownButton from "../../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import CustomStackedBarChart from "../../../../../_BASE/Charts/CustomStackedBarChart/CustomStackedBarChart";
import {getAgeGroups} from "../../../../../../services/StatisticService";
import Input from "../../../../../_BASE/UI/Input/Input";
import ExportModal from "../../../ExportModal/ExportModal";

const types = [
    {
        text: 'Ученики',
        code: 'STUDENT'
    },
    {
        text: 'Учителя',
        code: 'TEACHER'
    }]

const formatData = (data) => {
    return data.data.map((group) => {
        return {
            name: group.name,
            ...group.people
        }
    })
}

const currentDate = new Date()

const AgeGroups = () => {
    const [currentType, setCurrentType] = useState(types[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dateFrom, setDateFrom] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 2).toISOString().split('T')[0]);
    const [dateTo, setDateTo] = useState(currentDate.toISOString().split('T')[0]);

    useEffect(() => {
        const getData = async () => {


            try {
                setIsLoading(true)
                const req = await getAgeGroups(Date.parse(dateFrom), Date.parse(dateTo), currentType.code)
                setData(formatData(req))
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()

    }, [currentType, dateFrom, dateTo])

    const actions = types.map((type) => {
        return {
            label: type.text,
            func: () => setCurrentType(type)
        }
    })

    return <div className={s.wrap}>
        <div className={s.header}>
            <div className={s.line}>
                <h4 className={f.boldH4}>Возрастные группы пользователей</h4>
                <ExportModal title={`Возрастные группы (${currentType.text})`}
                             api={'/auth/s4'}
                             params={{
                                 userType: currentType.code
                             }}/>
            </div>
            <div className={s.selector}>
                <div className={s.dates}>
                    <Input value={dateFrom} setValue={setDateFrom}
                           type={'date'}/>
                    <Input value={dateTo} setValue={setDateTo}
                           type={'date'}/>
                </div>
                <div className={s.types}>
                    <p className={f.subtitle1Bold}>{currentType.text}</p>
                    <DropdownButton actions={actions} type={'icon'}/>
                </div>
            </div>
        </div>
        <CustomStackedBarChart data={data} isLoading={isLoading} height={360}
                               dataBarNames={['male', 'female']}/>
    </div>
}

export default AgeGroups
