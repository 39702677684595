//Получение уведомлений пользователя
import $auth from "../http";

export const getNotifications = async (timestamp) => {
    try {
        const res = await $auth.get(`/v1/notifications/get${timestamp?`?lastDate=${timestamp}`:''}`)
        return res.data
    } catch (e) {
        console.log({e})
    }
}