import f from '../../../../static/fonts.module.scss'
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {archivePromo} from "../../../../services/PaymentService";

const DeleteModal = ({closeFunc, id, keyword, updateData, status}) => {

    const deletePromo = async () => {
        try {
            await archivePromo(id, status)
            await updateData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }


    return <ModalWindow
        closeFunc={closeFunc}
        type={'small'}
        title={'Удаление промокода'}
        primaryAction={deletePromo}
    >
        <p className={f.body1}>Вы хотите удалить промокод "{keyword}". Вы уверены? </p>
    </ModalWindow>
}

export default DeleteModal