import {useEffect, useState} from "react";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";
import Radios from "../../../_BASE/UI/Radios/Radios";
import Slider from "../../../_BASE/UI/Slider/Slider";
import AddCompetencyModal from "./AddCompetencyModal/AddCompetencyModal";

import s from './CreateNewTeacherModal.module.scss'
import f from '../../../../static/fonts.module.scss'

import {ReactComponent as SchoolIcon} from "./icon/School.svg";
import {ReactComponent as StudentIcon} from "./icon/Student.svg";
import {ReactComponent as DeleteIcon} from "./icon/delete.svg";
import {ReactComponent as AddIcon} from "./icon/add.svg";
import {registerNewUser} from "../../../../services/AuthService";
import {createNewUserCompetencies, registerNewCompetencyProfile} from "../../../../services/CompetencyService";
import {notifications} from "../../../../index";
import {formatGender} from "../../../../static/utilities";
import Select from "../../../_BASE/UI/Select/Select";

const CreateNewTeacher = ({closeModalFunc, getData}) => {
    const [name, setName] = useState('')
    const [nameIsValid, setNameIsValid] = useState(false)
    const [surname, setSurname] = useState('')
    const [surnameIsValid, setSurnameIsValid] = useState(false)
    const [secondName, setSecondName] = useState('')
    const [secondNameIsValid, setSecondNameIsValid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [phone, setPhone] = useState('89')
    const [phoneIsValid, setPhoneIsValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordIsValid, setPasswordIsValid] = useState(false)
    const [repeatPassword, setRepeatPassword] = useState('')
    const [repeatPasswordIsValid, setRepeatPasswordIsValid] = useState(false)
    const [passwordsAreEqual, setPasswordsAreEqual] = useState(false)
    const [type, setType] = useState('')
    const [typeisValid, setTypeisValid] = useState(false)
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState('Не указано');

    const [step1isValid, setStep1isValid] = useState(false);

    const [addSkillsModal, setAddSkillsModal] = useState(false)
    const [dataStructure, setDataStructure] = useState(new Map())
    const [optionLabels, setOptionLabels] = useState(new Map())

    const [registeredUser, setRegisteredUser] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [registeredCompetencyUser, setRegisteredCompetencyUser] = useState('')


    const [currentStage, setCurrentStage] = useState(0)

    useEffect(() => {
        if (type) setTypeisValid(true)
    }, [type])

    useEffect(() => {
        if (password === repeatPassword && password.length > 5) {
            setPasswordsAreEqual(true)
        } else {
            setPasswordsAreEqual(false)
        }
    }, [password, repeatPassword])

    useEffect(() => {
        if (nameIsValid
            && emailIsValid
            && phoneIsValid
            && surnameIsValid
            && secondNameIsValid
            && passwordIsValid
            && repeatPasswordIsValid
            && passwordsAreEqual) {
            setStep1isValid(true)
        } else {
            setStep1isValid(false)
        }
    }, [nameIsValid, emailIsValid, phoneIsValid, surnameIsValid, secondNameIsValid, passwordIsValid, repeatPasswordIsValid, passwordsAreEqual]);


    //Верстка этапа выбора компетенций
    const skillsTableLayout = Array.from(dataStructure).map(([key, value]) => {
        let rows = []
        value.options.forEach((id) => {
            rows.push(
                <div key={id} className={s.singleTableRow}>
                    <div>
                        <img src={value.iconLink} alt={''}/>
                    </div>
                    <p className={f.body1}>{value.title}</p>
                    <p>{optionLabels.get(id)}</p>
                    <div onClick={() => {
                        dataStructure.get(key).options.delete(id)
                        if (dataStructure.get(key).options.size === 0) {
                            dataStructure.delete(key)
                        }
                        setDataStructure(new Map(dataStructure))
                    }
                    }>
                        <div className={s.deleteIcon} title={'Удалить компетенциию'}>
                            <DeleteIcon/>
                        </div>
                    </div>
                </div>
            )
        })
        return rows
    })

    const handleRegisterNewUser = async () => {
        try {
            setIsLoading(true)
            const request = await registerNewUser({
                roleId: 'teacher',
                name: name,
                surname: surname,
                middlename: secondName,
                phone: phone,
                email: email,
                password: password,
                dob: dob,
                gender: formatGender(gender)
            })
            if (request.status === 200) {
                setRegisteredUser(request.data.user)
                await getData()
                setCurrentStage(1)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    //Регистрация профиля компетенций пользователя
    const handleRegisterCompetencyProfileClick = async () => {
        try {
            setIsLoading(true)
            const request = await registerNewCompetencyProfile({
                institution: type,
                userId: registeredUser.userId,
                name: registeredUser.name,
                surname: registeredUser.surname,
                middlename: registeredUser.middlename,
                userRoleTitleCode: 'TEACHER',
            })
            if (request.status === 200) {
                setRegisteredCompetencyUser(request.data)
                setCurrentStage(2)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    //Регистрация компетенций пользователя
    const handleCreateCompetencyClick = async () => {
        let compIdArray = []
        Array.from(dataStructure).forEach(([key, value]) => {
            value.options.forEach((id) => compIdArray.push(id))
        })
        try {
            setIsLoading(true)
            const request = await createNewUserCompetencies(registeredCompetencyUser.profileId, compIdArray, registeredCompetencyUser.userId)
            if (request.status === 200) {
                notifications.addNotification('Пользователь успешно создан', 'OK')
                closeModalFunc(false)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <ModalWindow
                title={`Создание нового учителя`}
                closeFunc={closeModalFunc}
            >
                <Slider currentStage={currentStage} width={824}>
                    <form className={s.wrapStage}>
                        <p className={`${s.description} ${f.boldH5}`}>Шаг 1. Укажите личную информацию нового
                            учителя</p>
                        <div className={s.wrapStage1Inputs}>
                            <div className={s.tripleRow}>
                                <Input
                                    title={"Укажите имя"}
                                    value={name}
                                    setValue={setName}
                                    validator={'name'}
                                    setIsValid={setNameIsValid}
                                    placeholder={'Иван'}
                                />
                                <Input
                                    title={"Укажите фамилию"}
                                    value={surname}
                                    setValue={setSurname}
                                    validator={'name'}
                                    setIsValid={setSurnameIsValid}
                                    placeholder={'Иванов'}
                                />
                                <Input
                                    title={"Укажите отчество"}
                                    value={secondName}
                                    setValue={setSecondName}
                                    validator={'name'}
                                    setIsValid={setSecondNameIsValid}
                                    placeholder={'Иванович'}
                                />
                            </div>
                            <div className={s.doubleRow}>
                                <Input
                                    title={"Укажите дату рождения"}
                                    value={dob}
                                    type={'date'}
                                    setValue={setDob}
                                    placeholder={'01.01.1990'}
                                    min={'1930-01-01'}
                                    max={new Date().toISOString().split('T')[0]}
                                />
                                <Select options={["Мужской", "Женский", "Не указано"]} title={"Укажите пол"}
                                        selectedOption={gender} setSelectedOption={setGender}/>
                            </div>
                            <div className={s.doubleRow}>
                                <Input
                                    title={"Укажите E-mail"}
                                    value={email}
                                    setValue={setEmail}
                                    validator={'email'}
                                    setIsValid={setEmailIsValid}
                                    placeholder={'ivanovii@gmail.com'}
                                />
                                <Input
                                    title={"Укажите телефон"}
                                    value={phone}
                                    setValue={setPhone}
                                    validator={'phone'}
                                    setIsValid={setPhoneIsValid}
                                    maxLength={11}
                                    placeholder={'89998887766'}
                                />
                            </div>
                            <div className={s.doubleRow}>
                                <Input
                                    title={"Укажите пароль"}
                                    value={password}
                                    setValue={setPassword}
                                    validator={'password'}
                                    setIsValid={setPasswordIsValid}
                                    password
                                    placeholder={'*******'}
                                />
                                <div style={{position: 'relative'}}>
                                    {
                                        !passwordsAreEqual && password.length > 5
                                        && <p className={`${f.subtitle2Bold} ${s.wrongPasswordMessage}`}
                                              style={{color: 'red', marginTop: 4}}>
                                            Пароли не совпадают
                                        </p>
                                    }
                                    <Input
                                        title={"Повторите пароль"}
                                        value={repeatPassword}
                                        setValue={setRepeatPassword}
                                        validator={'password'}
                                        additionalVal={password}
                                        setIsValid={setRepeatPasswordIsValid}
                                        password
                                        placeholder={'*******'}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={s.wrapStage}>
                        <p className={`${s.description} ${f.boldH5}`}>Шаг 2. Выберите должность нового
                            учителя</p>
                        <div style={{marginTop: '-16px'}}>
                            <Radios
                                data={[{
                                    title: 'Школьный преподаватель', icon: <SchoolIcon/>, value: 'SCHOOL'
                                }, {
                                    title: 'Преподователь ВУЗа', icon: <StudentIcon/>, value: 'VUZ'
                                }]}
                                checkedValue={type}
                                setCheckedValue={setType}
                                validator='radio'
                            />
                        </div>
                    </div>
                    <div className={s.wrapStage}>
                        <p className={`${s.description} ${f.boldH5}`}>
                            Шаг 3. Укажите компетенции учителя
                        </p>
                        <div className={s.table}>
                            <div className={s.tableHeader}>
                                <div/>
                                <p className={f.subtitle1Bold}>ПРЕДМЕТ</p>
                                <p className={f.subtitle1Bold}>ОПЦИЯ</p>
                                <p className={f.subtitle1Bold}>ДЕЙСТВИЕ</p>
                            </div>

                            <div className={s.tableGrid}>
                                {skillsTableLayout}
                            </div>
                        </div>
                        <div title={'Добавить учителю новую компетенцию'}>
                            <button onClick={() => setAddSkillsModal(true)} className={s.addSkillsBtn}>
                                <AddIcon/>
                                <p className={f.button}>Добавить компетенцию</p>
                            </button>
                        </div>
                    </div>
                </Slider>
                {
                    //Кнопка регистрации первого этапа
                    currentStage === 0 &&
                    <Button text={"Продолжить"} click={() => handleRegisterNewUser()} disabled={!step1isValid}/>

                }
                {
                    //Кнопка регистрации профиля компетенций
                    currentStage === 1 &&
                    <Button text={"Продолжить"}
                            click={() => handleRegisterCompetencyProfileClick()}
                            disabled={!(typeisValid) || isLoading}
                    />
                }
                {
                    //Кнопка регистрации компетенции
                    currentStage === 2 &&
                    <Button text={"Создать"}
                            click={() => handleCreateCompetencyClick()}
                            disabled={!(dataStructure.size > 0) || isLoading}
                    />

                }
            </ModalWindow>
            {
                addSkillsModal && <AddCompetencyModal
                    closeFunc={() => setAddSkillsModal(false)}
                    role={type}
                    dataStructure={dataStructure}
                    setDataStructure={setDataStructure}
                    optionLabels={optionLabels}
                    setOptionLabels={setOptionLabels}
                />
            }
        </>
    )
}

export default CreateNewTeacher