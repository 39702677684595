import s from './PromoList.module.scss'
import f from '../../../static/fonts.module.scss'

import Input from "../../_BASE/UI/Input/Input";
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";

import {ReactComponent as EditIcon} from "./editIcon.svg";
import {useCallback, useEffect, useState} from "react";
import CreateModal from "./CreateModal/CreateModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import ListModal from "./ListModal/ListModal";
import Card from "../Dashboard/Card/Card";

import icon1 from './icon/1.png'
import icon2 from './icon/2.png'
import icon3 from './icon/3.png'
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import {getPromoCount, getPromoList, getPromoStats, updateRates} from "../../../services/PaymentService";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import CountUp from "react-countup";
import Table from "../../_BASE/Layout/Table/Table";

const mode = 'list';

const PromoList = () => {
    const actions = [{
        label: 'Создание нового промокода',
        func: () => setCreateModal(true)
    }]

    const [createModal, setCreateModal] = useState(false);
    const [editCard2, setEditCard2] = useState(false);
    const [editCard3, setEditCard3] = useState(false);
    const [card2Value, setCard2Value] = useState(200);
    const [card3Value, setCard3Value] = useState(1);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 5
    });
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);
    const [optionsLoading, setOptionsLoading] = useState(false);
    // const [mode, setMode] = useState('list');


    const [card2Counter, setCard2Counter] = useState(0);
    const [card3Counter, setCard3Counter] = useState(0);


    const getPromoOptions = async () => {
        await setOptionsLoading(true)
        const options = await getPromoStats()
        setCard2Counter(Number(options?.referralReward))
        setCard2Value(Number(options?.referralReward))
        setCard3Counter(Number(options?.referralNumberOfOrders))
        setCard3Value(Number(options?.referralNumberOfOrders))
        await setOptionsLoading(false)
    }

    const getData = useCallback(async () => {
        await setIsLoading(true)
        const data = await getPromoList(mode === 'list' ? "1" : "0", paginationObject.offset, paginationObject.page)
        setData(data)
        await setIsLoading(false)
    }, [paginationObject.offset, paginationObject.page])

    const getCount = useCallback(async () => {
        try {
            setCountIsLoaded(false)
            const count = await getPromoCount(mode === 'list' ? "1" : "0")
            setTotal(count)
            setCountIsLoaded(true)
        } catch (e) {
            throw e
        }
    }, [])

    useEffect(() => {
        getPromoOptions()
    }, [])


    useEffect(() => {
        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded, getData])

    useEffect(() => {
        getCount()
    }, [getCount])


    const card2Actions = [{
        label: 'Изменить',
        func: () => setEditCard2(true)
    }]
    const card3Actions = [{
        label: 'Изменить',
        func: () => setEditCard3(true)
    }]

    return <div className={s.wrap}>
        <div className={s.topLine}>
            <div/>
            <div className={s.topButtons}>
                <DropdownButton text={"Действия"} actions={actions}/>
            </div>

        </div>
        <div className={s.table}>
            {
                optionsLoading
                    ? <Preloader/>
                    : <div className={s.cardsWrap}>
                        <Card title={
                            mode === 'list'
                                ? 'Активных промокодов'
                                : 'Архивировано'
                        }
                              counter={<CountUp duration={1} end={total}/>}
                              icon={<img src={icon1} alt={''}/>} theme={'blue'} style={{padding: 32}}
                        />
                        <Card title={'Награда за реф. промокод'} counter={<><CountUp duration={1} end={card2Counter}/> Б</>}
                              icon={<img src={icon2} alt={''}/>} style={{padding: 32}}
                              actions={card2Actions}
                        />
                        <Card title={'Количество заказов до награды'} counter={<CountUp duration={1} end={card3Counter}/>}
                              icon={<img src={icon3} alt={''}/>} style={{padding: 32}}
                              actions={card3Actions}
                        />
                    </div>
            }

            {
                isLoading ? <Preloader type={'big'}/>
                    : <Table
                        headsArray={['ПРОМОКОД', 'НАЗВАНИЕ', 'КОММЕНТАРИЙ', 'НАГРАДА', 'ДАТА ОКОНЧАНИЯ', 'АКТИВИРОВАЛИ', 'ДЕЙСТВИЕ']}
                        rowsArray={data.map((item) =>
                            <TableRow item={item} key={item.id} mode={mode} getData={getData} getCount={getCount}/>
                        )}
                        gridTemplate={'4fr 5fr 5fr 3fr 5fr 5fr 92px'}
                    />
            }

            <Pagination
                paginationObject={paginationObject}
                setPaginationObject={setPaginationObject}
                totalCount={total} offsetSteps={[5, 10]}
            />
        </div>
        {
            createModal && <CreateModal closeFunc={setCreateModal} updateData={getData}/>
        }
        {
            editCard2 && <ModalWindow title={"Изменить параметры"}
                                      closeFunc={setEditCard2} type={'small'}
                                      primaryAction={async () => {
                                          await updateRates('REFERRAL_REWARD', card2Value)
                                          await getPromoOptions()
                                          setEditCard2(false)
                                      }
                                      }
            >
                <div className={s.cardModal}>
                    <p className={f.body1}>Укажите новое значение «Награда за реферальный промокод» </p>
                    <Input value={card2Value} setValue={setCard2Value} styles={{maxWidth: 524}}/>
                </div>

            </ModalWindow>
        }
        {
            editCard3 && <ModalWindow title={"Изменить параметры"}
                                      closeFunc={setEditCard3} type={'small'}
                                      primaryAction={async () => {
                                          await updateRates('REFERRAL_NUMBER_OF_ORDERS', card3Value)
                                          await getPromoOptions()
                                          setEditCard3(false)
                                      }}

            >
                <div className={s.cardModal}>
                    <p className={f.body1}>Укажите новое значение «Количество заказов до награды» </p>
                    <Input value={card3Value} setValue={setCard3Value} styles={{maxWidth: 524}}/>
                </div>

            </ModalWindow>
        }
    </div>
}

const TableRow = ({item, mode, getCount, getData}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [listModal, setListModal] = useState(false);


    const rowActions = [
        {
            label: 'Изменить',
            func: () => setEditModal(true)
        },
        {
            label: mode === 'list' ? 'Удалить' : 'Восстановить',
            func: () => setDeleteModal(true)
        },
        {
            label: 'Смотреть пользователей',
            func: () => setListModal(true)
        }
    ]


    return <>
        <p className={f.subtitle1Bold}>{item.secretWord}</p>
        <p className={f.body2}>{item.title}</p>
        <p className={f.body2}>{item.comment}</p>
        <p className={f.subtitle1Bold}>{item.reward} Б</p>
        <p className={f.subtitle1Bold}>{
            item?.endDate
                ? new Date(Number(item.endDate)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                : 'БЕЗ ОГРАНИЧЕНИЙ'
        }</p>
        <p className={f.subtitle1Bold}>{item.activated} {item?.numberOfActivations && `из ${item.numberOfActivations}`}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton type={'icon'} text={<EditIcon/>} actions={rowActions}/>
        </div>
        {
            deleteModal && <DeleteModal closeFunc={setDeleteModal} id={item.id} keyword={item.secretWord}
                                        updateData={getCount} status={mode === 'list' ? "0" : "1"}/>
        }
        {
            editModal && <CreateModal data={item} closeFunc={setEditModal} updateData={getData}/>
        }
        {
            listModal && <ListModal closeFunc={setListModal} id={item.id} type={'STANDARD_CODE'}/>
        }
    </>
}

export default PromoList
