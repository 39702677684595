import React, {useEffect, useState} from "react";
import {ReactComponent as NotificationIcon} from './icons/Notification.svg'
import {ReactComponent as SettingsIcon} from './icons/Setting.svg'
import TopNavbarTitle from '../../store/TopNavbarTitle'
import {store} from "../../index";

import fonts from '../../static/fonts.module.scss'
import style from './TopNavbar.module.scss'
import {observer} from "mobx-react-lite";
import Profile from "../_PAGES/UserInfo/Profile/Profile";
import AdminStore from "../../store/adminStore";
import Notifications from "./Notifications/Notifications";


const ProfileStore = new AdminStore()

const TopNavbar = observer(() => {
        const Store = store
        const [profileIsShown, setProfileIsShown] = useState(false);
        const [notificationsIsShown, setNotificationsIsShown] = useState(false);

        useEffect(() => {
            const getData = async () => {
                await ProfileStore.setUserId(Store.user.userId)
                await ProfileStore.setIsLoaded(false)
                await ProfileStore.getUserAuthProfile()
                await ProfileStore.setCompetencyProfile(false)
                await ProfileStore.setIsLoaded(true)
            }
            getData()
        }, [Store.user.userId])

        const handleProfileClick = () => {
            setProfileIsShown(
                !profileIsShown
            )
        }

    const handleNotificationClick = () => {
        setNotificationsIsShown(
            !notificationsIsShown
        )
    }

        return <div className={style.topNavbar}>
            <h6 className={`${style.header} ${fonts.boldH6}`}>{TopNavbarTitle.title}</h6>
            <div className={style.rightPart}>
                <div className={style.icons}>
                    <div className={`${style.iconWrap} ${style.settings}`}
                         onClick={() => handleProfileClick()}
                    >
                        <SettingsIcon/>
                    </div>
                    {/*<div onClick={()=>handleNotificationClick()}*/}
                    {/*    className={style.iconWrap}>*/}
                    {/*    <NotificationIcon/>*/}
                    {/*</div>*/}
                    {profileIsShown && <div className={style.profileWrap}>
                        <Profile Store={ProfileStore}/>
                    </div>
                    }
                    {
                        notificationsIsShown && <Notifications/>
                    }
                </div>
                <div className={style.infoWrap}>
                    <h6 className={fonts.boldH6}>{Store.user.name}</h6>
                    <p className={fonts.caption}>{Store.user.userRoleTitle}</p>
                </div>
                <img className={style.picture} src={Store.user.avatarLink} alt={''}/>
            </div>
        </div>
    }
)
export default TopNavbar