import f from "../../../../static/fonts.module.scss";

import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";

import {ReactComponent as EditIcon} from "../../CategoriesPage/editIcon.svg";
import {useState} from "react";
import {banReasonsStore, ReasonModal} from "../BanTable";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {deleteUserBanReason} from "../../../../services/AuthService";
import {deleteCompetencyBanReason} from "../../../../services/CompetencyService";

const TableRow = ({rowData, type}) => {
    const [editModalIsVisible, setEditModalIsVisible] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);

    const Store = banReasonsStore

    const actions = [
        {
            label: 'Изменить',
            func: () => setEditModalIsVisible(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        },
    ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.id}</p>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions} text={<EditIcon/>} nested_styles={{background: "white"}}/>
        </div>
        {
            editModalIsVisible &&
            <ReasonModal type={type}
                         data={{title: rowData.title, id: rowData.id}}
                         closeFunc={setEditModalIsVisible}/>
        }
        {
            deleteModal && <ModalWindow
                title={"Удаление"}
                closeFunc={setDeleteModal}
                type={'small'}
                primaryButtonTitle={'Удалить'}
                primaryAction={async () => {
                    if (type === 'Пользователи') {
                        await deleteUserBanReason(rowData.id)
                    }
                    if (type === 'Компетенции') {
                        await deleteCompetencyBanReason(rowData.id)
                    }
                    await Store.getData(type)
                    setDeleteModal(false)
                }}
            >
                <p className={f.subtitle1Bold}>Вы действительно хотите удалить "{rowData.title}"?</p>
            </ModalWindow>
        }
    </>
}

export default TableRow