import $auth from "../http";
import {notifications} from "../index";

//Создание записи
export const createRecord = async (title, text, image, type) => {
    try {
        const req = await $auth.post('/v1/news/create', {
            category: type,
            title: title,
            body: text,
            imageId: image.fileId,
            imageLink: image.fileLink
        })
        notifications.addNotification(req.data, "OK")
    } catch (e) {
        console.log({e})
    }
}

//Обновление записи
export const updateRecord = async (title, text, image, type, id) => {
    try {
        const req = await $auth.post('/v1/news/update', {
            category: type,
            title: title,
            body: text,
            imageId: image.imageId,
            imageLink: image.imageLink,
            id: id
        })
        console.log(req)
        notifications.addNotification(req.data, "OK")
    } catch (e) {
        console.log({e})
    }
}

//Удаление записи
export const deleteRecord = async (id) => {
    try {
        const req = await $auth.delete(`/v1/news/remove/${id}`)
        notifications.addNotification(req.data, "OK")
    } catch (e) {
        console.log({e})
    }
}

//Получение записи по ID
export const getRecord = async (id) => {
    try {
        const res = await $auth.get(`/v1/news/read/${id}`)
        console.log(res)
        return res.data
    } catch (e) {
        console.log({e})
    }
}


//Получение количества новостей
export const getNewsCount = async () => {
    try {
        const res = await $auth.get(`/v1/news/list/number?offset=10`)
        return res.data.newsCount
    } catch (e) {
        console.log({e})
    }
}

//Получение списка новостей
export const getNewsList = async (page, offset) => {
    try {
        const res = await $auth.get(`/v1/news/list?page=${page}&offset=${offset}`)
        return res.data
    } catch (e) {
        console.log({e})
    }
}


//Получение списка историй
export const getStoryList = async () => {
    try {
        const res = await $auth.get(`/v1/news/stories`)
        if (res.status === 200) {
            return res.data
        } else {
            return []
        }
    } catch (e) {
        console.log({e})
        return []
    }
}

//Создание истории
export const createStoryTopic = async (themeTypeCode, themeImage, themeName, stories) => {
    try {
        const query = `themeTypeCode=${themeTypeCode}&themeImage=${themeImage}&themeName=${themeName}`
        const storiesQuery = stories.map((item) => `stories[]=${JSON.stringify(item)}`)
        const req = await $auth.post(`/v1/news/stories?${query}&${storiesQuery.join("&")}`,)
        notifications.addNotification(req?.data?.message, "OK")
    } catch (e) {
        console.log({e})
    }
}

//Получение подробной информации по истории
export const getStoryTopicDetails = async (id) => {
    try {
        const req = await $auth.get(`/v1/news/stories/${id}`)
        return req.data
    } catch (e) {
        console.log({e})
    }
}


//Изменение истории
export const editStoryTopic = async (id, themeTypeCode, themeImage, themeName, stories) => {
    try {
        const query = `themeTypeCode=${themeTypeCode}&themeImage=${themeImage}&themeName=${themeName}`
        const storiesQuery = stories.map((item) => `stories[]=${JSON.stringify(item)}`)
        const req = await $auth.put(`/v1/news/stories/${id}?${query}&${storiesQuery.join("&")}`,)
        notifications.addNotification(req.data?.message, "OK")
    } catch (e) {
        console.log({e})
    }
}

//Удаление историй
export const deleteStory = async (id) => {
    try {
        const req = await $auth.delete(`/v1/news/stories/${id}`)
        notifications.addNotification(req.data?.message, "OK")
    } catch (e) {
        console.log({e})
    }
}
