import BanTable from "../../../components/_PAGES/BanReasonsTable/BanTable";
import TopNavbarTitle from "../../../store/TopNavbarTitle";
import {useEffect} from "react";

const BanReasons = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Список блокировок')
    }, [])

    return <BanTable/>
}

export default BanReasons