import s from './MobileFeedback.module.scss'
import f from '../../../../static/fonts.module.scss'

import {useCallback, useEffect, useState} from "react";
import {archiveFeedbackRecord, getFeedbackCount, getFeedbackList} from "../../../../services/SupportService";

import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import UserCard from "../../OrderInfo/UserCard/UserCard";
import Button from "../../../_BASE/UI/Button/Button";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";
import Table from "../../../_BASE/Layout/Table/Table";

const MobileFeedback = () => {

    const [activeTab, setActiveTab] = useState("Новые обращения");


    const tabs = [
        {
            label: 'Новые обращения',
            content: <List lifestatus={1} key={'active'} activeTab={activeTab}/>
        },
        {
            label: 'Архив обращений',
            content: <List lifestatus={0} key={'archive'} activeTab={activeTab}/>
        }
    ]

    return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
}


const List = ({lifestatus, activeTab}) => {
    const [data, setData] = useState([]);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    const getCount = useCallback(async () => {
        try {
            setCountIsLoaded(false)
            const c = await getFeedbackCount(lifestatus)
            setTotal(c)
            setCountIsLoaded(true)
        } catch (e) {
            throw e
        }
    }, [lifestatus])

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const data = await getFeedbackList(lifestatus, paginationObject.page, paginationObject.offset)
            setData(data)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [lifestatus, paginationObject.page, paginationObject.offset])

    useEffect(() => {
        getCount()
    }, [getCount])

    useEffect(() => {
        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded, getData])


    //Возможно пагинация будет устанавливать 1ю страницу на ререндере
    if (isLoading) return <Preloader type={'big'}/>


    const tableHeads = [
        "ID ПОЛЬЗОВАТЕЛЯ",
        "ЗАГОЛОВОК",
        "ДАТА ОБРАЩЕНИЯ",
        "ДЕЙСТВИЕ",
    ]


    return <>
        <Table headsArray={tableHeads}
               gridTemplate={"1fr 2fr 2fr 92px"}
               rowsArray={data.map((row, i) => <TableRow item={row} key={i} activeTab={activeTab}
                                                         getCount={getCount}/>)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </>

}

const TableRow = ({item, activeTab, getCount}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);


    const rowActions = [
        {
            label: 'Просмотр',
            func: () => setViewModal(true)
        },
        {
            label: 'Архивировать',
            func: () => setDeleteModal(true)
        }
    ]

    const rowActionsArchive = [
        {
            label: 'Просмотр',
            func: () => setViewModal(true)
        }
    ]

    const archiveClick = async () => {
        try {
            await archiveFeedbackRecord(item.id)
            await getCount()
        } catch (e) {
            throw e
        }
    }

    return <>
        <p className={f.subtitle1Bold}>{item.userId}</p>
        <p className={f.body1}>{item.feedback}</p>
        <p className={f.subtitle1Bold}>{
            new Date(Number(item.date)).toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            })
        }</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'}
                            actions={activeTab === 'Новые обращения'
                                ? rowActions
                                : rowActionsArchive}/>
        </div>
        {
            deleteModal && <ModalWindow
                closeFunc={setDeleteModal}
                title={'Архивирование обращения'}
                type={"small"}
                primaryAction={archiveClick}
            >
                <p className={f.body1}>Вы хотите заархивировать сообщение. Вы уверены? </p>
            </ModalWindow>
        }
        {
            viewModal &&
            <ViewModal activeTab={activeTab} setDeleteModal={setDeleteModal} setViewModal={setViewModal} item={item}/>
        }
    </>
}

const ViewModal = ({setViewModal, item, setDeleteModal, activeTab}) => {
    return <ModalWindow
        closeFunc={setViewModal}
        title={'Просмотр сообщения'}

    >
        <div className={s.viewWrap}>
            <UserCard userId={item.userId}
                      style={{background: "#E6F1FF"}}/>
            <div>
                <p className={f.subtitle1Bold} style={{marginBottom: 8}}>Дата
                    сообщения, {new Date(Number(item.date)).toLocaleDateString('ru-RU', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    })}</p>
                <p className={f.body1}>{item.feedback}</p>
            </div>
        </div>
        {
            activeTab === 'Новые обращения'
            && <Button text={'Архивировать сообщение'}
                       click={() => {
                           setViewModal(false)
                           setDeleteModal(true)
                       }}
            />
        }

    </ModalWindow>
}

export default MobileFeedback