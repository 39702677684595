import s from './BanTable.module.scss'
import f from "../../../static/fonts.module.scss";

import {useEffect, useState} from "react";
import {createUserBanReason, updateUserBanReason} from "../../../services/AuthService";
import {createCompetencyBanReason, updateCompetencyBanReason} from "../../../services/CompetencyService";
import {observer} from "mobx-react-lite";

import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import TableRow from "./TableRow/TableRow";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../_BASE/UI/Input/Input";
import BanRulesStore from "../../../store/banRules";
import Tabs from "../../_BASE/Layout/TabsTable/Tabs";
import Table from "../../_BASE/Layout/Table/Table";

export const banReasonsStore = new BanRulesStore()

export const ReasonModal = ({closeFunc, data, type}) => {
    const [reasonTitle, setReasonTitle] = useState(data ? data.title : '');
    const reasonId = data ? data.id : null;
    const target = type === 'Пользователи' ? 'пользователя' : 'компетенции'
    let title

    if (data) {
        title = `Вы редактируете блокировку ${target}: «${data.title}»`
    } else {
        title = `Укажите название новой блокировки ${target}`
    }

    const handleCreateClick = async () => {
        try {
            if (type === 'Пользователи') {
                await createUserBanReason(reasonTitle)
            }
            if (type === 'Компетенции') {
                await createCompetencyBanReason(reasonTitle)
            }
            await banReasonsStore.getData(type)
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    const handleEditClick = async () => {
        try {
            if (type === 'Пользователи') {
                await updateUserBanReason(reasonId, reasonTitle.trim())
            }
            if (type === 'Компетенции') {
                await updateCompetencyBanReason(reasonId, reasonTitle.trim())
            }
            banReasonsStore.getData(type)
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        title={data ? 'Редактирование блокировки' : 'Создание новой блокировки'}
        closeFunc={closeFunc}
        type={'small'}
        primaryAction={data ? handleEditClick : handleCreateClick}
        primaryButtonIsDisabled={!reasonTitle.trim().length > 0}
    >
        <div className={s.modal}>
            <p className={f.body1}>{title}</p>
            <Input value={reasonTitle} setValue={setReasonTitle} styles={{maxWidth: '100%', marginTop: 16}}/>
        </div>
    </ModalWindow>
}

const BanTable = () => {
    const [createUserReasonModal, setCreateUserReasonModal] = useState(false);
    const [createCompetencyReasonModal, setCreateCompetencyReasonModal] = useState(false);

    const [activeTab, setActiveTab] = useState('Пользователи');
    const tableGridTemplate = '100px 1fr 92px'
    const tableHeaders = ['ID', "Название", "Действие"]

    const tabs = [
        {
            label: 'Пользователи',
            content: banReasonsStore.isLoading ? <Preloader key={'userloading'} type={'big'}/> :
                <Table headsArray={tableHeaders} gridTemplate={tableGridTemplate}
                       rowsArray={banReasonsStore.data.map((item) => <TableRow rowData={item} type={activeTab}
                                                                               key={item.id}/>)}
                       key={'userBns'}/>
        },
        {
            label: 'Компетенции',
            content: banReasonsStore.isLoading ? <Preloader key={'comploading'} type={'big'}/> :
                <Table headsArray={tableHeaders} gridTemplate={tableGridTemplate}
                       rowsArray={banReasonsStore.data.map((item) => <TableRow rowData={item} type={activeTab}
                                                                               key={item.id}/>)}
                       key={'competencyBns'}/>
        },

    ]

    useEffect(() => {
        async function getData() {
            await banReasonsStore.getData(activeTab)
        }

        getData()
    }, [activeTab])


    //Действия для кнопки "Действия"
    const actions = [
        {
            label: 'Добавить причину блокировки пользователя',
            func: () => {
                setCreateUserReasonModal(true)
                setActiveTab('Пользователи')
            }
        },
        {
            label: 'Добавить причину блокировки компетенции',
            func: () => {
                setCreateCompetencyReasonModal(true)
                setActiveTab('Компетенции')
            }
        }
    ]


    return <div>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}
              sideButtons={<DropdownButton actions={actions} text={'Действия'}/>}/>
        {
            createUserReasonModal && <ReasonModal closeFunc={setCreateUserReasonModal}
                                                  type={'Пользователи'}/>
        }
        {
            createCompetencyReasonModal && <ReasonModal closeFunc={setCreateCompetencyReasonModal}
                                                        type={'Компетенции'}/>
        }
    </div>
}

export default observer(BanTable)
