import React, {useState, useEffect, useCallback} from "react";

import s from './UserCard.module.scss'
import font from '../../../../static/fonts.module.scss'
import {ReactComponent as StarIcon} from "../icon/Star.svg";
import {ReactComponent as ProfileIcon} from "../icon/Profile.svg";
import {ReactComponent as PhoneIcon} from "../icon/Phone.svg";
import {ReactComponent as MessageIcon} from "../icon/Message.svg";
import {getUserInfo} from "../../../../services/AuthService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import {getUserCompetenciesProfile} from "../../../../services/CompetencyService";

const UserCard = ({userId, style}) => {
    const [authData, setAuthData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [competencyProfile, setCompetencyProfile] = useState();
    const [notFound, setNotFound] = useState(false);
    const [userProfileLink, setUserProfileLink] = useState('');

    const getCompetencyData = useCallback(async (role) => {
        try {
            const profile = await getUserCompetenciesProfile(userId, role)
            setCompetencyProfile(profile.data)
        } catch (e) {
            throw e
        }
    }, [userId])


    const getProfileURLGroup = (role) => {
        switch (role) {
            case 'STUDENT' :
                return '/students/'
            case 'TEACHER' :
                return '/teachers/'
            case 'TEC_SUP_AGENT' :
                return '/agents/'
            default:
                return
        }
    }

    useEffect(() => {
        const getData = async () => {
            try {
                if (userId) {
                    const req = await getUserInfo(userId)
                    if (req?.data?.user.userRoleGroupTitleCode !== "SYSTEM_STAFF") {
                        await getCompetencyData(req.data.user.userRoleTitleCode)
                    }
                    setAuthData(req.data)
                    setUserProfileLink(getProfileURLGroup(req.data.user.userRoleTitleCode) + req.data.user.userId)
                } else {
                    setNotFound(true)
                }
                await setIsLoading(false)
            } catch (e) {
                setNotFound(true)
                throw e
            } finally {
                setIsLoading(false)
            }
        }
        getData()
    }, [userId, getCompetencyData]);


    return (
        <div className={`${s.userWrap}`} style={style ? style : null}>
            {
                isLoading
                    ? < Preloader/>
                    : notFound
                        ? <p className={`${font.subtitle1Bold} ${s.notFound}`}>Пользователь не найден</p>
                        : <a href={userProfileLink} target={'_blank'} rel="noreferrer"  className={s.innerLayout}>
                            <div className={s.mainInfo}>
                                <img src={authData?.user?.avatarLink} alt={authData?.user?.name}/>
                            </div>
                            <div className={s.userInfoWrap}>
                                <div className={s.row}>
                                    <p className={font.subtitle1Bold}>{authData?.user?.name} {authData?.user?.surname}</p>
                                    {
                                        authData.user.userRoleTitleCode !== 'TEC_SUP_AGENT' &&
                                        <div className={s.ratingWrap}>
                                            <StarIcon/>
                                            <p className={font.subtitle1Bold}>
                                                {competencyProfile?.rating}</p>
                                        </div>
                                    }
                                </div>
                                <p className={`${font.body2} ${s.role}`}>{
                                    authData.user.userRoleTitleCode === 'TEC_SUP_AGENT'
                                        ? 'Агент ТП'
                                        : competencyProfile?.positionTitle
                                }</p>
                                <div className={s.rowWithIcon}>
                                    <ProfileIcon/>
                                    <p className={font.body2}>{userId}</p>
                                </div>
                                <div className={s.rowWithIcon}>
                                    <PhoneIcon/>
                                    <p className={font.body2}>{authData?.user?.phone}</p>
                                </div>
                                <div className={s.rowWithIcon}>
                                    <MessageIcon/>
                                    <p className={font.body2}>{authData?.user?.email}</p>
                                </div>
                            </div>
                        </a>
            }
        </div>
    )
}

export default UserCard
