import s from './CreateStory.module.scss'
import f from '../../../../static/fonts.module.scss'


import FileInput from "../../../_BASE/UI/Input/FileInput/FileInput";
import Input from "../../../_BASE/UI/Input/Input";
import Select from "../../../_BASE/UI/Select/Select";
import Button from "../../../_BASE/UI/Button/Button";

import {uploadImage, deleteFile} from "../../../../services/FileService";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useNavigate} from "react-router";
import {useState, useEffect, useRef} from "react";
import {observer} from "mobx-react-lite";

import {ReactComponent as DeleteIcon} from "./Delete.svg";
import {ReactComponent as EditIcon} from "./Setting.svg";
import {ReactComponent as AddIcon} from "./Plus.svg";

import TopNavbarTitle from "../../../../store/TopNavbarTitle";
import CreateStoryMobX from "../../../../store/createStory";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {notifications} from "../../../../index";
import {createStoryTopic, editStoryTopic, getStoryTopicDetails} from "../../../../services/NewsService";
import {useParams} from "react-router-dom";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import TitleWithBack from "../../../TopNavbar/TitleWithBack/TitleWithBack";

const StoryEditorStore = new CreateStoryMobX()


const CreateStory = ({edit}) => {
    const [themeImageObject, setThemeImageObject] = useState();
    const [themeImageUrl, setThemeImageUrl] = useState('');
    const [themeTitle, setThemeTitle] = useState('');
    const [type, setType] = useState();
    const [editView, setEditView] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editWindowData, setEditWindowData] = useState();
    const [originData, setOriginData] = useState();
    const [deletedResources, setDeletedResources] = useState([]);


    const navigation = useNavigate()
    const {id} = useParams()

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            StoryEditorStore.stories,
            result.source.index,
            result.destination.index
        );

        StoryEditorStore.setStories(items)
    }

    TopNavbarTitle.setNewTitle(
        <TitleWithBack title={
            edit
                ? "Изменение истории"
                : "Создание истории"
        }/>
    )

    useEffect(() => {
        StoryEditorStore.setStories([])
        if (edit) {
            const getData = async () => {
                try {
                    const data = await getStoryTopicDetails(id)
                    setOriginData(data)
                    setThemeTitle(data.theme_name)
                    setThemeImageUrl(data.theme_image)
                    setType(data.theme_type_title)
                    data.stories.forEach((item) => {
                        StoryEditorStore.addStory({
                            storyName: item.story_title,
                            storySrc: item.story_src ? item.story_src : '',
                            storyFileSrc: item.story_file_src,
                            storyTypeCode: item.story_type,
                            storyId: item.story_id,
                            duration: item.duration
                        })
                    })
                } catch (e) {
                    notifications.addNotification(e.response.data.message)
                }
            }

            getData()
        }
    }, [edit, id])


    const createStoryTopicClick = async () => {
        const getThemeName = (type) => {
            if (type === 'Для всех') {
                return 'ALL_STORIES'
            }
            if (type === 'Для учителей') {
                return 'TEACHER'
            }
            if (type === 'Для учеников') {
                return 'STUDENT'
            }
        }

        console.log(StoryEditorStore)
        if (StoryEditorStore.stories.length === 0) {
            notifications.addNotification("Нельзя создать блок историй с 0 историй")
            return
        }

        if (!type) {
            notifications.addNotification("Укажите тип создаваемой истории")
            return
        }

        if (themeTitle?.length < 3) {
            notifications.addNotification('Укажите  заголовок блока историй')
            return
        }

        if (!themeImageObject && !edit) {
            notifications.addNotification('Прикрепите изображение блока')
            return
        }

        try {
            if (edit) {
                let imgLink = originData.theme_image
                if (themeImageObject) {
                    const img = await uploadImage(themeImageObject)
                    imgLink = img.fileLink
                    const tmp = deletedResources
                    tmp.push(originData.theme_image)
                    setDeletedResources(tmp)
                }
                await editStoryTopic(
                    id,
                    getThemeName(type),
                    imgLink,
                    themeTitle,
                    StoryEditorStore.wrapArray()
                )

                for (const deletedResource of deletedResources) {
                    await deleteFile(deletedResource)
                }

                navigation('/blog#stories')

            } else {
                const img = await uploadImage(themeImageObject)
                await createStoryTopic(
                    getThemeName(type),
                    img.fileLink,
                    themeTitle,
                    StoryEditorStore.wrapArray()
                )
                navigation('/blog#stories')
            }
        } catch (e) {
            try {
                notifications.addNotification(e.response.data.message)
            } catch (er) {
                console.log(er)
            }
        }
    }

    return <div className={s.wrap}>
        <div className={s.themeColumn}>
            <FileInput imageObject={themeImageObject} setImageObject={setThemeImageObject}
                       imageUrl={themeImageUrl} setImageUrl={setThemeImageUrl}
                       size={64}/>
            <Input title={'Укажите заголовок блока историй'} styles={{maxWidth: "100%"}}
                   value={themeTitle} setValue={setThemeTitle}/>
            <Select title={"Укажите тип создаваемой истории"} options={["Для всех", "Для учителей", "Для учеников"]}
                    setSelectedOption={setType} selectedOption={type}/>

            <div className={`${s.storyListCaption} ${f.body1}`}>
                Истории можно передвигать, чтобы изменять их позицию. <br/>Просто зажми картинку и двигай
            </div>
            <div className={s.storiesList}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => <InternalList provided={provided} snapshot={snapshot}
                                                               setEditWindowData={setEditWindowData}
                                                               setEditView={setEditView} setEditMode={setEditMode}
                                                               setDeletedResources={setDeletedResources}
                                                               deletedResources={deletedResources}
                                                               editMode={editMode}
                        />}

                    </Droppable>
                </DragDropContext>
                <div className={s.addNewWrap}>
                    {
                        !editView && <button className={s.addNewButton}
                                             onClick={() => {
                                                 setEditView(true)
                                                 setEditMode(false)
                                             }
                                             }
                        >
                            <AddIcon/>
                            <p className={f.body2}>
                                Добавить новую историю
                            </p>
                        </button>
                    }
                </div>
            </div>
            <div>
                <Button text={edit ? "Сохранить" : 'Создать'} click={() => createStoryTopicClick()}/>
            </div>
        </div>
        <div className={s.editCol}>
            {
                editView && <EditView data={editWindowData} closeFunc={setEditView} edit={edit} editMode={editMode}
                                      deletedResources={deletedResources} setDeletedResources={setDeletedResources}
                                      setEditMode={setEditMode}
                />
            }
        </div>
    </div>
}

const EditView = ({data, closeFunc, editMode, edit, deletedResources, setDeletedResources, setEditMode}) => {
    const [imageUrl, setImageUrl] = useState();
    const [imageObject, setImageObject] = useState();
    const [storyTitle, setStoryTitle] = useState('');
    const [storyLink, setStoryLink] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const imageRef = useRef()


    useEffect(() => {
        setImageObject(null)
        if (editMode) {
            setImageUrl(data.storyFileSrc)
            setStoryTitle(data.storyName)
            if (data.storySrc) setStoryLink(data.storySrc)
        }
    }, [data, editMode])

    const uploadStory = async () => {
        if (!imageObject) {
            notifications.addNotification('Прикрипите изображение')
            return
        }
        if (!check()) {
            return
        }
        try {
            setIsLoading(true)
            const req = await uploadImage(imageObject)

            const storyObject = {
                storyName: storyTitle,
                storySrc: storyLink,
                storyFileSrc: req.fileLink,
                storyTypeCode: imageObject.type === 'video/mp4'
                    ? "video"
                    : "image",
                duration: imageObject.type === 'video/mp4'
                    ? Math.floor(imageRef.current.duration) * 1000 > 15000
                        ? 15000
                        : Math.floor(imageRef.current.duration) * 1000
                    : "NULL",
            }

            if (edit) {
                storyObject.storyId = 'NEW_STORIES'
            }

            StoryEditorStore.addStory(storyObject)
            closeFunc(false)
        } catch (e) {
            notifications.addNotification(e.response.data.message)
        }
        setIsLoading(false)
    }
    const check = () => {
        if (storyTitle.length < 3) {
            notifications.addNotification('Заголовок стори должен превышать 3 символа')
            return false
        }
        return true
    }

    const editStory = async () => {
        if (!check()) {
            return
        }
        let imgUrl = data.storyFileSrc
        try {
            setIsLoading(true)
            if (imageObject) {
                const req = await uploadImage(imageObject)
                imgUrl = req.fileLink
                const tmp = deletedResources
                tmp.push(data.storyFileSrc)
                setDeletedResources(tmp)
            }
            setIsLoading(false)

            const storyObject = {
                storyName: storyTitle,
                storySrc: storyLink,
                storyFileSrc: imgUrl,
                id: data.id,
                storyTypeCode: imageObject
                    ? imageObject.type === 'video/mp4'
                        ? "video"
                        : "image"
                    : data.storyTypeCode,
                duration: imageObject
                    ? imageObject.type === 'video/mp4'
                        ? Math.floor(imageRef.current.duration) * 1000 > 15000
                            ? 15000
                            : Math.floor(imageRef.current.duration) * 1000
                        : "NULL"
                    : data.duration,
            }

            if (edit) {
                storyObject.storyId = data.storyId
            }

            StoryEditorStore.editStory(data.id, storyObject)
            closeFunc(false)
        } catch (e) {
            try {
                notifications.addNotification(e.response.data.message)
            } catch (er) {
                console.log(er)
            }
        }
    }


    return <div className={s.editWrap}>
        <div className={s.editHeadline}>
            <h4 className={f.boldH4}>Истории</h4>
            <Button type={'alert'} size={'small'} text={'Закрыть'} click={() => {
                closeFunc(false)
                setEditMode(false)
            }
            }/>
        </div>
        <FileInput imageObject={imageObject} setImageObject={setImageObject}
                   imageUrl={imageUrl} setImageUrl={setImageUrl}
                   imageRef={imageRef} type={data?.storyTypeCode}
                   size={64} types={['image/png', "image/jpg", "image/jpeg", "video/mp4"]}/>
        {
            imageObject?.type === 'video/mp4' &&
            <p className={f.caption}>Длительность видео не должна превышать 15 секунд.</p>
        }
        <Input styles={{maxWidth: '100%'}} value={storyTitle} setValue={setStoryTitle}
               title={"Укажите заголовок к истории"}/>
        <Input styles={{maxWidth: '100%'}} value={storyLink} setValue={setStoryLink}
               title={"Укажите ссылку на дополнительную информацию к истории"}/>
        <div>
            <Button text={editMode ? "Редактировать историю" : 'Добавить историю'}
                    click={async () => {
                        editMode ? await editStory() : await uploadStory()
                        setEditMode(false)

                    }
                    }/>
        </div>
        {
            isLoading && <div className={s.editIsLoading}>
                <Preloader/>
            </div>
        }
    </div>
}

const StoryCard = ({
                       data,
                       deletedResources,
                       setDeletedResources,
                       setEditMode,
                       setEditView,
                       setEditWindowData,
                       editMode
                   }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteStory = () => {
        setIsDeleting(true)
        setTimeout(() => {
            const deleted = StoryEditorStore.deleteStory(data.id)
            const tmp = deletedResources
            tmp.push(deleted.storyFileSrc)
            setDeletedResources(tmp)
        }, 300)
    }

    const editStory = () => {
        setEditMode(true)
        setEditView(true)
        setEditWindowData(data)
    }

    return <div className={`${s.storyCard} ${isDeleting ? s.isDeleting : ""}`}>
        {
            data.storyTypeCode === 'image'
                ? <img className={s.storyImg} src={data.storyFileSrc} alt={''}/>
                : <video className={s.storyImg} autoPlay loop muted>
                    <source src={data.storyFileSrc}/>
                </video>
        }
        <div className={s.storyInfo}>
            <p className={f.body2}>{data.storyName}</p>
        </div>
        {
            !editMode &&
            <div className={s.buttons}>
                <button onClick={() => editStory()}>
                    <EditIcon/>
                </button>
                <button title={"Удалить"} onClick={() => setDeleteModal(true)}>
                    <DeleteIcon/>
                </button>
            </div>
        }
        {
            deleteModal &&
            <ModalWindow
                closeFunc={setDeleteModal} title={'Удалить историю?'} type={'small'}
                primaryAction={deleteStory}
            >
                {data.storyName}
            </ModalWindow>
        }
    </div>
}

const InternalList = observer(({
                                   provided,
                                   deletedResources,
                                   setDeletedResources,
                                   setEditMode,
                                   setEditView,
                                   setEditWindowData,
                                   editMode

                               }) => {
    return <div className={s.storyArray}
                ref={provided.innerRef}
                {...provided.droppableProps}
    >
        {StoryEditorStore.stories.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                    <div className={s.cardWrap}
                         ref={provided.innerRef}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}

                    >
                        <StoryCard data={item} key={item.id}
                                   deletedResources={deletedResources} setDeletedResources={setDeletedResources}
                                   setEditMode={setEditMode} setEditView={setEditView}
                                   setEditWindowData={setEditWindowData}
                                   editMode={editMode}
                        />
                    </div>
                )}
            </Draggable>
        ))}
        {provided.placeholder}
    </div>
})

export default observer(CreateStory)
