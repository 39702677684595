import TopNavbarTitle from "../../store/TopNavbarTitle";
import PromoList from "../../components/_PAGES/PromoList/PromoList";
import {useEffect} from "react";

const Promo = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Список промокодов')
    }, [])

    return <PromoList/>
}

export default Promo