import OrderInfoPage from "../../components/_PAGES/OrderInfo/OrderInfoPage";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import {useParams} from "react-router-dom";
import TitleWithBack from "../../components/TopNavbar/TitleWithBack/TitleWithBack";


const OrdersPage = () => {
    const {id} = useParams()

    TopNavbarTitle.setNewTitle(<TitleWithBack title={`Информация о заказе ${id}`}/> )
    return <OrderInfoPage/>
}

export default OrdersPage