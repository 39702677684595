import s from './PopularTables.module.scss'
import f from '../../../../../static/fonts.module.scss'
import Table from "../../../../_BASE/Layout/Table/Table";
import {useEffect, useState} from "react";
import {getPopularCompetencies} from "../../../../../services/StatisticService";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";

const PopularTables = () => {
    return <div className={s.wrap}>
        <ListTable type={'SCHOOL'} key={'schoolTable'}/>
        <ListTable type={'VUZ'} key={'vuzTable'}/>
    </div>
}

const ListTable = ({type}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getPopularCompetencies(type)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [type])

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.listTable}>
        <h4 className={f.boldH4}>Популярные {type === 'SCHOOL' ? "школьные" : "ВУЗ"} компетенции</h4>
        <Table headsArray={['#', "КОМПЕТЕНЦИЯ", "Количество учителей"]}
               gridTemplate={'50px 2fr 1fr'}
               rowsArray={data.map((item, i) => <ListTableRow rowData={item} index={i + 1} key={i}/>)}/>

    </div>
}

const ListTableRow = ({rowData, index}) => {
    return <>
        <p className={f.subtitle1Bold}>{index}</p>
        <p className={f.body1}>{rowData.sTitle} / {rowData.oTitle}</p>
        <p className={f.subtitle1Bold}>{rowData.number}</p>
    </>
}

export default PopularTables