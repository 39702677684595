import s from './ActiveUsersGraph.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useEffect, useState} from "react";
import {getActiveUsers, getActiveUsersInfo, getRegisteredUsers} from "../../../../../services/StatisticService";
import {formatDataForAreaCharts} from "../../../../../static/utilities";

import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import ChartArea from "../../../Dashboard/DashboardBlock/ChartArea/ChartArea";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import Tooltip from "../../../../_BASE/UI/Tooltip/Tooltip";


const periods = [
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const text = 'Неактивные пользователи - это пользователи, которые не заходили в приложение более 30 дней. </br></br> Счетчик в заголовке блока показывает общее количество неактивных пользователей(то есть, даже с учетом тех, кто не создал профиль компетенций'

const ActiveUsersGraph = () => {
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getActiveUsers(currentPeriod.code)
                setTotal(req.reduce((sum, current) => sum + current[1], 0))
                setData(formatDataForAreaCharts(req, currentPeriod.code))

                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [currentPeriod.code])


    return <div className={s.wrap}>
        <div className={s.graph}>
            <div className={s.header}>
                <div className={s.row}>
                    <div className={s.headerWrapper}>
                        <h4 className={f.boldH4}>Количество неактивных пользователей</h4>
                        <Tooltip text={text} />
                    </div>
                    <h4 className={f.boldH4}>{total}</h4>
                </div>
                <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
            </div>
            <ChartArea height={316} isLoading={isLoading} data={data}/>
        </div>
        <InfoBlock/>
    </div>
}

const InfoBlock = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [blockData, setBlockData] = useState([]);


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getActiveUsersInfo()
                const block = await getRegisteredUsers('ALL_TIME', 'BLOCK')
                setData(req)
                setBlockData(block)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [])

    return <div className={s.info}>
        <h4 className={f.boldH4}>Информация</h4>
        {
            isLoading
                ? <Preloader/>
                : <div className={s.column}>
                    <div className={s.row}>
                        <p className={f.subtitle1Bold}>Заблокированные ученики на данный момент</p>
                        <div className={s.separtor}/>
                        <p className={f.subtitle1Bold}>{blockData.student}</p>
                    </div>
                    <div className={s.row}>
                        <p className={f.subtitle1Bold}>Заблокированные учителя на данный момент</p>
                        <div className={s.separtor}/>
                        <p className={f.subtitle1Bold}>{blockData.teacher}</p>
                    </div>
                    <div className={s.row}>
                        <p className={f.subtitle1Bold}>Активные пользователи за день</p>
                        <div className={s.separtor}/>
                        <p className={f.subtitle1Bold}>{data.day}</p>
                    </div>
                    <div className={s.row}>
                        <p className={f.subtitle1Bold}>Активные пользователи за неделю</p>
                        <div className={s.separtor}/>
                        <p className={f.subtitle1Bold}>{data.week}</p>
                    </div>
                    <div className={s.row}>
                        <p className={f.subtitle1Bold}>Активные пользователи за месяц</p>
                        <div className={s.separtor}/>
                        <p className={f.subtitle1Bold}>{data.month}</p>
                    </div>
                </div>
        }
    </div>
}


export default ActiveUsersGraph