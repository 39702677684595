import React from "react";

import style from './RandomColorAvatar.module.scss'
import {ReactComponent as DefaultSVG} from "./Profile.svg";


const RandomColorAvatar = ({url}) => {
    const colorPalette = [
        '#E6F1FF',
        '#FFB100',
        '#FF426C',
        '#3C4AE4',
        '#FFB8BA'
    ]

    const randomBG = colorPalette[Math.floor(Math.random() * colorPalette.length)]

    return (
        <div
            className={style.wrap}
            style={{background: randomBG}}
        >
            {
                url
                    ? <img src={url} alt=""/>
                    : <DefaultSVG/>
            }
        </div>
    )
}

export default RandomColorAvatar