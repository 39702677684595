import s from './PopularCategories.module.scss'

import RadialChart from "../../../../Dashboard/RadialChart/Charts";

import {useEffect, useState} from "react";

import {getPopularCategoriesByAgent} from "../../../../../../services/StatisticService";


const PopularCategories = ({period}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getPopularCategoriesByAgent(period)
                const formatted = req.map((item) => {
                    return {
                        name: `${item.sTitle} / ${item.cTitle}`,
                        value: item.number
                    }
                })
                setData(formatted)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period])

    if (data?.length === 0) return null

    return <div className={s.wrap}>
        <RadialChart data={data} isLoading={isLoading}/>
    </div>
}

export default PopularCategories