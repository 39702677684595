import {useCallback, useEffect, useState} from "react";
import {ReactComponent as EditIcon} from "./icon/Edit.svg";
import {ReactComponent as BlockIcon} from "./icon/Shield.svg";
import {
    createNewUserCompetencies,
    deleteCompetency,
    getUserCompetencies,
} from "../../../../services/CompetencyService";
import {userStore} from "../UserInfo";
import {observer} from "mobx-react-lite";
import {notifications} from "../../../../index";

import s from './SkillsTable.module.scss'
import f from '../../../../static/fonts.module.scss'

import Button from "../../../_BASE/UI/Button/Button";
import SingleSkillRow from "./SingleSkillRow/SingleSkillRow";
import AddCompetencyModal from "../../UserList/CreateNewTeacherModal/AddCompetencyModal/AddCompetencyModal";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import BlockedTable from "./BlockedTable/BlockedTable";
import Table from "../../../_BASE/Layout/Table/Table";


const SkillsTable = () => {
    const Store = userStore
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [data, setData] = useState([])
    const [compsFromDB, setCompsFromDB] = useState([])
    const [dataStructure, setDataStructure] = useState(new Map())
    const [optionLabels, setOptionLabels] = useState(new Map())
    const [activeTab, setActiveTab] = useState(0);

    const [isLoading, setIsLoading] = useState(true);


    //Запрос списка
    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const data = await getUserCompetencies(Store.competencyProfile.profileId)
            //Проверка пришел ли массив или сообщение о том что компетенций нет
            if (Array.isArray(data.data)) {
                setCompsFromDB(data.data)
                loadDataStructure(data.data)
                setData(data.data)
            } else {
                setData([])
            }
        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    },[Store.competencyProfile.profileId])

    const loadDataStructure = (data) => {
        const tempSet = new Set()
        const dataStructureTemplate = new Map()
        data.forEach((subjectObject) => {
            subjectObject.options.forEach((option) => {
                tempSet.add(option.aggRelId)
            })
            dataStructureTemplate.set(subjectObject.subjectId, {
                iconLink: subjectObject.subjectIconLink,
                options: tempSet,
                title: subjectObject.subject
            })
        })
        setDataStructure(dataStructureTemplate)
    }


    //Изменение компетенций
    const handleChangeCompsClick = async () => {
        //Обход по существующим предметам и удаление
        let deleteIdArray = []
        compsFromDB.forEach((originalSubject) => {
            //Если удалены все компетенции по данном предмету
            if (!dataStructure.has(originalSubject.subjectId)) {
                //Удаляем все опции(competencyId) из бд
                originalSubject.options.forEach((originalOption) => {
                    deleteIdArray.push(originalOption.aggRelId)
                })
            } else {
                //иначе сравниваем опции
                originalSubject.options.forEach((originalOption) => {
                    if (!dataStructure.get(originalSubject.subjectId).options.has(originalOption.aggRelId)) {
                        //Если в новм массиве такой опции нет, то удаляем (competencyId) из БД
                        deleteIdArray.push(originalOption.aggRelId)
                    }
                })
            }
        })
        if (deleteIdArray.length > 0) {
            try {
                await deleteCompetency(Store.competencyProfile.profileId, Store.userId, deleteIdArray)
            } catch (e) {
                throw e
            }
        }

        //Создание новых компетенций
        let newCompetenciesIdArray = []
        for (let subject of dataStructure.values()) {
            for (let competencyId of subject.options) {
                newCompetenciesIdArray.push(competencyId)
            }
        }
        if (newCompetenciesIdArray.length > 0) {
            try {
                const createReq = await createNewUserCompetencies(
                    Store.competencyProfile.profileId,
                    newCompetenciesIdArray,
                    Store.userId,
                )
                notifications.addNotification(createReq.data.newRecord, 'OK')
                notifications.addNotification(createReq.data.errorRecord, 'OK')
                notifications.addNotification(createReq.data.existRecord, 'OK')
            } catch (e) {
                throw e
            }
        }
        await getData()
        setEditModalIsOpen(false)
    }


    useEffect(() => {
        if (activeTab === 0) {
            getData()
        }
    }, [activeTab, getData])


    return <ErrorBoundary>
        <div className={s.wrap}>
            <div className={s.headLine}>
                <h4 className={`${f.boldH4}`}>Компетенции</h4>
                <div className={s.switch}>
                    <button className={`${s.switchButton} ${f.button}`}
                            onClick={() => activeTab === 0
                                ? setActiveTab(1)
                                : setActiveTab(0)
                            }
                    >
                        {
                            activeTab === 0
                                ? <>Заблокированные <BlockIcon/></>
                                : "Назад"
                        }
                    </button>
                    <Button type={'alert'} text={"Изменить"}
                            icon={<EditIcon/>} size={'small'} click={() => setEditModalIsOpen(true)}
                    />
                </div>
            </div>

            {
                editModalIsOpen
                    ? <AddCompetencyModal
                        closeFunc={setEditModalIsOpen}
                        setOptionLabels={setOptionLabels}
                        setDataStructure={setDataStructure}
                        dataStructure={dataStructure}
                        optionLabels={optionLabels}
                        role={Store.competencyProfile.workCode}
                        addButtonAction={() => handleChangeCompsClick()}
                    />
                    : null
            }
            {
                activeTab === 0
                    ? isLoading ? <Preloader type={'big'}/> : <CompetencyTable data={data} getData={getData}/>
                    : <BlockedTable/>
            }
        </div>
    </ErrorBoundary>

}

const CompetencyTable = ({data, getData}) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const allRows = []
        data.forEach((subjectObject) => {
            subjectObject.options.forEach((option) =>
                allRows.push(<SingleSkillRow key={option.competencyId}
                                             competencyId={option.competencyId}
                                             aggRelId={option.aggRelId}
                                             subject={subjectObject.subject}
                                             option={option.title}
                                             iconLink={subjectObject.subjectIconLink}
                                             updateFunc={getData}/>))
        })
        setRows(allRows)
    }, [getData, data])


    return <div className={s.table}>
        <Table headsArray={['', "Предмет", "Опция", "Действие"]}
               gridTemplate={'40px 1fr 1fr 92px'}
               rowsArray={rows}
        />
    </div>
}

export default observer(SkillsTable)