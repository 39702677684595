import s from './Top6.module.scss'
import f from '../../../static/fonts.module.scss'

import {useState} from "react";

import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Top6Table from "./Table/Top6Table";
import AddCompetencyModal from "../UserList/CreateNewTeacherModal/AddCompetencyModal/AddCompetencyModal";
import {createTop6Competencies, getTop6Competencies, deleteTop6Competencies} from "../../../services/CompetencyService";
import Tabs from "../../_BASE/Layout/TabsTable/Tabs";


const Top6 = () => {
    const [currentActiveTab, setCurrentActiveTab] = useState('Школьный топ')
    const [addNewCompetencyModal, setAddNewCompetencyModal] = useState(false);
    const [dataStructure, setDataStructure] = useState(new Map())
    const [optionLabels, setOptionLabels] = useState(new Map());
    const [modalCode, setModalCode] = useState('SCHOOL');
    const [modalOriginAggRelIds, setModalOriginAggRelIds] = useState(new Map());

    const tabs = [
        {
            label: "Школьный топ",
            content: <Top6Table code={'SCHOOL'} key={'school'}/>
        },
        {
            label: "Студенческий топ",
            content: <Top6Table code={'VUZ'} key={'vuz'}/>
        },
    ]


    //Обработка действий из кнопки "Действия"
    const handleActionClick = async (type) => {
        try {
            await setModalCode(type)
            const dataTemp = new Map()
            const originRelIds = new Map()

            const data = await getTop6Competencies(type)
            await data.forEach((record) => {
                originRelIds.set(record.aggRelId, record.recordId)
                dataTemp.set(record.subjectId, {
                    iconLink: record.iconLink,
                    options: dataTemp.has(record.subjectId)
                        ? new Set(dataTemp.get(record.subjectId).options.add(record.aggRelId))
                        : new Set([record.aggRelId]),
                    title: record.subjectTitle
                })
            })
            await setDataStructure(new Map(dataTemp))
            await setModalOriginAggRelIds(new Map(originRelIds))
            await setAddNewCompetencyModal(true)
        } catch (e) {
            throw e
        }
    }

    //Обработка кнопки модального окна добавления
    const handleUpdateTopClick = async () => {
        const deleteRelIds = new Map(modalOriginAggRelIds)
        //Обход по SubjectId
        for (let subject of dataStructure.values()) {
            //Обход по aggRelId каждого SubjectId
            for (let value of subject.options) {
                //Если aggRelId нет в исходном массиве
                if (!deleteRelIds.has(value)) {
                    //Создаем новую запись
                    try {
                        await createTop6Competencies(modalCode, value)
                    } catch (e) {
                        throw e
                    }
                } else {
                    //Значит этот aggRelId есть в исходном массиве, действия не требуются, удаляем aggRelId из исходного массива
                    deleteRelIds.delete(value)
                }
            }
        }
        //После обхода в исходном массиве останутся только те aggRelId, с которых пользователь снял отметку, их нужно удалить
        for (let recordId of deleteRelIds.values()) {
            try {
                await deleteTop6Competencies(modalCode, recordId)
            } catch (e) {
                throw e
            }
        }
        setAddNewCompetencyModal(false)
        setCurrentActiveTab(modalCode === 'VUZ' ? "Студенческий топ" : "Школьный топ")
    }

    const actions = [
        {
            label: 'Добавить школьную услугу',
            func: () => handleActionClick('SCHOOL')
        },
        {
            label: 'Добавить услугу ВУЗа',
            func: () => handleActionClick('VUZ')
        },
    ]


    return <div className={s.wrap}>
        <Tabs activeTab={currentActiveTab}
              setActiveTab={setCurrentActiveTab}
              tabs={tabs}
              sideButtons={<DropdownButton text={'Действия'} actions={actions}/>}
        />

        {
            addNewCompetencyModal && <AddCompetencyModal
                closeFunc={setAddNewCompetencyModal} role={modalCode}
                setDataStructure={setDataStructure} dataStructure={dataStructure}
                setOptionLabels={setOptionLabels} optionLabels={optionLabels}
                addButtonAction={() => handleUpdateTopClick()}
            />
        }
    </div>
}

export default Top6