import {useEffect, useState} from "react";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";

import s from './CreateNewHelpdeskAgentModal.module.scss'
import f from '../../../../static/fonts.module.scss'

import {registerNewUser} from "../../../../services/AuthService";
import {notifications} from "../../../../index";
import {formatGender} from "../../../../static/utilities";
import Select from "../../../_BASE/UI/Select/Select";

const CreateNewHelpdeskAgentModal = ({closeModalFunc, getData}) => {
    const [name, setName] = useState('')
    const [nameIsValid, setNameIsValid] = useState(false)
    const [surname, setSurname] = useState('')
    const [surnameIsValid, setSurnameIsValid] = useState(false)
    const [secondName, setSecondName] = useState('')
    const [secondNameIsValid, setSecondNameIsValid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [phone, setPhone] = useState('89')
    const [phoneIsValid, setPhoneIsValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordIsValid, setPasswordIsValid] = useState(false)
    const [repeatPassword, setRepeatPassword] = useState('')
    const [repeatPasswordIsValid, setRepeatPasswordIsValid] = useState(false)
    const [passwordsAreEqual, setPasswordsAreEqual] = useState(false)
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState('Не указано');

    const [isLoading, setIsLoading] = useState(false)
    const [step1isValid, setStep1isValid] = useState(false);

    const currentStage = 0

    useEffect(() => {
        if (password === repeatPassword && password.length > 5) {
            setPasswordsAreEqual(true)
        } else {
            setPasswordsAreEqual(false)
        }
    }, [password, repeatPassword])


    useEffect(() => {
        if (nameIsValid
            && emailIsValid
            && phoneIsValid
            && surnameIsValid
            && secondNameIsValid
            && passwordIsValid
            && repeatPasswordIsValid
            && passwordsAreEqual) {
            setStep1isValid(true)
        } else {
            setStep1isValid(false)
        }
    }, [nameIsValid, emailIsValid, phoneIsValid, surnameIsValid, secondNameIsValid, passwordIsValid, repeatPasswordIsValid, passwordsAreEqual]);

    const handleRegisterNewUser = async () => {
        try {
            setIsLoading(true)
            const request = await registerNewUser({
                roleId: 'agent',
                name: name,
                surname: surname,
                middlename: secondName,
                phone: phone,
                email: email,
                password: password,
                dob: dob,
                gender: formatGender(gender)
            })
            if (request.status === 200) {
                notifications.addNotification('Пользователь успешно создан', 'OK')
                await getData()
                closeModalFunc(false)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <>
            <ModalWindow
                title={`Создание нового агента ТП`}
                closeFunc={closeModalFunc}
            >
                <form className={s.wrapStage}>
                    <p className={`${s.description} ${f.boldH5}`}>Шаг 1. Укажите личную информацию нового
                        агента техподдержки</p>
                    <div className={s.wrapStage1Inputs}>
                        <div className={s.tripleRow}>
                            <Input
                                title={"Укажите имя"}
                                value={name}
                                setValue={setName}
                                validator={'name'}
                                setIsValid={setNameIsValid}
                                placeholder={'Иван'}
                            />
                            <Input
                                title={"Укажите фамилию"}
                                value={surname}
                                setValue={setSurname}
                                validator={'name'}
                                setIsValid={setSurnameIsValid}
                                placeholder={'Иванов'}
                            />
                            <Input
                                title={"Укажите отчество"}
                                value={secondName}
                                setValue={setSecondName}
                                validator={'name'}
                                setIsValid={setSecondNameIsValid}
                                placeholder={'Иванович'}
                            />
                        </div>
                        <div className={s.doubleRow}>
                            <Input
                                title={"Укажите дату рождения"}
                                value={dob}
                                setValue={setDob}
                                type={'date'}
                                placeholder={'01.01.1990'}
                                min={'1930-01-01'}
                                max={new Date().toISOString().split('T')[0]}
                            />
                            <Select options={["Мужской", "Женский", "Не указано"]} title={"Укажите пол"}
                                    selectedOption={gender} setSelectedOption={setGender}/>
                        </div>
                        <div className={s.doubleRow}>
                            <Input
                                title={"Укажите E-mail"}
                                value={email}
                                setValue={setEmail}
                                validator={'email'}
                                setIsValid={setEmailIsValid}
                                placeholder={'ivanovii@gmail.com'}
                            />
                            <Input
                                title={"Укажите телефон"}
                                value={phone}
                                setValue={setPhone}
                                validator={'phone'}
                                setIsValid={setPhoneIsValid}
                                maxLength={11}
                                placeholder={'89998887766'}
                            />
                        </div>
                        <div className={s.doubleRow}>
                            <Input
                                title={"Укажите пароль"}
                                value={password}
                                setValue={setPassword}
                                validator={'password'}
                                setIsValid={setPasswordIsValid}
                                password
                                placeholder={'*******'}
                            />
                            <div style={{position: 'relative'}}>
                                {
                                    !passwordsAreEqual && password.length > 5
                                    && <p className={`${f.subtitle2Bold} ${s.wrongPasswordMessage}`}
                                          style={{color: 'red', marginTop: 4}}>
                                        Пароли не совпадают
                                    </p>
                                }
                                <Input
                                    title={"Повторите пароль"}
                                    value={repeatPassword}
                                    setValue={setRepeatPassword}
                                    validator={'password'}
                                    additionalVal={password}
                                    setIsValid={setRepeatPasswordIsValid}
                                    password
                                    placeholder={'*******'}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                {
                    //Кнопка регистрации первого этапа
                    currentStage === 0 &&

                        <Button text={"Регистрация"}
                                click={() => handleRegisterNewUser()}
                                disabled={!step1isValid || isLoading}
                        />

                }
            </ModalWindow>
        </>
    )
}

export default CreateNewHelpdeskAgentModal