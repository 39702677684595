import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Finances from "../../components/_PAGES/Stats/Finances/Finances";


const FinancesStatsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Статистика по финансам')
    }, [])

    return <Finances/>
}

export default FinancesStatsPage