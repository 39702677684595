import s from './HelpdeskStats.module.scss'

import StatCards from "./StatCards/StatCards";
import AgentActivityTable from "./AgentActivityTable/AgentActivityTable";
import StatCharts from "./StatCharts/StatCharts";


const HelpdeskStats = () => {
    return <div className={s.wrap}>
        <StatCards/>
        <StatCharts/>
        <AgentActivityTable/>
    </div>
}

export default HelpdeskStats