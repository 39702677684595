import s from './PriceSettings.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import {getRates, updateRates} from "../../../../services/PaymentService";

import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import Table from "../../../_BASE/Layout/Table/Table";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

const EditModal = ({closeFunc, setData, type, value, setIsLoading}) => {
    const [newRate, setNewRate] = useState(value);

    const handleEditClick = async () => {
        await updateRates(type, newRate)
        await fetchData(setData, setIsLoading)
        closeFunc(false)
    }

    return <ModalWindow closeFunc={closeFunc}
                        type={'small'}
                        title={'Изменение коэффициента'}
                        primaryAction={() => handleEditClick()}
    >
        <div style={{width: 524}}>
            <Input value={newRate} setValue={setNewRate} title={'Коэффициент'}
                   styles={{maxWidth: 523}}/>
        </div>
    </ModalWindow>
}


const fetchData = async (setData, setIsLoading) => {
    const result = []
    try {
        setIsLoading(true)
        const data = await getRates()
        setIsLoading(false)


        result.push({
            label: 'Степенной коэффициент',
            value: data?.rate,
            code: "RATE"
        })
        result.push({
            label: 'Сервисный сбор (%)',
            value: data?.serviceRate,
            code: "SERVICE_RATE"
        })
        result.push({
            label: 'Дополнительный сбор (%)',
            value: data?.otherServiceRate,
            code: "OTHER_RATE"
        })
        result.push({
            label: 'Коэффициент для минимальной оригинальности',
            value: data?.originalityRateMin,
            code: "ORIGINALITY_RATE_MIN"
        })
        result.push({
            label: 'Коэффициент для максимальной оригинальности',
            value: data?.originalityRateMax,
            code: "ORIGINALITY_RATE_MAX"
        })
        result.push({
            label: 'Порог минимальной оригинальности (%)',
            value: data?.originalityNumberRateMin,
            code: "ORIGINALITY_NUMBER_RATE_MIN"
        })
        result.push({
            label: 'Порог максимальной оригинальности (%)',
            value: data?.originalityNumberRateMax,
            code: "ORIGINALITY_NUMBER_RATE_MAX"
        })
        result.push({
            label: '% сбора от стоимости заказа приходом денег (ЭКВАЙРИНГ)',
            value: data?.percentageIncome,
            code: "PER_INCOME"
        })
        result.push({
            label: 'Минимальная стоимость прихода денег (ЭКВАЙРИНГ) (рублей)',
            value: data?.minimumIncome,
            code: "MIN_INCOME"
        })
        result.push({
            label: 'Сбор от стоимости заказа при выводе денег (ЭКВАЙРИНГ) (%)',
            value: data?.percentageWithdrawal,
            code: "PER_WITHDRAWAL"
        })
        result.push({
            label: 'Минимальная стоимость вывода денег (ЭКВАЙРИНГ) (рублей)',
            value: data?.minimumWithdrawal,
            code: "MIN_WITHDRAWAL"
        })
        result.push({
            label: 'Стоимость тестовой операции для привязки карты (В рублях)',
            value: data?.addCardOrderPrice,
            code: "ADD_CARD_ORDER_PRICE"
        })
        result.push({
            label: 'Временная задержка отмены платежа до оплаты (В минутах)',
            value: data?.closeInvoiceTime,
            code: "CLOSE_INVOICE_TIME"
        })
        result.push({
            label: 'Временная задержка на подтверждения платежа (В минутах)',
            value: data?.confirmInvoiceTime,
            code: "CONFIRM_INVOICE_TIME"
        })
        result.push({
            label: '% от стоимости заказа, который можно оплатить бонусами',
            value: data?.useBonusPercent,
            code: "USE_BONUS_PERCENT"
        })
        result.push({
            label: '% от стоимости заказа, который пользователь получит в виде бонусов',
            value: data?.percentBonusForCloseOrder,
            code: "PERCENT_BONUS_FOR_CLOSE_ORDER"
        })

    } catch (e) {
        throw e
    }

    setData(result)
}


const TableRow = ({rowData, setData, setIsLoading}) => {
    const [editModal, setEditModal] = useState(false);

    const actions = [{
        label: 'Изменить',
        func: () => setEditModal(true)
    }]

    return <>
        <p className={f.subtitle1Bold}>{rowData.label}</p>
        <p className={f.subtitle1Bold}>{rowData.value}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            editModal &&
            <EditModal setIsLoading={setIsLoading} closeFunc={setEditModal} type={rowData.code} value={rowData.value}
                       setData={setData}/>
        }
    </>
}


const PriceSettings = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData(setData, setIsLoading)
    }, [])

    if (isLoading) {
        return <Preloader type={'big'}/>
    }

    return <div className={s.wrap}>
        <Table gridTemplate={'300px 1fr 92px'}
               headsArray={['Название', "Значение", "Действие"]}
               rowsArray={data.map((item) => <TableRow rowData={item}
                                                       setData={setData}
                                                       setIsLoading={setIsLoading}
               />)}/>
    </div>
}

export default PriceSettings
