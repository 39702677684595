import React from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';

import style from './ChartBar.module.scss'
import {CustomTooltip} from "../../ChartHelper/ChartHelper";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";


const BarShape = ({height, width, x, y, color, bgColor}) => {
    return (
        <>
            <rect x={x  } y={0} width={width} height={'calc(100% - 32px)'} rx="12" fill={bgColor} opacity={.25}/>
            <rect x={x  } y={y-8} width={width} height={height+16} rx="12" fill={color}/>
        </>
    )
}


const ChartBar = ({height, theme, data, isLoading}) => {

    if(!data) return null

    return <div className={style.chart} style={{height: height}}>
        {
            isLoading &&
            <div className={style.loader}>
                <Preloader/>
            </div>
        }
        <ResponsiveContainer>
            <BarChart data={data}
                margin={{top:10, left: 10, bottom: 10}}
            >
                <Bar
                    dataKey="value"
                    shape={
                    <BarShape
                        color={theme==='blue'?'#FFFFFF':'#3C4AE4'}
                        bgColor={theme==='blue'?'#F9E7E7':'#E6F1FF'}
                    />
                }
                />
                <Tooltip
                    cursor={false}
                    content={<CustomTooltip/>}
                />
                <XAxis
                    axisLine={false}
                    tickLine={false}
                    dataKey={'name'}
                    tickMargin={16}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    tickMargin={16}
                />
            </BarChart>
        </ResponsiveContainer>
    </div>

}

export default ChartBar


