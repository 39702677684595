import {makeAutoObservable} from "mobx";
import {getUserBanReasons} from "../services/AuthService";
import {getCompetencyBanReasons} from "../services/CompetencyService";

export default class BanRulesStore {
    data = []
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    setData(data) {
        this.data = data
    }

    setIsLoading(bool) {
        this.isLoading = bool
    }

    async getData(type) {
        this.setIsLoading(true)
        let data
        try {
            type === 'Пользователи'
                ? data = await getUserBanReasons()
                : data = await getCompetencyBanReasons()
            this.setData(data)
        } catch (e) {
            throw e
        } finally {
            this.setIsLoading(false)
        }

    }
}