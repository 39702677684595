import {useState} from "react";

import style from './DropdownButton.module.scss'
import fonts from '../../../../../static/fonts.module.scss'
import {ReactComponent as TriangleBlueIcon} from "./icons/Arrow - Down 2.svg";
import {ReactComponent as TriangleWhiteIcon} from "./icons/actionArrow.svg";
import {ReactComponent as RedEditIcon} from "./icons/Paper Negative.svg";
import {ReactComponent as EditIcon} from "./icons/editIcon.svg";
import {ReactComponent as AltIcon} from "./icons/altIcon.svg";

import {Link} from "react-router-dom";

const DropdownButton = ({text, type = 'main', actions, nested_styles}) => {
    const [animation, setAnimation] = useState('fadeIn')

    const actionList = actions.map(({label, func, link, type}, index) => {
        if(type === 'link'){
            return <Link
                key={index}
                className={`${fonts.button} ${style.dropdownButton}`}
                to={link}
            >
                {label}
            </Link>
        }
        return <button
            key={index}
            className={`${fonts.button} ${style.dropdownButton}`}
            onMouseDown={func}
        >
            {label}
        </button>
    })

    const [dropdownShown, setDropdownState] = useState(false)

    return (
        <div className={style.wrap}>
            <button
                style={nested_styles}
                className={`
                    ${style.actionButton} 
                    ${fonts.button}
                    ${type && style[type]}
                `}
                onClick={() => {
                    setAnimation('fadeIn')
                    setDropdownState(!dropdownShown)
                }}
                onBlur={() => {
                    setAnimation('fadeOut')
                    setTimeout(() => setDropdownState(false), 200)
                }}
            >
                {type === 'red' && <RedEditIcon/>}
                {type === 'icon' && <EditIcon/>}
                {type === 'altIcon' && <AltIcon/>}
                {type !== 'icon' && text}
                {type === 'secondary' && <TriangleBlueIcon/>}
                {type === 'main' && <TriangleWhiteIcon/>}
            </button>
            {
                dropdownShown && <div className={`${style.actionDropdownWrap} ${animation}`}>{actionList}</div>
            }
        </div>
    )
}

export default DropdownButton