import s from './FileInput.module.scss'
import {ReactComponent as FileIcon} from "./file.svg";
import {ReactComponent as DocumentIcon} from "./document.svg";
import f from "../../../../../static/fonts.module.scss";
import {notifications} from "../../../../../index";
import Compressor from 'compressorjs';

async function getImageDimensions(file) {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    await img.decode();
    let width = img.width;
    let height = img.height;
    return {
        width,
        height,
    }
}


const getAcceptableTypes = (typesArray) => {
    let resultString = ""
    const dict = new Map(Object.entries({
        'image/png': '.png, ',
        'image/jpg': '.jpg, ',
        'image/jpeg': '.jpeg, ',
        'application/pdf': '.pdf, ',
        'video/mp4': '.mp4, '
    }))

    typesArray.forEach((type) => {
        resultString = resultString + dict.get(type)
    })
    return resultString
}

const FileInput = ({
                       imageObject, setImageObject,
                       imageUrl, setImageUrl,
                       size = 40, types = ['image/png', "image/jpg", "image/jpeg"],
                       imageRef, type,
                       text = 'Загрузите файл .jpg или .png размером не менее 25х25 и весом не более 10МБ'
                   }) => {


    const compressImage = (e) => {
        if (
            e.target.files[0].type === 'image/png' ||
            e.target.files[0].type === 'image/jpg' ||
            e.target.files[0].type === 'image/jpeg'
        ) {
            getImageDimensions(e.target.files[0]).then(
                (result) => {
                    if (result.width < 25 || result.height < 25) {
                        notifications.addNotification('Изображение слишком маленькое')
                        return
                    }
                    new Compressor(e.target.files[0], {
                        quality: 0.6,
                        maxWidth: 1000,
                        maxHeight: 1000,
                        success(result) {
                            setImageObject(result)
                            setImageUrl(URL.createObjectURL(result))
                        }
                    })
                }
            )
        } else {
            const url = URL.createObjectURL(e.target.files[0])
            setImageUrl(url)
            setImageObject(e.target.files[0])
        }
    }


    const handleFileUpload = (e) => {
        //Был ли файл выбран
        if (e.target.files[0]) {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                notifications.addNotification('Размер файла превышает 10 мБ')
            } else {
                let passed = false
                for (const typesKey of types) {
                    if (e.target.files[0].type === typesKey) {
                        passed = true
                    }
                }
                passed
                    ? compressImage(e)
                    : notifications.addNotification('Неверный формат файла')
            }

        }
    }

    return <label className={s.uploadIconWrap}>
        <input
            type="file"
            accept={getAcceptableTypes(types)}
            onChange={(e) => handleFileUpload(e)}
            id='uploadCover'
        />
        {
            imageObject
                ? imageObject?.type === 'video/mp4' || type === 'video'
                    ? <video className={s.videoView} controls loop ref={imageRef}>
                        <source src={imageUrl}/>
                    </video>
                    : <div className={s.iconPreview} style={{width: size, height: size}}>
                        {
                            imageObject.type === 'image/png' ||
                            imageObject.type === 'image/jpg' ||
                            imageObject.type === 'image/jpeg'
                                ? <img src={imageUrl} alt=""/>
                                : <DocumentIcon/>
                        }

                    </div>
                : <FileIcon/>
        }
        {
            !imageObject
                ? <p className={f.subtitle1Light}>{text}</p>
                : <p className={f.subtitle1Bold}>{imageObject.name}</p>
        }
    </label>
}

export default FileInput
