import ChartArea from "../../DashboardBlock/ChartArea/ChartArea";
import {useEffect, useState} from "react";
import {getIncomeStats} from "../../../../../services/StatisticService";
import {formatDataForAreaCharts} from "../../../../../static/utilities";

const Graph = ({period, sumSetter}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getIncomeStats(period)
                const sum = req.reduce((acc, curr) => acc + +curr[1], 0)
                sumSetter(sum)
                setData(req.map(item => ([item[0], +item[1]])))
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period, sumSetter])

    if (data?.length === 0) return null


    return <ChartArea unit={'₽'} height={320} data={formatDataForAreaCharts(data, period)} isLoading={isLoading}/>
}

export default Graph