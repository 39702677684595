import s from './Tooltip.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useState} from "react";


const Tooltip = ({title, text}) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(prev => !prev)
    }

    const leave = () => {
        setIsOpen(false)
    }

    return <div className={s.wrapper} onMouseLeave={leave}>
        <button className={`${s.visible} ${f.subtitle2Bold}`} onClick={toggle}>?</button>
        <div className={`${s.hiddenPart} ${isOpen ? s.active : ""}`}>
            <p className={f.subtitle2Light} dangerouslySetInnerHTML={{__html: text}}/>
        </div>
    </div>
}

export default Tooltip