import s from './Faq.module.scss'
import f from '../../../static/fonts.module.scss'
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {useEffect, useState} from "react";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import {createFAQ, deleteFAQ, editFAQ, getFAQList} from "../../../services/SupportService";
import {ReactComponent as EditIcon} from "../Blog/icon/editIcon.svg";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import Select from "../../_BASE/UI/Select/Select";
import Input from "../../_BASE/UI/Input/Input";
import Radios from "../../_BASE/UI/Radios/Radios";
import {notifications} from "../../../index";
import Table from "../../_BASE/Layout/Table/Table";


const CreateModal = ({data, setCreateModal, getData, closeFunc = setCreateModal}) => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [link, setLink] = useState('');
    const [hasLink, setHasLink] = useState(false);
    const [type, setType] = useState('');

    const faqType = (type) => {
        if (type === 'Для учеников') {
            return "STUDENT"
        }

        if (type === 'Для учителей') {
            return "TEACHER"
        }

        if (type === 'Для всех') {
            return "FOR_ALL"
        }
    }

    const clickCreate = async () => {
        if (question?.length < 3) {
            notifications.addNotification("Вопрос слишком короткий")
            return
        }
        if (answer?.length < 3) {
            notifications.addNotification("Ответ слишком короткий")
            return
        }
        if (link?.length < 3 && hasLink) {
            notifications.addNotification("Ссылка слишком короткая")
            return
        }
        if (!type) {
            notifications.addNotification("Укажите тип")
            return
        }
        try {
            data
                ? await editFAQ(data.id,
                    {
                        question: question,
                        answer: answer,
                        link: hasLink ? link : 'NULL',
                        faqCode: faqType(type)
                    }
                )
                : await createFAQ(
                    {
                        question: question,
                        answer: answer,
                        link: hasLink ? link : 'NULL',
                        faqCode: faqType(type)
                    }
                )
            await getData()
            setCreateModal(false)

        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        if (data) {
            setQuestion(data.question)
            setAnswer(data.answer)
            if (data?.link?.length > 0) {
                setHasLink(true)
                setLink(data.link)
            }
            setType(data.title)
        }
    }, [data])

    return <ModalWindow type={'small'} title={'Создание нового FAQ'}
                        closeFunc={closeFunc}
                        primaryAction={clickCreate}
    >
        <div className={s.createWrap}>
            <Select selectedOption={type} setSelectedOption={setType}
                    options={['Для учеников', "Для учителей", "Для всех"]}
                    title={'Укажите тип FAQ'}
            />
            <Input value={question} setValue={setQuestion}
                   title={"Укажите вопрос"}
                   styles={{maxWidth: "100%"}}
            />
            <Input value={answer} setValue={setAnswer}
                   type={"textarea"} title={'Укажите ответ'}
                   styles={{maxWidth: "100%"}}
            />
            <Radios
                checkedValue={hasLink}
                setCheckedValue={setHasLink}
                groupName={'hasLink'}
                size={'small'}
                data={[
                    {
                        value: false,
                        title: 'Без ссылки'
                    },
                    {
                        value: true,
                        title: 'С ссылкой'
                    },
                ]}
            />
            {
                hasLink && <Input value={link} setValue={setLink}
                                  title={'Ссылка'} styles={{maxWidth: "100%"}}/>
            }
        </div>
    </ModalWindow>
}

const TableRow = ({data, getData, setCreateModal}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const rowActions = [
        {
            label: 'Изменить',
            func: () => setEdit(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }
    ]

    const getTag = (tag) => {
        let style
        if (tag === 'Для учеников') {
            style = s.blueTag
        }

        if (tag === 'Для учителей') {
            style = s.yellowTag
        }

        if (tag === 'Для всех') {
            style = s.blackTag
        }

        return <p className={`${s.tag} ${style} ${f.caption}`}>{tag}</p>
    }

    const deleteClick = async () => {
        try {
            await deleteFAQ(data.id)
            await getData()
            setDeleteModal(false)
        } catch (e) {
            throw e
        }
    }


    return <>
        <p className={f.subtitle1Bold}>{data.question}</p>
        <p className={f.body1}>{`${data.answer.slice(0, 160)}${data.answer.length > 160 ? '...' : ''}`}</p>
        {
            getTag(data.title)
        }
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} nested_styles={{background: "white"}} text={<EditIcon/>}
                            actions={rowActions}/>
        </div>
        {
            edit && <CreateModal closeFunc={setEdit} getData={getData} setCreateModal={setCreateModal} data={data}/>
        }
        {
            deleteModal && <ModalWindow
                primaryAction={deleteClick}
                closeFunc={setDeleteModal} title={"Удалить FAQ"}
                type={'small'}
            >
                <p className={f.subtitle1Bold}>Вы хотите удалить запись. Вы уверенны?</p>
            </ModalWindow>
        }
    </>
}

const Faq = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [createModal, setCreateModal] = useState(false);
    const actions = [
        {
            label: 'Создать новый FAQ',
            func: () => setCreateModal(true)
        }
    ]
    const getData = async () => {
        try {
            await setIsLoading(true)
            const req = await getFAQList()
            setData(req)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        getData()

    }, [])

    if (isLoading) {
        return <Preloader type={'big'}/>
    }

    return <div className={s.wrap}>
        <div className={s.topLine}>
            <DropdownButton text={'Действия'} actions={actions}/>
        </div>
        <Table gridTemplate={'1fr 1fr 1fr 92px'}
               headsArray={['ВОПРОС', 'ОТВЕТ', 'ТИП', 'ДЕЙСТВИЕ']}
               rowsArray={data.map((item) => <TableRow key={item.id}
                                                       data={item}
                                                       setCreateModal={setCreateModal}
                                                       getData={getData}
               />)}
        />
        {
            createModal && <CreateModal getData={getData} setCreateModal={setCreateModal}/>
        }
    </div>
}

export default Faq
