import s from './CustomStackedBarChart.module.scss'
import Preloader from "../../UI/Preloader/Preloader";
import {ResponsiveContainer, Tooltip, XAxis, YAxis, BarChart, Bar} from "recharts";
import {GRAPH_COLORS} from "../../../../static/utilities";
import {getGraphLabel} from "../CustomAreaChart/AreaChart";

const BarShape = ({height, width, x, y, color, bgColor, type = 'default'}) => {
    return <>
        {
            type === 'first' &&
            <rect x={x} y={0} width={width} height={'calc(100% - 32px)'} rx="12" fill={bgColor} opacity={.25}/>
        }
        {
            type === 'first'
                ? <rect x={x} y={y - 6} width={width} height={height} rx="6" fill={color}/>
                : height !== 0 &&
                <path
                    d={`M${x},${y + height}
                        v${-height}
                        q0,-6 6,-6
                        h${width - 12}
                        q6,0 6,6
                        v${height}
                        z`}
                    fill={color}
                />
        }
    </>
}

const CustomStackedBarChart = ({data, dataBarNames, isLoading, height = 350}) => {
    if (!data || data.length === 0) return <Preloader style={{height: height}}/>

    return <div className={s.wrap}>
        {
            isLoading &&
            <div className={s.loader}>
                <Preloader/>
            </div>
        }
        <ResponsiveContainer height={height} width="100%">
            <BarChart
                data={data}
                margin={{
                    top: 8,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip cursor={false} formatter={(value, name, props) => [value, getGraphLabel(name)]}/>
                {
                    dataBarNames.map((name, index) => {
                        let type = 'default'
                        if (index === 0 || name ==='other') {
                            type = 'first'
                        }
                        return <Bar dataKey={name}
                                    stackId={name === 'other' ? 'other' : 'genders'}
                                    fill={GRAPH_COLORS[index]}
                                    shape={<BarShape
                                        color={GRAPH_COLORS[index]}
                                        bgColor={'#F9E7E7'}
                                        type={type}
                                    />}
                        />
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    </div>

}

export default CustomStackedBarChart