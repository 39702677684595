import {useEffect, useState} from "react";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";
import Radios from "../../../_BASE/UI/Radios/Radios";
import Slider from "../../../_BASE/UI/Slider/Slider";
import Select from "../../../_BASE/UI/Select/Select";

import style from './CreateNewStudent.module.scss'
import fonts from '../../../../static/fonts.module.scss'

import {ReactComponent as SchoolIcon} from "./icon/School.svg";
import {ReactComponent as StudentIcon} from "./icon/Student.svg";
import {registerNewUser} from "../../../../services/AuthService";
import {registerNewCompetencyProfile} from "../../../../services/CompetencyService";
import {formatGender} from "../../../../static/utilities";
import {notifications} from "../../../../index";


const CreateNewStudent = ({closeModalFunc, getData}) => {
    const [name, setName] = useState('')
    const [nameIsValid, setNameIsValid] = useState(false)
    const [surname, setSurname] = useState('')
    const [surnameIsValid, setSurnameIsValid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [phone, setPhone] = useState('89')
    const [phoneIsValid, setPhoneIsValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordIsValid, setPasswordIsValid] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(false)
    const [type, setType] = useState('')
    const [typeIsValid, setTypeIsValid] = useState(false)
    const [passwordsAreEqual, setPasswordsAreEqual] = useState(false)
    const [registeredUser, setRegisteredUser] = useState('')
    const [dob, setDob] = useState(null)
    const [gender, setGender] = useState("Не выбрано");

    const [step1isValid, setStep1isValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        if (password === confirmPassword && password.length > 5) {
            setPasswordsAreEqual(true)
        } else {
            setPasswordsAreEqual(false)
        }
    }, [password, confirmPassword])

    useEffect(() => {
        if (type.length > 0) {
            setTypeIsValid(true)
        }
    }, [type])

    useEffect(() => {
        if (nameIsValid
            && emailIsValid
            && phoneIsValid
            && surnameIsValid
            && passwordIsValid
            && confirmPasswordIsValid
            && passwordsAreEqual) {
            setStep1isValid(true)
        } else {
            setStep1isValid(false)
        }
    }, [nameIsValid, emailIsValid, phoneIsValid, surnameIsValid, passwordIsValid, confirmPasswordIsValid, passwordsAreEqual]);


    const [currentStage, setCurrentStage] = useState(0)

    const handleRegisterNewUser = async () => {
        try {
            setIsLoading(true)
            const request = await registerNewUser({
                roleId: 'student',
                name: name,
                surname: surname,
                phone: phone,
                email: email,
                password: password,
                gender: formatGender(gender),
                dob: dob
            })
            if (request.status === 200) {
                setRegisteredUser(request.data.user)
                setCurrentStage(1)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    //Регистрация профиля компетенций пользователя
    const handleRegisterCompetencyProfileClick = async () => {
        try {
            setIsLoading(true)
            const request = await registerNewCompetencyProfile({
                institution: type,
                userId: registeredUser.userId,
                name: registeredUser.name,
                surname: null,
                middlename: null,
                userRoleTitleCode: 'STUDENT'
            })
            if (request.status === 200) {
                notifications.addNotification("Ученик успешно зарегистрирован", 'OK')
                await getData()
                closeModalFunc(false)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <ModalWindow
                title={`Создание нового ученика`}
                closeFunc={closeModalFunc}
            >
                <Slider width={824} currentStage={currentStage}>
                    <div className={style.stage1}>
                        <p className={`${style.description} ${fonts.boldH5}`}>Шаг 1. Укажите личную информацию
                            нового
                            ученика</p>
                        <div className={style.stage1Inputs}>
                            <div className={style.doubleRow}>
                                <div>
                                    <Input
                                        title={"Укажите имя"}
                                        value={name}
                                        setValue={setName}
                                        setIsValid={setNameIsValid}
                                        validator={'name'}
                                        placeholder={'Ваня'}
                                    />
                                </div>
                                <div>
                                    <Input
                                        title={"Укажите фамилию"}
                                        value={surname}
                                        setValue={setSurname}
                                        setIsValid={setSurnameIsValid}
                                        validator={'name'}
                                        placeholder={'Петров'}
                                    />
                                </div>
                                <div>
                                    <Input
                                        title={"Укажите дату рождения"}
                                        value={dob}
                                        setValue={setDob}
                                        type={'date'}
                                        placeholder={'01.01.1990'}
                                        min={'1930-01-01'}
                                        max={new Date().toISOString().split('T')[0]}
                                    />
                                </div>
                                <div>
                                    <Select title={'Укажите пол'} selectedOption={gender}
                                            setSelectedOption={setGender}
                                            options={['Мужской', 'Женский', 'Не выбрано']}/>
                                </div>
                            </div>

                            <div className={style.doubleRow}>
                                <Input
                                    title={"Укажите E-mail"}
                                    value={email}
                                    setValue={setEmail}
                                    setIsValid={setEmailIsValid}
                                    validator={'email'}
                                    placeholder={'ivanovii@gmail.com'}
                                />
                                <Input
                                    title={"Укажите телефон"}
                                    value={phone}
                                    setValue={setPhone}
                                    setIsValid={setPhoneIsValid}
                                    validator={'phone'}
                                    maxLength={11}
                                    placeholder={'89998887766'}
                                />
                            </div>
                            <div className={style.doubleRow}>
                                <Input
                                    title={"Укажите пароль"}
                                    value={password}
                                    setValue={setPassword}
                                    setIsValid={setPasswordIsValid}
                                    validator={'password'}
                                    password
                                    placeholder={'*******'}
                                />
                                <div style={{position: "relative"}}>
                                    <Input
                                        title={"Повторите пароль"}
                                        value={confirmPassword}
                                        setValue={setConfirmPassword}
                                        setIsValid={setConfirmPasswordIsValid}
                                        validator={'password'}
                                        password
                                        placeholder={'*******'}
                                    />
                                    {
                                        !passwordsAreEqual && password.length > 5
                                        && <p className={`${fonts.subtitle2Bold} ${style.notEqual}`}
                                              style={{color: 'red', marginTop: 4}}>
                                            Пароли не совпадают
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={style.stage2}>
                        <div>
                            <p className={`${style.description} ${fonts.boldH5}`}>Шаг 2. Выберите тип нового
                                ученика</p>
                            <Radios
                                data={[
                                    {title: 'Школьник', icon: <SchoolIcon/>, value: 'SCHOOL'},
                                    {title: 'Студент', icon: <StudentIcon/>, value: 'VUZ'}]}
                                checkedValue={type}
                                setCheckedValue={setType}
                            />
                        </div>
                    </div>
                </Slider>
                {
                    currentStage === 0 && <Button text={'Продолжить'}
                                                  click={() => handleRegisterNewUser()}
                                                  disabled={!step1isValid || isLoading}
                    />
                }
                {
                    //Кнопка регистрации профиля компетенций
                    currentStage === 1 &&
                    <Button text={"Создать"}
                            click={() => handleRegisterCompetencyProfileClick()}
                            disabled={!typeIsValid || isLoading}
                    />

                }

            </ModalWindow>
        </>
    )
}

export default CreateNewStudent