import s from './DocumentEditorModal.module.scss'
import 'react-quill/dist/quill.snow.css';

import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useCallback, useEffect, useState} from "react";
import ReactQuill from "react-quill";
import Input from "../../../_BASE/UI/Input/Input";
import {editStaticDocument, getStaticDocument} from "../../../../services/FileService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";


const DocumentEditorModal = ({closeFunc, data, updateData}) => {
    const [text, setText] = useState('');
    const [name, setName] = useState('');

    const [isLoading, setIsLoading] = useState(true);


    const getData = useCallback(async () => {
            try {
                setIsLoading(true)
                const fetchedData = await getStaticDocument(data.code)
                setName(fetchedData.title)
                setText(fetchedData.content)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }
    , [data.code])
    const save = async () => {
        try {
            await editStaticDocument({
                code: data.code,
                title: name,
                content: text
            })
            closeFunc(false)
            await updateData()

        } catch (er) {
            console.log(er)
        }
    }

    useEffect(() => {
        getData()
    }, [getData])

    return <ModalWindow
        closeFunc={closeFunc}
        type={'small'}
        title={'Редактирование важного документа'}
        primaryButtonTitle={'Сохранить'}
        primaryAction={() => save()}

    >
        {
            isLoading
                ? <Preloader/>
                : <div className={s.wrap}>
                    <Input value={name} setValue={setName} title={'Название документа'}
                           styles={{maxWidth: "100%"}}
                    />
                    <ReactQuill theme="snow" value={text} onChange={setText}
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, false] }],
                                        ['bold', 'italic', 'underline'],
                                        ['image', 'code-block']
                                    ],
                                    keyboard: {
                                        bindings: {
                                            'list autofill': false
                                        }
                                    }
                                }}
                    />
                </div>
        }
    </ModalWindow>
}

export default DocumentEditorModal
