import SupportCategories from "../../../components/_PAGES/SupportCategories/CreateTicketReasons/SupportCategories";
import {useEffect} from "react";
import TopNavbarTitle from "../../../store/TopNavbarTitle";


const SupportCategoriesPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Категории обращений в техническую поддержку')
    }, [])


    return <SupportCategories key={'helpdeskSettings'}/>
}

export default SupportCategoriesPage