import s from './Sections.module.scss'
import f from '../../../../../static/fonts.module.scss'
import Table from "../../../../_BASE/Layout/Table/Table";
import {useEffect, useState} from "react";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import CreateModal from "./CreateModal/CreateModal";
import DeleteModal from "./DeleteModal/DeleteModal";

const Sections = ({sections, getSections}) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const load = async () => {
            try {
                setIsLoading(true)
                await getSections()
                setIsLoading(false)
            } catch (e) {
                console.log(e)
            }
        }

        load()
    }, [getSections])

    if (isLoading) return <Preloader type={'big'}/>

    return <Table
        headsArray={['', 'Название', "Видимость", "Действие"]}
        gridTemplate={'40px 1fr 250px 92px'}
        rowsArray={sections.map(section => <SingleRow
            getSections={getSections}
            key={section.id} rowData={section}/>)}
    />
}

const SingleRow = ({rowData, getSections}) => {
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const actions = [
        {
            label: 'Изменить',
            func: () => setEditModal(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }
    ]
    return <>
        <img className={s.icon} src={rowData.imageLink} alt=""/>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        {colorType(rowData.typeTitle)}
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
        {
            editModal && <CreateModal closeFunc={setEditModal}
                                      updateData={getSections}
                                      data={rowData}/>
        }
        {
            deleteModal && <DeleteModal closeFunc={setDeleteModal}
                                        sectionTitle={rowData.title}
                                        sectionId={rowData.id}
                                        updateData={getSections}
            />
        }
    </>
}

export const colorType = (text) => {
    switch (text) {
        case 'Для учеников' :
            return <div style={{display: 'flex'}}>
                <p className={`${f.subtitle2Bold} ${s.tag} ${s.blue}`}>{text}</p>
            </div>
        case 'Для учителей' :
            return <div style={{display: 'flex'}}>
                <p className={`${f.subtitle2Bold} ${s.tag} ${s.yellow}`}>{text}</p>
            </div>
        case 'Для всех' :
            return <div style={{display: 'flex'}}>
                <p className={`${f.subtitle2Bold} ${s.tag} ${s.black}`}>{text}</p>
            </div>
        default:
            return
    }
}

export default Sections