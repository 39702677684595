import s from './Cards.module.scss'
import SingleCard from "../../../Dashboard/StatCards/SingleCard/SingleCard";

import {getRegisteredUsers} from "../../../../../services/StatisticService";
import {useState, useEffect} from "react";

import {ReactComponent as Card1} from "./card1.svg";
import {ReactComponent as Card2} from "./card2.svg";
import {ReactComponent as Card3} from "./card3.svg";
import {ReactComponent as Card4} from "./card4.svg";
import Card from "../../../Dashboard/Card/Card";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";

const Cards = () => {


    return <div className={s.wrap}>
        <SingleCard title={'Новых учеников'} theme={'blue'} icon={<Card1/>}
                    getDataApi={async () => await getRegisteredUsers('DAY', 'STUDENT')}
                    periods={[
                        {
                            text: 'Сегодня',
                            code: 'DAY'
                        }
                    ]}
                    style={{
                        padding: 32
                    }}
        />
        <SingleCard title={'Новых учителей'} icon={<Card2/>}
                    getDataApi={async () => await getRegisteredUsers('DAY', 'TEACHER')}
                    periods={[
                        {
                            text: 'Сегодня',
                            code: 'DAY'
                        }
                    ]}
                    style={{
                        padding: 32
                    }}
        />
        <SingleCard title={'Всего пользователей'} theme={'blue'} icon={<Card3/>}
                    getDataApi={async (period) => await getRegisteredUsers(period, 'ALL_USERS')}
                    periods={[
                        {
                            text: 'Сегодня',
                            code: 'DAY'
                        },
                        {
                            text: 'За все время',
                            code: 'ALL_TIME'
                        }
                    ]}
                    style={{
                        padding: 32
                    }}/>
    </div>
}

export const BlockedUsersCard = () => {
    const types = [
        {
            label: 'Ученики',
            func: () => setCurrentType('STUDENT')
        }, {
            label: 'Учителя',
            func: () => setCurrentType('TEACHER')
        },

    ]
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(0);
    const [currentType, setCurrentType] = useState('STUDENT');

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const res = await getRegisteredUsers('ALL_TIME', 'BLOCK')
                setData(res)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [])

    if (isLoading) return <div className={s.emptyList}>
        <Preloader/>
    </div>
    return <Card title={'Заблокировано'} tag={currentType === 'STUDENT' ? "Ученики" : "Учителя"} actions={types}
                 style={{padding: 32}}
                 counter={currentType === 'STUDENT' ? data.student : data?.teacher}
                 icon={<Card4/>}
    />
}

export default Cards