import {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {CategoriesStore} from "../CategoriesPage";

import SingleOptionRow from "./SingleOptionRow/SingleOptionRow";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../_BASE/Layout/Table/Table";

const Options = () => {
    const Store = CategoriesStore

    useEffect(() => {
        Store.getOptions()
    }, [Store])

    if (Store.isLoading) return <Preloader type={'big'}/>

    return <Table gridTemplate={'1fr 92px'}
                  headsArray={['Тип задания', "Действие"]}
                  rowsArray={Store.data.map((item) => <SingleOptionRow key={item.id} {...item}/>)}/>
}

export default observer(Options)