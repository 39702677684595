import $auth from "../http";
import {notifications} from "../index";

//Изменение рейтинга за счет коррекционного балла
export const updateRating = async (userCode, profileId, penaltyCode, penalty, comment) => {
    try {
        return await $auth.post('/v1/reviews/edit', {
            userCode: userCode,
            profileId: profileId,
            penaltyCode: penaltyCode,
            penalty: penalty,
            comment: comment
        })
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er) => {
            console.log(er)
        })
        throw e
    }
}

//Получение количества отзывов о пользователе
export const getReviewsCount = async (userCode, id) => {
    try {
        const count = await $auth.get(`/v1/reviews/get?userCode=${userCode}&id=${id}&offset=20&code=NUMBER_OF_PAGE`)
        return count.data.recordCount
    } catch (e) {
        throw e
    }
}

//Получение списка отзывов о пользователе
export const getReviews = async (userCode, id, page, offset) => {
    try {
        const data = await $auth.get(`/v1/reviews/get?userCode=${userCode}&id=${id}&page=${page}&offset=${offset}&code=LIST`)
        return data.data
    } catch (e) {
        throw e
    }
}

//Получение отзывов для заказа
export const getReviewsByOrder = async (id) => {
    try {
        const req = await $auth.get(`/v1/reviews/order/get?id=${id}`)
        return req.data
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        e.response.data.errors.forEach((er) => {
            console.log(er)
            notifications.addNotification(er.msg)
        })
    }
}