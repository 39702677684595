import {useState} from "react";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";

const EditDateModal = ({closeFunc, Store}) => {
    const [day, month, year] = Store.authProfile.dob.split('.')
    const [dob, setDob] = useState(`${year}-${month}-${day}`)


    return <ModalWindow
        title={"Изменить дату рождения "}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={"Сохранить"}
        primaryAction={async () => {
            await Store.updateUserAuthProfile('DOB', JSON.stringify({
                dob: dob
            }), Store.authProfile.userId)
            closeFunc(false)
        }}
        // primaryButtonIsDisabled={!emailIsValid}
    >
        <div style={{width: 550,}}>
            <Input title={'Дата рождения'}
                   type={'date'}
                   value={dob}
                   setValue={setDob}
                   styles={{maxWidth: '100%'}}
                   min={'1930-01-01'}
                   max={new Date().toISOString().split('T')[0]}
            />
        </div>
    </ModalWindow>
}

export default EditDateModal
