import {ReactComponent as Icon1} from "./icons/1.svg";
import {ReactComponent as Icon2} from "./icons/2.svg";
import {ReactComponent as Icon3} from "./icons/3.svg";
import {ReactComponent as Icon4} from "./icons/4.svg";
import {ReactComponent as Icon5} from "./icons/5.svg";
import {ReactComponent as Icon6} from "./icons/6.svg";
import {ReactComponent as Icon7} from "./icons/7.svg";
import {ReactComponent as Icon8} from "./icons/8.svg";
import {ReactComponent as Icon9} from "./icons/9.svg";
import {ReactComponent as Icon10} from "./icons/10.svg";
import {ReactComponent as Icon11} from "./icons/11.svg";
import {ReactComponent as Icon12} from "./icons/12.svg";
import {ReactComponent as Icon13} from "./icons/13.svg";
import {ReactComponent as Icon14} from "./icons/14.svg";
import {ReactComponent as Icon15} from "./icons/15.svg";
import {ReactComponent as Icon16} from "./icons/16.svg";
import {ReactComponent as Icon17} from "./icons/17.svg";
import {ReactComponent as Icon18} from "./icons/18.svg";
import {ReactComponent as Icon19} from "./icons/19.svg";
import {ReactComponent as Icon20} from "./icons/20.svg";
import {ReactComponent as Icon21} from "./icons/21.svg";

const mapStructure = {
    blocks: [
        {

            links: [
                {
                    link: '/blog',
                    IconSVG: <Icon1/>,
                    title: 'Блог',
                    id: 8
                },
                {
                    link: '/docs',
                    IconSVG: <Icon1/>,
                    title: 'Документы',
                    id: 30
                },
                {
                    link: '/settings',
                    IconSVG: <Icon20/>,
                    title: 'Параметры',
                    id: 14
                },
            ]
        },
        {
            blockHeader: 'Пользователи',
            links: [
                {
                    link: '/teachers',
                    IconSVG: <Icon2/>,
                    title: 'Учителя',
                    id: 1
                },
                {
                    link: '/students',
                    IconSVG: <Icon3/>,
                    title: 'Ученики',
                    id: 2
                },
                {
                    link: '/agents',
                    IconSVG: <Icon4/>,
                    title: 'Агенты ТП',
                    id: 3
                },
            ]
        },
        {
            blockHeader: 'Техническая поддержка',
            links: [
                {
                    link: '/requests',
                    IconSVG: <Icon5/>,
                    title: 'Работа с бонусами',
                    id: 4
                },
                {
                    link: '/helpdesk/categories',
                    IconSVG: <Icon6/>,
                    title: 'Категории обращений',
                    id: 19
                },
                {
                    link: '/helpdesk/tickets',
                    IconSVG: <Icon7/>,
                    title: 'Список обращений',
                    id: 21
                },
                {
                    link: '/feedback/mobile',
                    IconSVG: <Icon8/>,
                    title: 'Обратная связь (приложения)',
                    id: 18
                },
                {
                    link: '/feedback/web',
                    IconSVG: <Icon8/>,
                    title: 'Обратная связь (лендинг)',
                    id: 28
                },
            ]
        },
        {
            blockHeader: 'Заказы',
            links: [
                {
                    link: '/categories/school',
                    IconSVG: <Icon9/>,
                    title: 'Школьные категории',
                    id: 5
                },
                {
                    link: '/categories/university',
                    IconSVG: <Icon10/>,
                    title: 'Категории ВУЗа',
                    id: 12
                },
                {
                    link: '/top6',
                    IconSVG: <Icon11/>,
                    title: 'Топ 6',
                    id: 15
                },
                {
                    link: '/orders',
                    IconSVG: <Icon12/>,
                    title: 'Список заказов',
                    id: 11
                }
            ]
        },
        {
            blockHeader: 'Статистика',
            links: [

                {
                    link: '/stats/helpdesk',
                    IconSVG: <Icon13/>,
                    title: 'Техническая поддержка',
                    id: 19
                },
                {
                    link: '/stats/orders',
                    IconSVG: <Icon14/>,
                    title: 'Заказы',
                    id: 21
                },
                {
                    link: '/stats/finances',
                    IconSVG: <Icon15/>,
                    title: 'Финансы',
                    id: 20
                },
                {
                    link: '/stats/users',
                    IconSVG: <Icon16/>,
                    title: 'Пользователи',
                    id: 22
                },
            ]
        },
        {
            blockHeader: 'ПРАВОВАЯ ИНФОРМАЦИЯ',
            links: [
                {
                    link: '/rules',
                    IconSVG: <Icon17/>,
                    title: 'Причины блокировки пользователей',
                    id: 13
                },
                {
                    link: '/helpdesk/close-reasons',
                    IconSVG: <Icon18/>,
                    title: 'Причины закрытия обращения в ТП',
                    id: 20
                },
                {
                    link: '/faq',
                    IconSVG: <Icon19/>,
                    title: 'Вопрос - Ответ',
                    id: 17
                },
            ]
        },
        {
            blockHeader: 'Оплата',
            links: [
                {
                    link: '/promo',
                    IconSVG: <Icon21/>,
                    title: 'Промокоды',
                    id: 16
                },
                // {
                //     link: '/payments',
                //     IconSVG: <PaymentIcon/>,
                //     title: 'Выплаты учителям',
                //     id: 10
                // },
                {
                    link: '/payments/operations',
                    IconSVG: <Icon5/>,
                    title: 'Банковские операции',
                    id: 40
                },
            ]
        },
    ]
}

export default mapStructure
