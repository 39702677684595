import s from './Users.module.scss'

import Cards from "./Cards/Cards";
import Graphs from "./Graphs/Graphs";
import PopularTables from "./PopularTables/PopularTables";
import TopUsers from "./TopUsers/TopUsers";
import ActiveUsersGraph from "./ActiveUsersGraph/ActiveUsersGraph";
import AgeGroups from "./Graphs/AgeGroups/AgeGroups";

const Users = () => {
    return <div className={s.wrap}>
        <Cards/>
        <Graphs/>
        <AgeGroups/>
        <ActiveUsersGraph/>
        <PopularTables/>
        <TopUsers/>
    </div>
}

export default Users