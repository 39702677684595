import s from './ListModal.module.scss'
import f from '../../../../static/fonts.module.scss'
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useEffect, useState} from "react";
import {getReferralsCount, getReferralsList} from "../../../../services/PaymentService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import Table from "../../../_BASE/Layout/Table/Table";

const ListModal = ({closeFunc, id, type}) => {
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });

    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getCount = async () => {
            await setIsLoading(true)
            const count = await getReferralsCount(id, type)
            setTotal(count)
            await setIsLoading(false)
        }

        getCount()
    }, [id, type])

    useEffect(() => {
        const getList = async () => {
            setIsLoading(true)
            const data = await getReferralsList(id, type, paginationObject.page, paginationObject.offset)
            setData(data)
            setIsLoading(false)
        }

        getList()
    }, [paginationObject.page, paginationObject.offset, total, id, type])


    return <ModalWindow closeFunc={closeFunc} title={"Список активации промокода "}>
        {
            isLoading
                ? <Preloader/>
                : <div className={s.wrap}>
                    <Table rowsArray={data.map(item => <TableRow item={item}/>)}
                           gridTemplate={'2fr 3fr 5fr 4fr'}
                           headsArray={[
                               "ID",
                               'Имя',
                               'Фамилия',
                               'Дата Активации',
                           ]}

                    />
                    <Pagination
                        paginationObject={paginationObject}
                        setPaginationObject={setPaginationObject}
                        totalCount={total}
                    />
                </div>
        }
    </ModalWindow>
}

const TableRow = ({item}) => {
    return <>
        <p className={f.subtitle1Light}>{item.userId}</p>
        <p className={f.subtitle1Light}>{item.name}</p>
        <p className={f.subtitle1Light}>{item.surname}</p>
        <p className={f.subtitle1Bold}>{new Date(Number(item.dateOfUse)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        })}</p>
    </>
}

export default ListModal
