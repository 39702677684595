import s from './Tabs.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useLocation, redirect} from "react-router-dom";
import {useEffect} from "react";
import {has} from "mobx";

const Tabs = ({tabs, activeTab, setActiveTab, sideButtons}) => {
    const location = useLocation()

    useEffect(() => {
        const hash = location.hash.replace('#','')

        if(hash){
            const selectedTabLabel = tabs.find(tab=>tab.link === hash)
            if(selectedTabLabel){
                setActiveTab(selectedTabLabel.label)
            }
        }

    }, [location])

    return <div className={s.wrap}>
        <div className={s.top}>
            <div className={s.tabNavs}>
                {
                    tabs.map((tab) => {
                        return <button
                            className={`${s.tabButton} ${f.boldH6} ${activeTab === tab.label ? s.active : ''}`}
                            onClick={() => {
                                setActiveTab(tab.label)
                                if (tab.link) {
                                    window.location.hash = tab.link
                                }
                            }}
                            key={tab.label}
                        >{tab.label}
                        </button>
                    })
                }
            </div>
            {
                sideButtons
            }
        </div>

        {
            tabs.filter(tab => activeTab === tab.label)[0].content
        }
    </div>
}

export default Tabs