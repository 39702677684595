import React, {useContext, useEffect, useState} from "react";
import NavBlock from "./NavBlock";
import NavSingleLink from "./NavBlock/navSingleLink";
import mapStructure from "./mapStructure";
import {ReactComponent as HomeIcon} from './icons/Home.svg'
import {ReactComponent as LogoutIcon} from './icons/Logout.svg'
import {ReactComponent as ShowIcon} from './icons/arrow.svg'
import style from './NavSidebar.module.scss'
import fonts from '../../static/fonts.module.scss'
import {Context} from "../../index";
import {Link} from "react-router-dom";
import logoIcon from './logo.svg'

const NavSidebar = () => {
    const {store} = useContext(Context)

    const [logo, setLogo] = useState(<Link to={'/'} className={`${style.header} ${fonts.boldH5}`}><img alt={''} src={logoIcon}/>Решка
        ИС</Link>);
    const [menuHide, setMenuHide] = useState(
        localStorage.getItem("menuIsHidden") === 'true'
    );


    const blocks = mapStructure.blocks.map(({blockHeader, links}, index) =>
        <NavBlock key={index} blockHeader={blockHeader} links={links} menuIsHidden={menuHide}/>
    )


    useEffect(() => {
        if (!menuHide) {
            setTimeout(() => {
                setLogo(
                    <Link to={'/'} className={`${fonts.boldH5} ${style.logo} fadeIn`}><img alt='' src={logoIcon}/>Решка
                        ИС</Link>
                )
            }, 300)
        } else {
            setLogo("")
        }
        localStorage.setItem("menuIsHidden", menuHide ? "true" : "false")
    }, [menuHide])


    return (
        <div className={style.stickyWrap}>
            <div className={`${style.wrap} ${menuHide ? style.small : ''}`}>
                <div className={style.header}>
                    {
                        logo
                    }
                    <button className={`${style.hideMenu} ${menuHide ? style.active : ''}`}
                            onClick={() => {
                                const newValue = !menuHide
                                setMenuHide(newValue)
                            }}
                            title={"Изменить форму меню"}
                    >
                        <ShowIcon/>
                    </button>

                </div>
                <div className={`${style.flexCol} ${style.justifyBetween}  ${style.linksWrap}`}>
                    <NavSingleLink link='/' IconSVG={<HomeIcon/>} menuIsHidden={menuHide} title="Главная" id={0}/>
                    {blocks}
                </div>
                <div className={style.exitButton}>
                    <NavSingleLink link='/auth' onClick={() => store.logout()} IconSVG={<LogoutIcon/>} title="Выход"
                                   id={999} menuIsHidden={menuHide}/>
                </div>
            </div>
        </div>
    )

}

export default NavSidebar
