import {makeAutoObservable} from "mobx";


export default class CreateStoryMobX {
    isLoading = false
    stories = []
    idCounter = 0


    constructor() {
        makeAutoObservable(this)
    }

    setStories(data) {
        this.stories = data
    }

    addStory(data) {
        this.stories.push({
            id: this.idCounter.toString(),
            ...data
        })
        this.idCounter += 1

    }

    deleteStory(id) {
        let deleted = null
        this.stories.forEach((item, index) => {
            if (item.id === id) {
                const result = Array.from(this.stories);
                result.splice(index, 1);
                this.stories = result
                console.log(item)
                deleted = item
            }
        })
        return deleted
    }

    editStory(id, data) {
        this.stories.forEach((item, index) => {
            if (item.id === id) {
                this.stories[index] = data
            }
        })
    }

    wrapArray() {
        return this.stories.map((item, index) => {
            const itemTmp = {}
            Object.assign(itemTmp, item)
            delete itemTmp.id
            if (!itemTmp.storySrc) {
                itemTmp.storySrc = "NULL"
            }
            return {
                position: index + 1,
                ...itemTmp
            }
        })
    }
}