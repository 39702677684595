import s from './NotFoundTable.module.scss'
import f from "../../../../../static/fonts.module.scss";

import {useEffect, useState} from "react";
import {getNotFoundCompetenciesCount} from "../../../../../services/StatisticService";

import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../../_BASE/Layout/Table/Table";
import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import ExportModal from "../../ExportModal/ExportModal";


const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const SingleTableRow = ({rowData, i}) => {
    return <>
        <p className={f.subtitle1Bold}>{i}</p>
        <p className={f.body1}>{rowData.sTitle} - {rowData.oTitle}</p>
        <p className={f.subtitle1Bold}>{rowData.number}</p>
    </>
}

const SingleTable = ({title, period, type}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getNotFoundCompetenciesCount(period, type)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period, type])


    if (isLoading) return <Preloader type={'big'}/>
    return <div className={s.tableWrap}>
        <div className={s.line}>
            <h5 className={f.boldH5}>{title}</h5>
            <ExportModal title={`Компетенция, для которых не был найден исполнитель (${title})`} api={'/order/s4'}
                         params={{
                             type: type
                         }}/>
        </div>
        <Table rowsArray={data.map((item, i) => <SingleTableRow rowData={item} i={i + 1} key={item.sTitle + i}/>)}
               gridTemplate={'30px 2fr 1fr'}
               headsArray={['#', 'КОМПЕТЕНЦИЯ', 'Количество заказов']}/>
    </div>
}

const SearchTable = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    return <div className={s.wrap}>
        <div className={s.headline}>
            <h4 className={f.boldH4}>Компетенция, для которых не был найден исполнитель</h4>
            <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
        </div>
        <div className={s.tableGrid}>
            <SingleTable title={'Школьные компетенции'} type={'SCHOOL'} period={currentPeriod.code}/>
            <SingleTable title={'ВУЗ компетенции'} type={'VUZ'} period={currentPeriod.code}/>
        </div>
    </div>
}

export default SearchTable
