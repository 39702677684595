import s from './Finances.module.scss'

import Stats from "../../Dashboard/Stats/Stats";
import Cards from "./Cards/Cards";
import CompetenciesTable from "./CompetenciesTable/CompetenciesTable";
import PopularReferals from "./PopularReferals/PopularReferals";


const Finances = () => {
    return <div className={s.wrap}>
        <Cards/>
        <Stats/>
        <CompetenciesTable/>
        <PopularReferals/>
    </div>
}
export default Finances