import f from '../../../../static/fonts.module.scss'
import s from './OrderSettings.module.scss'

import {useEffect, useState} from "react";
import {getOrderSettings, updateOrderSettings} from "../../../../services/OrdersService";
import Table from "../../../_BASE/Layout/Table/Table";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";

const OrderSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            setIsLoading(true)
            const options = await getOrderSettings()
            setData(options)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) return <Preloader type={'big'}/>

    return <Table
        headsArray={['Название параметра', 'Значение', "Действие"]}
        gridTemplate={'1fr 200px 92px'}
        rowsArray={data.map(row => <SingleRow key={row.code} rowData={row}
                                              getData={getData}
        />)}
    />
}

const SingleRow = ({rowData, getData}) => {
    const [editModal, setEditModal] = useState(false);

    const actions = [
        {
            label: 'Изменить',
            func: () => setEditModal(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        <p className={f.subtitle1Bold}>{rowData.data}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            editModal && <EditModal data={rowData}
                                    closeFunc={setEditModal}
                                    getData={getData}
            />
        }
    </>
}

const EditModal = ({data, closeFunc, getData}) => {
    const [value, setValue] = useState(data.data);
    const [isSaving, setIsSaving] = useState(false);

    const saveValue = async () => {
        try {
            setIsSaving(true)
            await updateOrderSettings(data.code, value)
            await getData()
            closeFunc(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsSaving(false)
        }
    }


    return <ModalWindow type={'small'}
                        closeFunc={closeFunc}
                        title={'Изменение параметра'}
                        primaryButtonTitle={'Изменить'}
                        primaryButtonIsDisabled={isSaving}
                        primaryAction={saveValue}
    >
        <div className={s.wrap}>
            <p className={f.boldH6}>Название параметра: <span className={f.body1}>{data.title}</span></p>
            <Input
                value={value}
                setValue={setValue}
                title={'Значение'}
                type={'number'}
                styles={{maxWidth: "100%"}}
            />
        </div>

    </ModalWindow>
}


export default OrderSettings