import React, {useState} from "react";
import {ReactComponent as ArrowIcon} from "./icon/icon.svg";

import style from './Select.module.scss'
import fonts from '../../../../static/fonts.module.scss'

const Select = ({title, options, selectedOption, setSelectedOption}) => {
    const [isOpen, setIsOpen] = useState(false)

    const optionList = options.map((value, i) => {
        return <div
            className={style.singleOption}
            key={i}
            onMouseDown={() => {
                setIsOpen(false)
                setSelectedOption(value)
            }}
        >
            {value}
        </div>
    })


    return (
        <div className={style.wrap}>
            {
                title && <h6 className={`${fonts.boldH6} ${style.title}`}>{title}</h6>
            }
            <div
                tabIndex={'0'}
                className={style.viewArea}
                onClick={() => setIsOpen(!isOpen)}
                onBlur={() => setIsOpen(false)}
            >
                <p className={`${style.selectedText} ${fonts.body1}`}>{
                    selectedOption
                        ? selectedOption.length > 64
                            ? selectedOption.slice(0, 64) + '...'
                            : selectedOption
                        : 'Не выбрано'

                }
                </p>
                <div className={style.icon}>
                    <ArrowIcon/>
                </div>
            </div>
            {
                isOpen ?
                    <div className={style.dropdownWrap} style={{maxHeight: window.innerHeight * 0.3}}>
                        {optionList}
                    </div>
                    : null
            }
        </div>
    )
}

export default Select
