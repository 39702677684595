import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Tickets from "../../components/_PAGES/SupportTickets/Tickets";

const TicketsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Список обращений')
    }, [])

    return <Tickets/>
}

export default TicketsPage