import TopNavbarTitle from "../../store/TopNavbarTitle";
import {useEffect} from "react";
import Faq from "../../components/_PAGES/FAQ/Faq";


const FaqPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('FAQ')
    }, [])

    return <Faq/>
}

export default FaqPage