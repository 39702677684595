import s from './StatCharts.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useState} from "react";

import PopularCategories from "./PopularCategories/PopularCategories";
import TicketCountGraph from "./TicketCountGraph/TicketCountGraph";
import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import Tooltip from "../../../../_BASE/UI/Tooltip/Tooltip";

const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const text = 'На данном графике учитываются только обращения со статусом закрыто.'
const text2 = 'На этом графике учитываются все созданные обращения (даже закрытые)'
const StatCharts = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);

    return <div className={s.wrap}>

        <div className={s.headerWrapper}>
            <h4 className={f.boldH4}>Обращения</h4>
            <Tooltip text={text}/>
        </div>
        <div className={s.cols}>
            <div className={s.col}>
                <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
                <TicketCountGraph period={currentPeriod.code}/>
            </div>
            <div className={s.col}>
                <div style={{alignSelf: "flex-end"}} className={s.headerWrapper}>
                    <p className={f.subtitle1Bold}>Самые популярные категории </p>
                    <Tooltip text={text2}/>
                </div>
                <PopularCategories period={currentPeriod.code}/>
            </div>
        </div>
    </div>
}

export default StatCharts