import s from './BlockedUsers.module.scss'
import f from '../../../../static/fonts.module.scss'
import Table from "../../../_BASE/Layout/Table/Table";
import Select from "../../../_BASE/UI/Select/Select";
import {useCallback, useEffect, useState} from "react";
import {getBlockedUsersCount, getBlockedUsersList, getUserBanReasons, unbanUser} from "../../../../services/AuthService";
import RandomColorAvatar from "../../Dashboard/DashboardBlock/PersonList/SinglePerson/RandomColorAvatar/RandomColorAvatar";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";
import BlockUserModal from "../BlockUserModal/BlockUserModal";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";

const BlockedUsers = ({role}) => {
    const [selectedFilter, setSelectedFilter] = useState();
    const [reasons, setReasons] = useState([]);
    const [searchDateFrom, setSearchDateFrom] = useState('');
    const [searchDateTo, setSearchDateTo] = useState('');

    const [filterObject, setFilterObject] = useState({});

    const [isFiltering, setIsFiltering] = useState(false);


    const getReasons = async () => {
        try {
            const req = await getUserBanReasons()
            setReasons(req)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getReasons()
    }, [])

    const applyFilter = () => {
        setIsFiltering(true)
        setFilterObject({
            reasonId: getSelectedOptionId(),
            dateFrom: searchDateFrom,
            dateTo: searchDateTo
        })
    }

    const resetFilter = () => {
        setIsFiltering(false)
        setFilterObject({})
        setSelectedFilter(null)
        setSearchDateFrom('')
        setSearchDateTo('')
    }

    const getSelectedOptionId = () => {
        if (selectedFilter) {
            return reasons.filter(reason => reason.title === selectedFilter)[0].id
        } else return null
    }

    return <div className={s.wrap}>
        <div className={s.filtersWrap}>
            <div className={s.selector}>
                <Select setSelectedOption={setSelectedFilter} selectedOption={selectedFilter}
                        options={reasons.map(reason => reason.title)}
                    // title={'Причина блокировки'}
                />

            </div>
            <div className={s.dates}>
                <Input value={searchDateFrom} setValue={setSearchDateFrom}
                       styles={{flex: 1}}
                       type={'date'}/>
                <span>—</span>
                <Input value={searchDateTo} setValue={setSearchDateTo}
                       type={'date'}/>
            </div>
            <Button text={'Применить фильтр'} click={applyFilter}/>
            {
                isFiltering && <Button type={'secondary'} text={'Сбросить'} click={resetFilter}/>
            }
        </div>
        <SingleTable role={role} filterObject={filterObject}
                     key={filterObject.reasonId + filterObject.dateFrom + filterObject.dateTo}/>

    </div>
}

const SingleRow = ({rowData, getData, getCount}) => {
    const [blockModal, setBlockModal] = useState(false)
    const [unblockModal, setUnblockModal] = useState(false);

    const actions = [
        {
            label: 'Просмотр',
            type: 'link',
            link: `${rowData.id}`
        },
    ]

    if (!rowData.isDeleted) {
        actions.push({
            label: rowData.isBanned === 0 ? "Заблокировать" : "Разблокировать",
            func: () => rowData.isBanned === 0 ? setBlockModal(true) : setUnblockModal(true),
        })
    }

    return <>
        <div><RandomColorAvatar url={rowData.avatarLink}/></div>
        <p className={`${f.subtitle1Bold}`}>{rowData.surname} {rowData.name} {rowData.middlename}</p>
        <p className={`${f.subtitle1Bold}`}>{rowData.id}</p>
        <p className={`${f.subtitle1Bold}`}>{rowData.email}</p>
        <p className={`${f.subtitle1Bold}`}>{rowData.phone}</p>
        <p className={`${f.subtitle1Bold}`}>{rowData.blockDate}</p>
        <p className={`${f.subtitle1Bold}`}>{rowData.blockingTime}</p>
        <div style={{justifyContent: 'center', width: "100%", display: "flex"}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
        {
            blockModal && <BlockUserModal getData={getData} closeModalFunc={setBlockModal} userId={rowData.id}/>

        }
        {
            unblockModal && <ModalWindow
                type={'small'}
                closeFunc={setUnblockModal}
                title={`Разблокировать пользователя ${rowData.name}`}
                primaryButtonTitle={'Разблокировать'}
                primaryAction={async () => {
                    try {
                        await unbanUser(rowData.id)
                        setUnblockModal(false)
                        await getData()
                        await getCount()
                    } catch (e) {
                        throw e
                    }
                }
                }
            >
                <p className={`${f.body1}`}>Вы уверены?</p>
            </ModalWindow>
        }
    </>
}

const SingleTable = ({filterObject, role}) => {
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [total, setTotal] = useState();

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getBlockedUsersList(role,
                paginationObject.page,
                paginationObject.offset,
                filterObject?.reasonId,
                filterObject?.dateFrom,
                filterObject?.dateTo,
            )
            setIsLoading(false)
            setData(req)
        } catch (e) {
            console.log(e)
        }
    },[role, paginationObject.page, paginationObject.offset, filterObject.reasonId, filterObject.dateFrom, filterObject.dateTo])

    const getCount = useCallback(async () => {
        try {
            const req = await getBlockedUsersCount(role,
                filterObject?.reasonId,
                filterObject?.dateFrom,
                filterObject?.dateTo,
            )
            setTotal(req.userCount)
        } catch (e) {
            console.log(e)
        }
    }, [role, filterObject.reasonId, filterObject.dateFrom, filterObject.dateTo])


    useEffect(() => {
        getCount()
    }, [getCount])

    useEffect(() => {
        getData()
    }, [paginationObject.page, paginationObject.offset, getData])

    if (isLoading) return <Preloader type={'big'}/>

    return <>
        <Table headsArray={["", "Имя", "ID", "Email", "Телефон", "Был заблокирован", "Заблокирован до", "Действие"]}
               gridTemplate={'40px 2fr 1fr 2fr 2fr 2fr 2fr 92px'}
               rowsArray={data.map(row => <SingleRow
                   role={role} key={row.userId}
                   rowData={row}
                   getData={getData}
                   getCount={getCount}
               />)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total} hide={isLoading}
        />
    </>
}

export default BlockedUsers