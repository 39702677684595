import {useEffect, useRef, useState} from "react";

import style from "./TabsPano.module.scss"
import fonts from "../../../../static/fonts.module.scss";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import {Link, Route, Routes,} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {ReactComponent as RightIcon} from "./right.svg";
import {ReactComponent as LeftIcon} from "./left.svg";


const TabsPano = ({data}) => {


    const scrollHeaderRef = useRef()
    const [showButtons, setShowButtons] = useState(false);

    const currentLink = useLocation()
    const ar = currentLink.pathname.split('/')
    const currentTab = ar[ar.length - 1]


    const tabs = data.map(({label, link}) => {
        return (
            <Link
                key={link}
                className={`
                    ${style.tab} 
                    ${fonts.button} 
                    ${link === currentTab ? style.active : ""}
                `}
                to={link}
            >
                {label}
            </Link>
        )
    })

    const tabsContent = data.map(({content, link}, index) =>
        <Route path={link} element={content} key={index}/>)

    const handleLeftClick = () => {
        scrollHeaderRef.current.scrollLeft = scrollHeaderRef.current.scrollLeft - 100
    }

    const handleRightClick = () => {
        scrollHeaderRef.current.scrollLeft = scrollHeaderRef.current.scrollLeft + 100
    }

    useEffect(() => {
        if (scrollHeaderRef.current.scrollWidth > scrollHeaderRef.current.offsetWidth) {
            scrollHeaderRef.current.style.paddingBottom = '16px'
            setShowButtons(true)
        }

        window.onresize = () => {
            if (scrollHeaderRef.current.scrollWidth > scrollHeaderRef.current.offsetWidth) {
                scrollHeaderRef.current.style.paddingBottom = '16px'
                setShowButtons(true)
            } else {
                scrollHeaderRef.current.style.paddingBottom = '0'
                setShowButtons(false)
            }
        }

        console.log(data[0].content)
    }, [data])

    return (
        <>
            <div className={style.tabNavbar}>
                <div className={style.scrollbarWrap}>
                    <div className={style.tabs} ref={scrollHeaderRef}>
                        {tabs}
                    </div>
                    {
                        showButtons &&
                        <button className={style.leftButton}
                                onClick={() => handleLeftClick()}
                        >
                            <LeftIcon/>
                        </button>
                    }
                    {
                        showButtons &&
                        <button className={style.rightButton}
                                onClick={() => handleRightClick()}
                        >
                            <RightIcon/>
                        </button>
                    }
                </div>
            </div>

            <div className={style.tabContent}>
                <ErrorBoundary>
                    <Routes>
                        <Route path={''} element={data[0].content}/>)
                        {tabsContent}
                    </Routes>
                </ErrorBoundary>
            </div>
        </>
    )
}

export default TabsPano