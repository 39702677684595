import s from './OperationInfo.module.scss'
import f from '../../../../static/fonts.module.scss'

import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    cancelPaymentRequest,
    confirmPaymentRequest, confirmPayoutRequest,
    getOperationInfo, getOrderInfoFromBank,
    getPaymentInfoFromBank, getPayoutInfoFromBankAPI
} from "../../../../services/PaymentService";
import {timestampToDate} from "../../../../static/utilities";
import Button from "../../../_BASE/UI/Button/Button";
import SpecList from "../../../_BASE/Layout/SpecList/SpecList";

import {ReactComponent as Info} from "./Info.svg";
import {ReactComponent as Arrow} from "./Arrow.svg";

import Table from "../../../_BASE/Layout/Table/Table";
import OrderInfo from "./OrderInfo/OrderInfo";
import EmptyList from "../../../_BASE/Layout/Table/EmptyList/EmptyList";
import React from "react";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Radios from "../../../_BASE/UI/Radios/Radios";
import Input from "../../../_BASE/UI/Input/Input";
import {notifications} from "../../../../index";
import ReactJson from "react-json-view";


const OperationInfo = () => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const {id} = useParams()

    const [cancelModal, setCancelModal] = useState(false);
    const [confirmPaymentModal, setConfirmPaymentModal] = useState(false);
    const [confirmPayoutModal, setConfirmPayoutModal] = useState(false);
    const [paymentInfoModal, setPaymentInfoModal] = useState(false);
    const [orderInfoModal, setOrderInfoModal] = useState(false);
    const [payoutBankInfoModal, setPayoutBankInfoModal] = useState(false);

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getOperationInfo({id: id})
            setData(req)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }, [id])

    useEffect(() => {
        getData()
    }, [getData])


    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <div className={s.infoCol}>
            <PaymentInfo data={data}/>
            {
                data.invoice.cCode === 'ORDER_INVOICE' && <div className={s.buttons}>
                    <Button text={'Подтвердить платеж'}
                            click={() => setConfirmPaymentModal(true)}
                    />
                    <Button text={'Отменить платеж'}
                            type={'alertBorder'}
                            click={() => setCancelModal(true)}
                    />
                    <Button text={'Информация о платеже (Тинькофф)'}
                            type={'secondary'} icon={<Info/>}
                            click={() => setPaymentInfoModal(true)}
                    />
                    <Button text={'Информация о заказе (Тинькофф)'}
                            type={'secondary'} icon={<Info/>}
                            click={() => setOrderInfoModal(true)}
                    />
                </div>
            }
            {
                data.invoice.cCode === 'PAYOUT_INVOICE' && <div className={s.buttons}>
                    <Button text={'Подтвердить платеж'}
                            click={() => setConfirmPayoutModal(true)}
                    />
                    <Button text={'Информация о выплате (Тинькофф)'}
                            type={'secondary'} icon={<Info/>}
                            click={() => setPayoutBankInfoModal(true)}
                    />

                </div>
            }
            {
                (data.invoice.cCode === 'ADD_CUSTOMER_CARD_INVOICE'
                    || data.invoice.cCode === 'ADD_EXECUTOR_CARD_INVOICE') && <div className={s.buttons}>
                    <Button text={'Информация о платеже (Тинькофф)'}
                            type={'secondary'} icon={<Info/>}
                            click={() => setPaymentInfoModal(true)}
                    />
                    <Button text={'Отменить платеж'}
                            type={'alertBorder'}
                            click={() => setCancelModal(true)}
                    />
                </div>
            }

            <PaymentMethodInfo data={data}/>
            <ErrorInfo data={data}/>
            <CancelList data={data}/>
            <ConfirmList data={data}/>
            <RecurrentList data={data}/>
            <div className={s.historyWrap}>
                <h5 className={f.boldH5}>История платежа</h5>
                <Table headsArray={["Старый стаус", "Новый статус", "Дата"]}
                       gridTemplate={"3fr 3fr 2fr"}
                       rowsArray={data.paymentOrderHistory.map(rowData => <React.Fragment key={rowData.timestamp}>
                           <p className={f.body1}>{rowData.oldTitle}</p>
                           <p className={f.body1}>{rowData.newTitle}</p>
                           <p className={f.body1}>{timestampToDate(rowData.timestamp)}</p>
                       </React.Fragment>)}
                />
            </div>
        </div>
        <div className={s.orderInfo}>
            <OrderInfo orderId={data.invoice.order.orderId}/>
        </div>
        {
            cancelModal && <PaymentCancelModal
                closeFunc={setCancelModal}
                recordId={id}
                getData={getData}
            />
        }
        {
            confirmPaymentModal && <ConfirmPaymentModal
                getData={getData}
                closeFunc={setConfirmPaymentModal}
                recordId={id}
            />

        }
        {
            confirmPayoutModal && <ConfirmPayoutModal
                getData={getData}
                closeFunc={setConfirmPayoutModal}
                recordId={id}
            />

        }
        {
            payoutBankInfoModal && <PayoutBankInfoModal closeFunc={setPayoutBankInfoModal} id={data.invoice.paymentId}/>
        }
        {
            paymentInfoModal && <BankPaymentInfo closeFunc={setPaymentInfoModal} id={id}/>
        }
        {
            orderInfoModal && <OrderPaymentInfo closeFunc={setOrderInfoModal} id={data.invoice.order.orderId}/>
        }
    </div>
}

const PayoutBankInfoModal = ({id, closeFunc}) => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const req = await getPayoutInfoFromBankAPI({id: id})
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            }
        }

        getData()
    }, [id])

    return <ModalWindow title={"Информация о выплате"}
                        closeFunc={closeFunc}>
        <div className={s.modalWrap}>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <ReactJson src={data.state}/>
            }
        </div>
    </ModalWindow>
}

const PaymentCancelModal = ({closeFunc, recordId, getData}) => {
    const [refundType, setRefundType] = useState("FULL");
    const [partialValue, setPartialValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const cancelRequest = async () => {
        try {
            setIsLoading(true)
            const req = await cancelPaymentRequest({
                cancelCode: refundType,
                id: recordId,
                amount: refundType === "PARTIAL" ? partialValue : 1
            })
            notifications.addNotification(req.message, "OK")
            await getData()
            closeFunc(false)
        } catch (e) {
            notifications.addNotification(e.response.data.message)
            console.log(e)
            throw e
        } finally {
            setIsLoading(false)
        }
    }


    return <ModalWindow title={'Отмена платежа'}
                        closeFunc={closeFunc}
                        type={'small'}
                        primaryAction={() => cancelRequest()}
                        primaryButtonIsDisabled={isLoading}
    >
        <div className={s.modalWrap}>
            <div className={s.radioWrap}>
                <h6 className={f.boldH6}>Тип отмены</h6>
                <Radios size={'small'}
                        checkedValue={refundType}
                        setCheckedValue={setRefundType}
                        data={[
                            {
                                title: "Полная сумма",
                                value: "FULL"
                            },
                            {
                                title: "Частичная сумма",
                                value: "PARTIAL"
                            },
                        ]}/>
            </div>
            {
                refundType === "PARTIAL" && <Input type={"number"}
                                                   value={partialValue}
                                                   setValue={setPartialValue}
                                                   title={'Сумма отмены'}
                                                   styles={{maxWidth: "100%"}}
                />
            }
            {
                isLoading && <div className={s.loader}><Preloader/></div>
            }
        </div>
    </ModalWindow>
}

const ConfirmPaymentModal = ({closeFunc, recordId, getData}) => {
    const [isLoading, setIsLoading] = useState(false);

    const confirmRequest = async () => {
        try {
            setIsLoading(true)
            const req = await confirmPaymentRequest({
                id: recordId,
            })
            notifications.addNotification(req.message, "OK")
            await getData()
            closeFunc(false)
        } catch (e) {
            notifications.addNotification(e.response.data.message)
            console.log(e)
            throw e
        } finally {
            setIsLoading(false)
        }
    }


    return <ModalWindow title={'Подтвердить платеж'}
                        closeFunc={closeFunc}
                        type={'small'}
                        primaryAction={() => confirmRequest()}
                        primaryButtonIsDisabled={isLoading}
    >
        <div className={s.modalWrap}>
            <hp className={f.body1}
                style={{textAlign: "center"}}
            >Вы хотите подтвердить платеж?
            </hp>
            {
                isLoading && <div className={s.loader}><Preloader/></div>
            }
        </div>
    </ModalWindow>
}

const ConfirmPayoutModal = ({closeFunc, recordId, getData}) => {
    const [isLoading, setIsLoading] = useState(false);

    const confirmRequest = async () => {
        try {
            setIsLoading(true)
            const req = await confirmPayoutRequest({
                id: recordId,
            })
            notifications.addNotification(req.message, "OK")
            await getData()
            closeFunc(false)
        } catch (e) {
            notifications.addNotification(e.response.data.message)
            console.log(e)
            throw e
        } finally {
            setIsLoading(false)
        }
    }


    return <ModalWindow title={'Подтвердить выплату'}
                        closeFunc={closeFunc}
                        type={'small'}
                        primaryAction={() => confirmRequest()}
                        primaryButtonIsDisabled={isLoading}
    >
        <div className={s.modalWrap}>
            <hp className={f.body1}
                style={{textAlign: "center"}}
            >Вы хотите подтвердить выплату?
            </hp>
            {
                isLoading && <div className={s.loader}><Preloader/></div>
            }
        </div>
    </ModalWindow>
}

const BankPaymentInfo = ({id, closeFunc}) => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const req = await getPaymentInfoFromBank({id: id})
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            }
        }

        getData()
    }, [id])

    return <ModalWindow title={"Информация о платеже "}
                        closeFunc={closeFunc}>
        <div className={s.modalWrap}>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <ReactJson src={data.state}/>
            }
        </div>
    </ModalWindow>
}

const OrderPaymentInfo = ({id, closeFunc}) => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                const req = await getOrderInfoFromBank({id: id})
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log(e)
                setNotFound(true)
            }
        }
        getData()
    }, [id])


    return <ModalWindow title={"Информация о заказе "}
                        closeFunc={closeFunc}>
        <div className={s.modalWrap}>
            {
                notFound
                    ? <EmptyList title={'Заказ не найден'}/>
                    : isLoading
                        ? <Preloader type={'big'}/>
                        : <ReactJson src={data.state}/>
            }
        </div>
    </ModalWindow>
}

const DropdownListBlock = ({blocks, title}) => {
    const [isOpen, setIsOpen] = useState(false);

    if (blocks.length === 0) return null

    return <div className={s.dropdownWrap}>
        <button
            onClick={() => setIsOpen(prevState => !prevState)}
            className={s.header}>
            <h4 className={f.subtitle1Bold}>{title} <span>({blocks.length})</span></h4>
            <div className={`${s.icon} ${isOpen ? s.open : ''}`}><Arrow/></div>
        </button>
        {
            isOpen && <div className={s.content}>
                {blocks}
            </div>
        }
    </div>
}

//Информация о платеже
const PaymentInfo = ({data}) => {
    const params = [
        {
            label: "№ транзакции (в нашей системе)",
            value: data.invoice.id
        },
        {
            label: "Идентификатор транзакции (в системе банка)",
            value: data.invoice.paymentId
        },
        {
            label: "Идентификатор платежного заказа (в нашей системе)",
            value: data.invoice.orderId,
            title: 'Описание'
        },
        {
            label: "Идентификатор заказа (в нашей системе)",
            value: data.invoice.order.orderId
        },
        {
            label: "Категория платежа",
            value: data.invoice.cTitle
        },
        {
            label: "Идентификатор безопасной сделки",
            value: data.invoice.spAccumulationId
        },
        {
            label: "Дата создания платежа",
            value: timestampToDate(data.invoice.timestamp)
        },
        {
            label: "Статус транзакции (в системе банка)",
            value: data.invoice.status
        },
        {
            label: "Статус платежного заказа",
            value: data.invoice.order.sTitle
        },

        {
            label: "Оригинальная стоимость заказа",
            value: data.invoice.order.originalAmount / 100 + '.00 ₽'
        },
        {
            label: "Сумма платежа",
            value: data.invoice.order.newAmount / 100 + '.00 ₽'
        },
        {
            label: "Использовались бонусы",
            value: data.invoice.order.useBonus ? "Да" : "Нет"
        },
        {
            label: "Количество бонусов",
            value: data.invoice.order.bonusAmount
        },
    ]

    return <SpecList params={params} blockTitle={'Информация о платеже'}/>
}

//Информация о средстве оплаты
const PaymentMethodInfo = ({data}) => {
    const params = [
        {
            label: "Идентификатор автоплатежа",
            value: data.invoice.paymentInformation?.rebildId || "Не указано"
        },
        {
            label: "Идентификатор карты (в системе банка)",
            value: data.invoice.paymentInformation?.cardId
        },
        {
            label: "Идентификатор карты (в нашей системе)",
            value: data.invoice.paymentInformation?.id
        },
        {
            label: "Номер карты",
            value: data.invoice.paymentInformation?.pan
        },
        {
            label: "Срок действия карты",
            value: timestampToDate(data.invoice.paymentInformation?.expDate)
        },
        {
            label: "Код карты держателя",
            value: data.invoice.paymentInformation?.cardCode
        },
        {
            label: "Название держателя карты",
            value: data.invoice.paymentInformation?.cardTitle
        },
        {
            label: "Основной способ оплаты",
            value: data.invoice.paymentInformation?.isMainMethod ? "Да" : "Нет"
        },
        {
            label: "Удален способ оплаты",
            value: data.invoice.paymentInformation?.isLifeStatus ? "Да" : "Нет"
        },
    ]

    if (!data.invoice.paymentInformationId) return null

    return <SpecList params={params} blockTitle={'Информация о способе оплаты'}/>
}

//Информация об ошибке при оплате
const ErrorInfo = ({data}) => {
    const params = [
        {
            label: "Код ошибки (Банк)",
            value: data.invoice.errorCode || "Не указано"
        },
        {
            label: "Короткое описание ошибки",
            value: data.invoice.message || "Не указано",
            type: 'vertical'
        },
        {
            label: "Детальное описание ошибки",
            value: data.invoice.details || "Не указано",
            type: 'vertical'
        },
    ]

    if (data.invoice.errorCode === "0") return null

    return <SpecList params={params} blockTitle={'Ошибки'}/>
}

const CancelList = ({data}) => {
    return <DropdownListBlock title={'Запросы на отмену'} blocks={data.cancelInvoiceList.map(blockData => {
        const params = [
            {
                label: "Дата совершения запроса:",
                value: timestampToDate(blockData.timestamp) || "Не указано"
            },
            {
                label: "Статус",
                value: blockData.status || "Не указано"
            },
            {
                label: "Стоимость до отмены",
                value: blockData.originalAmount / 100 + '.00 ₽' || "Не указано"
            },
            {
                label: "Стоимость после отмены",
                value: blockData.newAmount / 100 + '.00 ₽' || "Не указано"
            },
            {
                label: "Код Ошибки (от Банка)",
                value: blockData.errorCode,
            },
            {
                label: "Короткое описание ошибки",
                value: blockData.message,
                type: 'vertical'
            },
            {
                label: "Детальное описание ошибки",
                value: blockData.details,
                type: 'vertical'
            },
        ]

        return <SpecList blockTitle={`Запрос от ${timestampToDate(blockData.timestamp)}`} params={params}/>
    })}/>
}

const ConfirmList = ({data}) => {
    return <DropdownListBlock title={'Запросы на подтверждение'} blocks={data.confirmInvoiceList.map(blockData => {
        const params = [
            {
                label: "Дата совершения запроса:",
                value: timestampToDate(blockData.timestamp) || "Не указано"
            },
            {
                label: "Статус",
                value: blockData.status || "Не указано"
            },
            {
                label: "Код Ошибки (от Банка)",
                value: blockData.errorCode,
            },
            {
                label: "Короткое описание ошибки",
                value: blockData.message,
                type: 'vertical'
            },
            {
                label: "Детальное описание ошибки",
                value: blockData.details,
                type: 'vertical'
            },
        ]

        return <SpecList blockTitle={`Запрос от ${timestampToDate(blockData.timestamp)}`} params={params}/>
    })}/>
}

const RecurrentList = ({data}) => {
    return <DropdownListBlock title={'Реккурентный платеж'} blocks={data.chargeInvoiceList.map(blockData => {
        const params = [
            {
                label: "Дата совершения запроса:",
                value: timestampToDate(blockData.timestamp) || "Не указано"
            },
            {
                label: "Статус",
                value: blockData.status || "Не указано"
            },
            {
                label: "Код Ошибки (от Банка)",
                value: blockData.errorCode,
            },
            {
                label: "Короткое описание ошибки",
                value: blockData.message,
                type: 'vertical'
            },
            {
                label: "Детальное описание ошибки",
                value: blockData.details,
                type: 'vertical'
            },
        ]

        return <SpecList blockTitle={`Платеж от ${timestampToDate(blockData.timestamp)}`} params={params}/>
    })}/>
}

export default OperationInfo