import s from './OrdersCount.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useState, useEffect, useMemo} from "react";

import {getOrdersCount} from "../../../../../services/StatisticService";

import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ChartBar from "../../../Dashboard/DashboardBlock/ChartBar/ChartBar";
import ExportModal from "../../ExportModal/ExportModal";


const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const statuses = [
    {
        text: 'Заказы в работе',
        code: 'ORDER_IN_PROGRESS'
    },
    {
        text: 'Отменённые заказы',
        code: 'ORDER_CANCELLED'
    },
    {
        text: 'Закрытые заказы',
        code: 'ORDER_CLOSED'
    },
    {
        text: 'Заказы на гарантии',
        code: 'WARRANTY_ORDER'
    },
    {
        text: 'Заказы в доработке',
        code: 'ORDER_SENT_FOR_FIX'
    }
]

const formatData = (data, period) => {
    if (period === 'WEEK') {
        return data.map((item) => {
            return {
                name: new Date(item[0]).toLocaleDateString('ru-RU', {
                    weekday: 'short'
                }).toUpperCase(),
                value: item[1]
            }
        })
    }

    if (period === 'MONTH') {
        return data.map((item) => {
            return {
                name: item[0].slice(3, 5),
                value: item[1]
            }
        })
    }

    if (period === 'YEAR') {
        return data.map((item) => {
            return {
                name: (new Date(item[0]).toLocaleDateString('ru-RU', {
                    month: 'short'
                })).toUpperCase().slice(0, 3),
                value: item[1]
            }
        })
    }
    return null
}

const OrdersCountGraph = () => {
    const [totalCounter, setTotalCounter] = useState(0);
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const [currentStatus, setCurrentStatus] = useState(statuses[0]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const actions = useMemo(() => {
        return statuses.map((status) => {
            return {
                label: status.text,
                func: () => setCurrentStatus(status)
            }
        })
    }, [])

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrdersCount(currentPeriod.code, currentStatus.code)
                setTotalCounter(req.reduce((sum, current) => sum + current[1], 0))
                setData(formatData(req, currentPeriod.code))
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [currentStatus, currentPeriod])


    return <div className={s.wrap}>
        <div className={s.headline}>
            <div className={s.row}>
                <div className={s.line}>
                    <h4 className={`${f.boldH4} ${s.white}`}>Заказы</h4>
                    <ExportModal api={'/order/s1'} title={`Заказы (${currentStatus.text})`}
                                 params={{
                                     orderStatusCode: currentStatus.code
                                 }}/>
                </div>
                <h4 className={`${f.boldH4} ${s.white}`}>{totalCounter}</h4>
            </div>
            <div className={s.row}>
                <div className={s.tabs}>
                    <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
                </div>
                <div className={s.selector}>
                    <p className={f.subtitle1Bold}>{currentStatus.text}</p>
                    <DropdownButton type={'altIcon'} actions={actions}/>
                </div>
            </div>
            <ChartBar height={312} theme={'blue'} data={data} isLoading={isLoading}/>
        </div>
    </div>
}

export default OrdersCountGraph
