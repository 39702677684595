import NavSidebar from "./components/NavSidebar";
import TopNavbar from "./components/TopNavbar";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import {useContext, useEffect} from "react";

import Dashboard from "./pages/main/main";

import './static/global.scss'
import './static/animations.scss'
import Teachers from "./pages/teachers/teachers";
import Students from "./pages/students/students";
import Auth from "./pages/auth/auth";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import Teacher from "./pages/teachers/teacher/teacher";
import Student from "./pages/students/student/student";
import Orders from "./pages/orders/orders";
import Preloader from "./components/_BASE/UI/Preloader/Preloader";
import NewsBlog from "./pages/blog/NewsBlog";
import SchoolCategories from "./pages/categories/SchoolCategories";
import UniversityCategories from "./pages/categories/UniversityCategories";
import BanReasons from "./pages/rules/banReasons/banReasons";
import Settings from "./pages/settings/settings";
import HelpdeskAgents from "./pages/helpdesk/agents";
import Top6Page from "./pages/categories/top6";
import Promo from "./pages/promo/promo";
import SingleNew from "./components/_PAGES/Blog/SingleNew/SingleNew";
import CreateStory from "./components/_PAGES/Blog/CreateStory/CreateStory";
import FaqPage from "./pages/faq/faq";
import MobileFeedbackPage from "./pages/feedback/mobileFeedback";
import SupportCategoriesPage from "./pages/helpdesk/categories/ticketCreate";
import TicketClosePage from "./pages/helpdesk/categories/ticketClose";
import TicketsPage from "./pages/helpdesk/Tickets";
import AgentPage from "./pages/helpdesk/agent/agent";
import BonusRequestPage from "./pages/helpdesk/bonusRequestPage";
import HelpdeskStatsPage from "./pages/statistics/helpdesk";
import FinancesStatsPage from "./pages/statistics/finances";
import OrdersStatsPage from "./pages/statistics/orders";
import UsersStatsPage from "./pages/statistics/users";
import DocsPage from "./pages/docs/docs";
import WebFeedbackPage from "./pages/feedback/WebFeedback";
import OperationsPage from "./pages/payments/operations";
import OperationInfoPage from "./pages/payments/operationInfo/operationInfo";
import OrdersPage from "./pages/orders/singleOrder";


const App = () => {
    const {store} = useContext(Context)

    useEffect(() => {
        if (localStorage.getItem('authTokenAdmin')) {
            store.checkAuth()
        } else {
            store.setIsLoading(false)
        }
    }, [store])

    if (store.isLoading) {
        return <Preloader type={'big'}/>
    }

    return (
        <Router>
            {
                !store.isAuth
                    ? <Routes>
                        <Route
                            path='/auth'
                            element={<Auth/>}
                        />
                        <Route
                            path='*'
                            element={<Navigate to="/auth"/>}
                        />
                    </Routes>
                    : <div className="admin">
                        <ErrorBoundary>
                            <NavSidebar/>
                        </ErrorBoundary>
                        <div className='topNavbarWrapper'>
                            <ErrorBoundary>
                                <TopNavbar/>
                            </ErrorBoundary>
                            <div className='contentContainer'>
                                <ErrorBoundary>
                                    <Routes>
                                        <Route
                                            path='/'
                                            element={<Dashboard/>}
                                        />

                                        <Route
                                            path='/docs'
                                            element={<DocsPage/>}
                                        />
                                        <Route
                                            path='/teachers'
                                            element={<Teachers/>}
                                            children={() => <div>Учителя</div>}
                                        >
                                        </Route>
                                        <Route
                                            path='/teachers/:id/*'
                                            element={<Teacher/>}/>
                                        <Route
                                            path='/students'
                                            element={<Students/>}
                                        />
                                        <Route
                                            path='/students/:id/*'
                                            element={<Student/>}/>
                                        <Route
                                            path='/categories/school'
                                            element={<SchoolCategories/>}
                                        />
                                        <Route
                                            path='/categories/university'
                                            element={<UniversityCategories/>}
                                        />
                                        <Route
                                            path='/orders'
                                            element={<Orders/>}
                                        />
                                        <Route
                                            path='/orders/:id'
                                            element={<OrdersPage/>}
                                        />
                                        <Route
                                            path='/blog/*'
                                            element={<NewsBlog/>}
                                        />
                                        <Route
                                            path={'/blog/create-news'}
                                            element={<SingleNew edit={false} key={"create"}/>}
                                        />
                                        <Route
                                            path={'/blog/:id/edit'}
                                            element={<SingleNew edit key={"create"}/>}
                                        />
                                        <Route
                                            path={'/blog/create-story'}
                                            element={<CreateStory key={"create-story"}/>}
                                        />
                                        <Route
                                            path={'/story/:id/edit'}
                                            element={<CreateStory edit key={"edit-story"}/>}
                                        />
                                        <Route
                                            path={'/faq'}
                                            element={<FaqPage/>}
                                        />
                                        <Route
                                            path={'/feedback/mobile'}
                                            element={<MobileFeedbackPage/>}
                                        />
                                        <Route
                                            path={'/feedback/web'}
                                            element={<WebFeedbackPage/>}
                                        />

                                        <Route
                                            path={`/blog/:id/edit`}
                                            element={<SingleNew edit key={window.location.pathname}/>}/>
                                        <Route
                                            path='/settings'
                                            element={<Settings/>}
                                        />
                                        <Route
                                            path='/rules'
                                            element={<BanReasons/>}
                                        />
                                        <Route
                                            path='/agents'
                                            element={<HelpdeskAgents/>}
                                        />
                                        <Route
                                            path='/agents/:id/*'
                                            element={<AgentPage/>}
                                        />
                                        <Route
                                            path='/top6'
                                            element={<Top6Page/>}
                                        />
                                        <Route
                                            path='/promo'
                                            element={<Promo/>}
                                        />
                                        <Route
                                            path='/helpdesk/categories'
                                            element={<SupportCategoriesPage/>}
                                        />
                                        <Route
                                            path='/helpdesk/close-reasons'
                                            element={<TicketClosePage/>}
                                        />
                                        <Route
                                            path='/helpdesk/tickets'
                                            element={<TicketsPage/>}
                                        />
                                        <Route
                                            path='/requests'
                                            element={<BonusRequestPage/>}
                                        />
                                        <Route
                                            path="/stats/helpdesk"
                                            element={<HelpdeskStatsPage/>}
                                        />
                                        <Route
                                            path="/stats/finances"
                                            element={<FinancesStatsPage/>}
                                        />
                                        <Route
                                            path="/stats/orders"
                                            element={<OrdersStatsPage/>}
                                        />
                                        <Route
                                            path="/stats/users"
                                            element={<UsersStatsPage/>}
                                        />
                                        <Route
                                            path="/payments/operations"
                                            element={<OperationsPage/>}
                                        />
                                        <Route
                                            path="/payments/operations/:id"
                                            element={<OperationInfoPage/>}
                                        />
                                        <Route
                                            path='*'
                                            element={<Navigate to="/"/>}
                                        />
                                    </Routes>
                                </ErrorBoundary>
                            </div>
                        </div>
                    </div>
            }
        </Router>
    );
}

export default observer(App);
