import {useCallback, useEffect, useMemo, useState} from "react";

import s from './UserList.module.scss'
import f from '../../../static/fonts.module.scss'

import {ReactComponent as SearchIcon} from "./icons/Search.svg";
import {ReactComponent as UserIcon} from "./icons/Add User.svg";

import Button from "../../_BASE/UI/Button/Button";
import Input from "../../_BASE/UI/Input/Input";

import TableRow from "./TableRow/TableRow";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import {getSearchUsers, getUsersCount, getUsersList} from "../../../services/AuthService";
import Table from "../../_BASE/Layout/Table/Table";
import CreateNewTeacherModal from "./CreateNewTeacherModal/CreateNewTeacherModal";
import CreateNewHelpdeskAgentModal from "./CreateNewHelpdeskAgentModal/CreateNewHelpdeskAgentModal";
import CreateNewStudent from "./CreateNewStudentModal/CreateNewStudent";


const UserList = ({name, profile_link, roleTitle, status}) => {
    const [searchBarValue, setSearchBarValue] = useState('')

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0)

    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });

    const [createNewUserModalIsVisible, setCreateNewUserModalIsVisible] = useState(false);


    const searchQuery = async (type) => {
        try {
            setIsLoading(true)
            const res = await getSearchUsers(roleTitle, type, searchBarValue)
            setData(res)
        } catch (e){
            setData([])
        } finally {
            setIsLoading(false)
            setIsSearching(true)
        }
    }

    useEffect(() => {
        const getCount = async () => {
            try {
                const res = await getUsersCount(roleTitle, status)
                setTotal(res.userCount)
                setCountIsLoaded(status)
            } catch (e) {
                throw e
            }
        }

        getCount()
    }, [roleTitle, status])

    const getData = useCallback(async () => {
        try {
            if (countIsLoaded) {
                setIsLoading(true)
                const newData = await getUsersList(roleTitle, paginationObject.page, paginationObject.offset, status)
                setData(newData)
                setIsLoading(false)
            }
        } catch (e) {
            throw e
        }
    }, [countIsLoaded, roleTitle, paginationObject.page, paginationObject.offset, status])

    const getModal = (userRole) => {
        switch (userRole) {
            case 'teacher' :
                return <CreateNewTeacherModal closeModalFunc={setCreateNewUserModalIsVisible} getData={getData}/>
            case 'student' :
                return <CreateNewStudent closeModalFunc={setCreateNewUserModalIsVisible} getData={getData}/>
            case 'agent' :
                return <CreateNewHelpdeskAgentModal closeModalFunc={setCreateNewUserModalIsVisible} getData={getData}/>
            default:
                return
        }
    }

    useEffect(() => {
        if (!isSearching) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded, isSearching, getData])


    //Чтобы не ререндерить каждое нажатие клавиши в поиске
    const tableLayout = useMemo(() => {
        return <>
            <Table gridTemplate={"40px 4fr 2fr 5fr 4fr 4fr  92px"}
                   headsArray={[
                       "",
                       `ФИО ${name}`,
                       "ID",
                       "E-mail",
                       "Телефон",
                       "Дата регистрации",
                       "Действие"
                   ]}
                   rowsArray={data.map((rowData) => <TableRow rowData={rowData}
                                                              profile_link={profile_link}
                                                              key={rowData.id}
                                                              getData={getData}
                   />)}
            />
        </>
    }, [data, getData, name, profile_link])


    const search_queries = [
        {
            label: 'ID',
            func: () => searchQuery('id')
        },
        {
            label: 'ФИО',
            func: () => searchQuery('fullName')
        },
        {
            label: 'E-mail',
            func: () => searchQuery('email')
        },
        {
            label: 'Телефон',
            func: () => searchQuery('phone')
        },
    ]


    return <div className={s.wrap}>
        {
            createNewUserModalIsVisible && getModal(roleTitle)
        }
        <div className={s.headLine}>
            <div className={s.searchBar}>
                <ErrorBoundary>
                    <Input icon={<SearchIcon/>}
                           value={searchBarValue}
                           setValue={setSearchBarValue}
                           placeholder={"Введите поисковый запрос..."}
                    />
                    {
                        searchBarValue.length > 0 &&
                        <DropdownButton text={'Поиск по'} actions={search_queries}
                                        nested_styles={{height: 52, marginLeft: 16}}/>
                    }
                    {
                        isSearching && <div className={s.resetSearchbar}>
                            <Button type={'secondary'}
                                    text={'Сбросить'}
                                    click={async () => {
                                        setIsSearching(false)
                                        setSearchBarValue('')
                                    }
                                    }/>
                        </div>
                    }
                </ErrorBoundary>
            </div>
            <Button
                text={`Добавить ${name}`}
                icon={<UserIcon/>}
                click={() => setCreateNewUserModalIsVisible(true)}
            />
        </div>
        {
            isSearching && !isLoading && <p className={`${f.subtitle1Bold} ${s.searchCount}`}>
                Всего результатов: {data.length}
            </p>
        }
        {
            isLoading
                ? <Preloader type={'big'}/>
                : tableLayout
        }
        {
            !isSearching && <div className={s.paginationInfo}>
                <ErrorBoundary>
                    <Pagination
                        paginationObject={paginationObject}
                        setPaginationObject={setPaginationObject}
                        totalCount={total} hide={isLoading}
                    />
                </ErrorBoundary>
            </div>
        }
    </div>
}

export default UserList
