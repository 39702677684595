import {makeAutoObservable} from "mobx";
import {getOptionsList, getSubjectList} from "../services/CompetencyService";

export default class CategoriesStoreMobx {
    data = []
    type = ''
    optionsCode = ''
    pagination
    isLoading = false

    constructor() {
        makeAutoObservable(this)
    }

    setData(data) {
        this.data = data
    }

    setType(type) {
        this.type = type
    }

    setOptionsCode(string) {
        this.optionsCode = string
    }

    setPagination(data) {
        this.pagination = data
    }

    setIsLoading(bool) {
        this.isLoading = bool
    }

    async getSubjects() {
        this.setIsLoading(true)
        try {
            const data = await getSubjectList(this.type)
            console.log(data)
            this.setData(data)
        } catch (e) {
            this.setData([])
            throw e
        } finally {
            this.setIsLoading(false)
        }
    }

    async getOptions() {
        this.setIsLoading(true)
        try {
            const data = await getOptionsList(this.optionsCode)
            this.setData(data)
        } catch (e) {
            this.setData([])
            throw e
        } finally {
            this.setIsLoading(false)
        }
    }
}