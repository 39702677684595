import s from './OrderInfo.module.scss'

import React, {useEffect, useState} from "react";
import {getOrder} from "../../../../services/OrdersService";

import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";
import ChatView from "./ChatView/ChatView";
import UserCard from "../UserCard/UserCard";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import SystemInfo from "./SystemInfo/SystemInfo";
import OrderDetails from "./OrderDetails/OrderDetails";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";


const OrderInfo = ({orderId, vertical}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeInfoTab, setActiveInfoTab] = useState("Информация о заказе");


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrder(orderId)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }
        getData()
    }, [orderId])

    if (isLoading) return <Preloader type={'big'}/>


    const infoTabs = [
        {
            label: 'Информация о заказе',
            content: <ErrorBoundary>
                <OrderDetails data={data} orderId={orderId}/>
            </ErrorBoundary>
        },
        {
            label: 'Системная информация',
            content: <ErrorBoundary>
                <SystemInfo orderId={orderId}
                            orderTitle={data.orderTitle}
                            customerUserId={data?.studentInfo?.userId}
                            executorUserId={data?.teacherInfo?.userId}
                />
            </ErrorBoundary>
        }
    ]


    return <div className={s.wrap} style={vertical ? {flexDirection: 'column', display: "flex"} : null}>
        <div className={s.infoColumn}>
            <ErrorBoundary>
                <div className={s.users}>
                    <UserCard userId={data?.studentInfo?.userId}
                              style={{background: "#E6F1FF"}}/>
                    <UserCard userId={data?.teacherInfo?.userId}/>
                </div>
            </ErrorBoundary>
            <Tabs tabs={infoTabs} activeTab={activeInfoTab} setActiveTab={setActiveInfoTab}/>
        </div>
        <ErrorBoundary>
            <ChatView orderId={orderId} rightUserId={data?.teacherInfo?.userId}/>
        </ErrorBoundary>
    </div>
}


export default OrderInfo
