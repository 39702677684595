import {makeAutoObservable} from "mobx";
import {
    checkTeacherCompetencyStatus,
    getUserCompetenciesProfile, registerNewCompetencyProfile,
    updateTeacherCompetencyStatus
} from "../services/CompetencyService";
import {getUserInfo, updateUserAuthProfile} from "../services/AuthService";
import {notifications} from "../index";
import {getUserOrders, getUserOrdersCount} from "../services/OrdersService";


export default class UserProfileStore {
    competencyProfile = {}
    authProfile = {}
    userId
    teacherStatus = null
    isLoaded = true
    statusIsChecking = false

    constructor() {
        makeAutoObservable(this)
    }

    setCompetencyProfile(data) {
        this.competencyProfile = data
    }

    setUserId(id) {
        this.userId = id
    }

    setAuthProfile(data) {
        this.authProfile = data
    }

    setTeacherStatus(bool) {
        this.teacherStatus = bool
    }

    setIsLoaded(bool) {
        this.isLoaded = bool
    }

    setStatusIsChecking(bool) {
        this.statusIsChecking = bool
    }

    resetValues() {
        this.competencyProfile = {}
        this.authProfile = {}
        this.userId = null
        this.teacherStatus = null
    }

    async getUserCompetencyProfile() {
        if (this.authProfile.userRoleTitleCode === 'TEACHER' || this.authProfile.userRoleTitleCode === 'STUDENT') {
            try {
                this.setStatusIsChecking(true)
                const profile = await getUserCompetenciesProfile(this.userId, this.authProfile.userRoleTitleCode)
                this.setCompetencyProfile(profile.data)
                if (this.authProfile.userRoleTitleCode === 'TEACHER') {
                    await this.checkCompetencyTeacherStatus()
                }
            } catch (e) {
                if (e.response?.data?.errors?.userNotFound) {
                    this.setCompetencyProfile(false)
                } else {
                    throw e
                }
            } finally {
                this.setStatusIsChecking(false)
            }
        }
    }

    async getUserAuthProfile() {
        this.setIsLoaded(false)
        const profile = await getUserInfo(this.userId)
        this.setAuthProfile(profile.data.user)
        this.setIsLoaded(true)
    }

    async checkCompetencyTeacherStatus() {
        this.setStatusIsChecking(true)
        const check = await checkTeacherCompetencyStatus(this.competencyProfile.profileId)
        this.setTeacherStatus(check)
        this.setStatusIsChecking(false)
    }

    async updateCompetencyTeacherStatus() {
        this.setStatusIsChecking(true)
        await updateTeacherCompetencyStatus(
            this.competencyProfile.profileId,
            !this.teacherStatus)
        await this.checkCompetencyTeacherStatus()
    }

    async updateUserAuthProfile(type, data, userId) {
        this.setIsLoaded(false)
        await updateUserAuthProfile(type, data, userId)
        await this.getUserAuthProfile()
        this.setIsLoaded(true)
    }

    async registerCompetencyProfile(type) {
        try {
            await registerNewCompetencyProfile({
                institution: type,
                userId: this.authProfile.userId,
                name: this.authProfile.name,
                surname: this.authProfile.surname,
                middlename: this.authProfile.middlename,
                userRoleTitleCode: this.authProfile.userRoleTitleCode,
                dob: this.authProfile.dob
            })
            notifications.addNotification('Профиль компетенций успешно создан', 'OK')
        } catch (e) {
            throw e
        }
    }

    async getUserOrders(page, offset, status) {
        return await getUserOrders(
            this.competencyProfile.profileId,
            status,
            this.authProfile.userRoleTitleCode,
            page,
            offset
        )

    }

    async getTotalUserOrders(status) {
        return await getUserOrdersCount(
            this.competencyProfile.profileId,
            status,
            this.authProfile.userRoleTitleCode
        )
    }

    async getAllData() {
        try {
            await this.setIsLoaded(false)
            await this.getUserAuthProfile()
            await this.getUserCompetencyProfile()
            await this.setIsLoaded(true)
        } catch (e){
            console.log(e)
        }
    }

}