import m from "../../../static/misc.module.scss";
import {ReactComponent as BackIcon} from "../../../static/icons/back.svg";
import {useNavigate} from "react-router";


const TitleWithBack = ({title, link}) => {
    const navigation = useNavigate()

    return <div className={m.navHeader}>
        {
            (window.history.length > 2 || link) &&
            <div
                className={m.backButton}
                onClick={() => link ? navigation(link) : navigation(-1)}
            >
                <BackIcon/>
            </div>
        }
        {title}
    </div>
}

export default TitleWithBack