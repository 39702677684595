import s from './CreateModal.module.scss'
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import {useEffect, useState} from "react";
import Radios from "../../../_BASE/UI/Radios/Radios";
import {createPromo, updatePromo} from "../../../../services/PaymentService";
import {notifications} from "../../../../index";


const CreateModal = ({closeFunc, data, updateData}) => {
    const [secretWord, setSecretWord] = useState('');
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [hasLifetime, setHasLifetime] = useState(false);
    const [lifetime, setLifetime] = useState("");
    const [hasLimit, setHasLimit] = useState(false);
    const [limit, setLimit] = useState("");
    const [reward, setReward] = useState("");

    useEffect(() => {
        if (data) {
            setSecretWord(data.secretWord)
            setName(data.title)
            setComment(data.comment)
            setReward(data.reward)
            if (data?.numberOfActivations) {
                setHasLimit(true)
                setLimit(data.numberOfActivations)
            }
            if (data?.endDate) {
                const date = new Date(Number(data.endDate)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                const [day, month, year] = date.split('.')

                setHasLifetime(true)
                setLifetime(`${year}-${month}-${day}`)
            }
        }
    }, [data])

    const checkValues = () => {
        if (secretWord?.length < 2) {
            notifications.addNotification('Длина кодового слова должна превышать 4 символа')
            return false
        }
        if (name?.length < 2) {
            notifications.addNotification('Длина названия должна превышать 4 символа')
            return false
        }
        if (comment?.length < 2) {
            notifications.addNotification('Длина комментария должна превышать 4 символа')
            return false
        }
        if (hasLifetime) {
            if (lifetime?.length < 1) {
                notifications.addNotification('Не выбрана дата')
                return false
            }

            if (new Date(lifetime) < new Date()) {
                notifications.addNotification('Введена некорректная дата')
                return false
            }

        }


        if (hasLimit) {
            if (limit?.length < 1) {
                notifications.addNotification('Не установлен лимит активаций')
                return false
            }
        }
        return true
    }

    const createNewPromo = async () => {
        if (!checkValues()) return
        try {
            await createPromo({
                title: name,
                comment: comment,
                secretWord: secretWord,
                endDate: hasLifetime ? lifetime : "NULL",
                numberOfActivations: hasLimit ? limit : "NULL",
                reward: reward
            })
            await updateData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    const editPromo = async () => {
        if (!checkValues()) return
        try {
            await updatePromo(data.id, {
                title: name,
                comment: comment,
                endDate: hasLifetime ? lifetime : "NULL",
                numberOfActivations: hasLimit ? limit : "NULL",
                reward: reward
            })
            await updateData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        closeFunc={closeFunc}
        title={data ? "Изменение промокода" : 'Создание нового промокода'}
        type={'small'}
        primaryAction={() => data ? editPromo() : createNewPromo()}
    >
        <div className={s.wrap}>
            <Input
                title={'Укажите секретное слово промокода'}
                placeholder={'BASE123'}
                value={secretWord}
                setValue={setSecretWord}
                styles={{maxWidth: '100%'}}
            />
            <Input
                title={'Укажите название'}
                placeholder={'Рекламная компания №12'}
                value={name}
                setValue={setName}
                styles={{maxWidth: '100%'}}
            />
            <Input
                title={'Укажите комментарий'}
                placeholder={'Какой-то текст'}
                value={comment}
                setValue={setComment}
                type={'textarea'}
                styles={{maxWidth: '100%'}}
                minHeight={100}
            />
            <Input
                title={'Номинал'}
                placeholder={'200'}
                type={'number'}
                value={reward}
                setValue={setReward}
                styles={{maxWidth: '100%'}}
            />
            <Radios
                checkedValue={hasLifetime}
                setCheckedValue={setHasLifetime}
                groupName={'hasLifeTime'}
                size={'small'}
                data={[
                    {
                        value: false,
                        title: 'Бессрочный'
                    },
                    {
                        value: true,
                        title: 'С датой'
                    },
                ]}
            />
            {
                hasLifetime && <Input
                    placeholder={'12.04.1200'}
                    min={new Date().toISOString().split('T')[0]}
                    value={lifetime}
                    setValue={setLifetime}
                    styles={{maxWidth: '100%'}}
                    type={'date'}
                    max={'2100-01-01'}
                />
            }
            <Radios
                checkedValue={hasLimit}
                setCheckedValue={setHasLimit}
                groupName={'hasLimit'}
                size={'small'}
                data={[
                    {
                        value: false,
                        title: 'Без ограничений'
                    },
                    {
                        value: true,
                        title: 'С ограничениями'
                    },
                ]}
            />
            {
                hasLimit && <Input
                    placeholder={'1000'}
                    value={limit}
                    setValue={setLimit}
                    styles={{maxWidth: '100%'}}
                    type={'number'}
                />
            }

        </div>
    </ModalWindow>
}

export default CreateModal