import s from './main.module.scss'
import f from '../../static/fonts.module.scss'
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Stats from "../../components/_PAGES/Dashboard/Stats/Stats";
import SupportTable from "../../components/_PAGES/Dashboard/SupportTable/SupportTable";
import StatCards from "../../components/_PAGES/Dashboard/StatCards/StatCards";
import SupportChart from "../../components/_PAGES/Dashboard/SupportChart/SupportChart";
import {useEffect} from "react";


const Dashboard = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Главная')
    }, [])


    return <div className={s.wrap}>
        <h4 className={f.boldH4}>Статистика</h4>
        <StatCards/>
        <Stats/>
        <h4 className={f.boldH4}>Список обращений</h4>
        <div className={s.support}>
            <SupportTable/>
            <SupportChart/>
        </div>
    </div>
}

export default Dashboard

