import fonts from "../../../../static/fonts.module.scss";
import style from "./TableRow.module.scss";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {ReactComponent as EditIcon} from "../editIcon.svg";
import {useState} from "react";
import OrderInfo from "../../OrderInfo/OrderInfo/OrderInfo";
import {timestampToDate} from "../../../../static/utilities";

export const statusTag = (statusLabel, maxLength) => {
    const getColor = () => {
        if (
            statusLabel === 'Заказ создан'
            || statusLabel === 'Поиск исполнителя'
            || statusLabel === 'Заказ на гарантии'
            || statusLabel === 'Заказ отправлен на исправление'
        ) return style.yellow
        if (
            statusLabel === 'Заказ выполняется'
            || statusLabel === 'Заказ оплачен'
            || statusLabel === 'Ожидает подтверждения заказчика'

        ) return style.blue
        if (
            statusLabel === 'Заказ отменен'
            || statusLabel === 'Исполнитель не найден'
            || statusLabel === 'Заказ не оплачен'
            || statusLabel === 'Заказ закрыт'
        ) return style.red
    }

    return <p title={statusLabel} className={`${style.status} ${fonts.subtitle2Bold} ${getColor()}`}>
        {
            maxLength
                ? `${statusLabel?.slice(0, maxLength)}...`
                : statusLabel
        }
    </p>
}

export const categoryTag = (text, type) => {
    return <div className={style.tagWrap}>
        <p title={text} className={`${type === 'big' ? fonts.subtitle1Bold : fonts.body2} ${style.tag}`}>
            {
                text.length > 16
                    ? text.slice(0, 16) + '...'
                    : text
            }
        </p>
    </div>
}

const TableRow = ({rowData}) => {
    const [orderModalVisible, setOrderModalVisible] = useState(false);

    const actions = [{
        label: 'Просмотр',
        type: 'link',
        link: `${rowData.orderId}`,
    }]

    return <>
        <p className={`${fonts.subtitle1Bold} ${style.infoWrap}`}>{rowData.orderId}</p>
        <p className={`${fonts.body2} ${style.infoWrap}`}>{rowData.orderTitle}</p>
        <div className={style.tagContainer}>
            {categoryTag(rowData.subjectTitle)}
            {categoryTag(rowData.optionTitle)}
        </div>
        <p className={`${fonts.body2} ${style.infoWrap}`}>{rowData.price} ₽</p>
        <div>
            {statusTag(rowData.statusTitle, 12)}
        </div>
        <p className={`${fonts.body2} ${style.infoWrap}`}>{timestampToDate(rowData.createDate)}</p>
        <p className={`${fonts.body2} ${style.infoWrap}`}>{timestampToDate(rowData.changeOrderStatusDate)}</p>
        <div
            className={`${fonts.subtitle1Bold} ${style.infoWrap}`}
            style={{justifyContent: 'center'}}
        >
            <DropdownButton type={'icon'} text={<EditIcon/>} actions={actions}
                            nested_styles={{background: "white"}}/>
        </div>
        {
            orderModalVisible && <OrderInfo closeFunc={setOrderModalVisible} orderId={rowData.orderId}/>
        }
    </>
}

export default TableRow
