import s from './SubjectModal.module.scss'
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";

import {useEffect, useState} from "react";
import {uploadImage} from "../../../../../services/FileService";
import {createNewSubject, updateSubjectInfo} from "../../../../../services/CompetencyService";
import {notifications} from "../../../../../index";
import {observer} from "mobx-react-lite";
import {CategoriesStore} from "../../CategoriesPage";
import FileInput from "../../../../_BASE/UI/Input/FileInput/FileInput";

const SubjectModal = ({data = null, closeFunc}) => {
    const [subjectName, setSubjectName] = useState('')

    const [subjectIcon, setSubjectIcon] = useState()
    const [subjectIconUrl, setSubjectIconUrl] = useState('')
    const [subjectIconId, setSubjectIconId] = useState('')

    const Store = CategoriesStore

    useEffect(() => {
        if (data) {
            setSubjectName(data.title)
            setSubjectIconUrl(data.iconLink)
            setSubjectIconId(data.iconId)
        }
    }, [data])

    const handleCreateClick = async () => {
        //Редактируем
        if (data) {
            if (
                data.title !== subjectName
                || data.iconLink !== subjectIconUrl
            ) {
                let iconId = subjectIconId
                let iconLink = subjectIconUrl
                if (data.iconLink !== subjectIconUrl) {
                    const icon = await uploadImage(subjectIcon)
                    iconId = icon.fileId
                    iconLink = icon.fileLink
                }
                const res = await updateSubjectInfo(
                    data.id,
                    iconId,
                    iconLink,
                    subjectName
                )
                if (res.status === 200) {
                    notifications.addNotification('Информация успешно обновлена', 'OK')
                }
            }
            await Store.getSubjects()
            closeFunc(false)

        }
        //Создаем
        else {
            const icon = await uploadImage(subjectIcon)
            const regRes = await createNewSubject(
                Store.type,
                icon.fileId,
                icon.fileLink,
                subjectName,
                1
            )
            if (regRes.status === 200) {
                notifications.addNotification('Предмет успешно создан', 'OK')
                await Store.getSubjects()
                closeFunc(false)
            }
        }
    }


    return <ModalWindow
        title={data ? 'Редактирование предмета' : 'Создание нового предмета'}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={data ? 'Сохранить' : 'Создать'}
        primaryAction={handleCreateClick}
        primaryButtonIsDisabled={!(
            subjectName.length > 2
            && subjectIconUrl
        )}
    >
        <div className={s.wrap}>
            <Input title={'Укажите название'}
                   value={subjectName} setValue={setSubjectName}
                   styles={{maxWidth: '523px'}}
            />
            <FileInput setImageObject={setSubjectIcon}
                       setImageUrl={setSubjectIconUrl}
                       imageObject={subjectIcon}
                       imageUrl={subjectIconUrl}
            />
        </div>
    </ModalWindow>
}

export default observer(SubjectModal)