import s from './PopularReferals.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useEffect, useState} from "react";
import {getReferalStats} from "../../../../../services/StatisticService";
import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../../_BASE/Layout/Table/Table";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";

const periods = [
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
    {
        text: "За все время",
        code: "ALL_TIME"
    },
]

const SingleTableRow = ({rowData}) => {
    const actions = [{
        label: "Открыть профиль",
        func: () => window.open(`/${rowData.user.titleCode === 'STUDENT'
            ? 'students' : 'teachers'
        }/${rowData.userId}`)
    }]

    return <>
        <img className={s.profileAvatar} src={rowData.user.avatarLink} alt=""/>
        <p className={f.subtitle1Bold}>{rowData.user.surname} {rowData.user.name} {rowData.user.middlename}</p>
        <p className={f.subtitle1Bold}>{rowData.user.title}</p>
        <p className={f.subtitle1Bold}>{rowData.number}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
    </>
}

const SingleTable = ({period}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getReferalStats(period)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period])


    if (isLoading) return <Preloader type={'big'}/>
    return <Table rowsArray={data.map((item) => <SingleTableRow rowData={item} key={item.promocodeId}/>)}
                  gridTemplate={'40px 2fr 1fr 1fr 92px'}
                  headsArray={['', 'Имя пользователя', 'Роль', 'Приглашено пользователей', 'ДЕЙСТВИЕ']}/>

}

const PopularReferals = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    return <div className={s.wrap}>
        <div className={s.headline}>
            <h4 className={f.boldH4}>Самые активные участники реферальной программы</h4>
            <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
        </div>
        <SingleTable period={currentPeriod.code}/>
    </div>
}

export default PopularReferals