import NavSingleLink from "./navSingleLink";
import style from './NavBlock.module.scss'
import fonts from './../../../static/fonts.module.scss'
import {useEffect, useState} from "react";

function NavBlock(props) {
    const links = props.links.map(({link, IconSVG, title, id},) =>
        <NavSingleLink link={link} IconSVG={IconSVG} title={title} key={id} id={id} menuIsHidden={props.menuIsHidden}/>
    )

    const [blockTitleRendered, setBlockTitleRendered] = useState(
        <h3 className={`${fonts.overline} ${style.blockHeader} fadeIn`}>{props.blockHeader}</h3>
    );

    useEffect(() => {
        if (!props.menuIsHidden) {
            setTimeout(() => {
                setBlockTitleRendered(
                    <h3 className={`${fonts.overline} ${style.blockHeader} fadeIn`}>{props.blockHeader}</h3>
                )
            }, 300)
        } else {
            setBlockTitleRendered("")
        }
    }, [props.menuIsHidden, props.blockHeader])

    return (
        <div className={style.wrap}>
            {
                props.blockHeader &&
                blockTitleRendered
            }
            <div className={style.links}>
                {links}
            </div>
        </div>
    )
}

export default NavBlock
