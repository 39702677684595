import s from './SingleCard.module.scss'

import Card from "../../Card/Card";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import {useEffect, useState} from "react";


const SingleCard = ({title, icon, theme, getDataApi, periods, style}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const [counter, setCounter] = useState();

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getDataApi(currentPeriod.code)
                setCounter(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [currentPeriod, getDataApi])

    const actions = periods.map((period) => {
        return {
            label: period.text,
            func: () => setCurrentPeriod(period)
        }
    })

    if (isLoading) return <div className={s.emptyList}>
        <Preloader/>
    </div>

    return <Card title={title} counter={counter}
                 tag={currentPeriod.text} icon={icon}
                 theme={theme} actions={actions?.length > 1 ? actions : null}
                 style={style}
    />
}

export default SingleCard