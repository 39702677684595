import {useCallback, useEffect, useState} from "react";

import style from './OrderList.module.scss'
import fonts from '../../../static/fonts.module.scss'

import {ReactComponent as SearchIcon} from "./icons/Search.svg";

import Input from "../../_BASE/UI/Input/Input";
import TableRow from "./TableRow/TableRow";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";
import Table from "../../_BASE/Layout/Table/Table";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import Button from "../../_BASE/UI/Button/Button";

import {getOrdersStatuses, getTotalOrders, getTotalOrdersCount, searchOrder} from "../../../services/OrdersService";

const OrderList = () => {

    const [searchBarValue, setSearchBarValue] = useState('')
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);
    const [currentStatus, setCurrentStatus] = useState('ALL_ORDER');
    const [currentStatusLabel, setCurrentStatusLabel] = useState('Все заказы');
    const [statuses, setStatuses] = useState([]);
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);
    const [isSearching, setIsSearching] = useState(false);


    const getData = useCallback(async () => {
        setIsLoading(true)
        const req = await getTotalOrders(currentStatus, paginationObject.page, paginationObject.offset)
        setData(req.data)
        setIsLoading(false)
    }, [currentStatus, paginationObject.page, paginationObject.offset])

    const getCount = useCallback(async () => {
        setCountIsLoaded(false)
        const req = await getTotalOrdersCount(currentStatus, paginationObject.offset)
        setTotal(req)
        setCountIsLoaded(true)
    }, [currentStatus, paginationObject.offset])

    const getAllStatuses = async () => {
        try {
            const req = await getOrdersStatuses()
            req.data.push({id: 999, title: "Все заказы", code: "ALL_ORDER"})
            const statusActions = req.data.map(({title, code}) => {
                return {
                    label: title,
                    func: () => {
                        setCurrentStatus(code)
                        setCountIsLoaded(false)
                        setCurrentStatusLabel(title)
                        setPaginationObject({
                            page: 1,
                            offset: 20
                        })
                    }
                }
            })
            setStatuses(statusActions)
        } catch (e) {
            throw e
        }
    }

    const getSearchedData = async (type, value) => {
        try {
            setIsLoading(true)
            const res = await searchOrder(type, value)
            setData([res])

        } catch (e) {
            setData([])
            throw e
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getAllStatuses()
    }, [])

    useEffect(() => {
        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded, getData])

    useEffect(() => {
        getCount()
    }, [getCount])

    const search_queries = [
        {
            label: 'ID Заказа',
            func: () => {
                setIsSearching(true)
                getSearchedData('ID', searchBarValue)
            }
        },
    ]


    return (
        <div className={style.wrap}>
            <div className={style.headLine}>
                <div className={style.searchBar}>
                    <ErrorBoundary>
                        <Input icon={<SearchIcon/>}
                               value={searchBarValue}
                               setValue={setSearchBarValue}
                               placeholder={"Введите поисковый запрос..."}
                        />
                        {
                            searchBarValue.length > 0 &&
                            <DropdownButton text={'Поиск по'} actions={search_queries}
                                            nested_styles={{height: 52}}/>
                        }
                        {
                            isSearching &&
                            <Button text={'Сбросить'} type={'alert'} click={() => {
                                setIsSearching(false)
                                setSearchBarValue('')
                                getData()
                            }
                            } nestedStyles={{height: 52}}/>
                        }
                    </ErrorBoundary>
                </div>
                {
                    !isSearching &&
                    <div className={style.statusSelector}>
                        <p className={fonts.subtitle1Bold}>Выбор статуса заказа:</p>
                        <DropdownButton actions={statuses} text={currentStatusLabel} type={'secondary'}/>
                    </div>
                }
            </div>

            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <Table
                        headsArray={["ID", "ЗАГОЛОВОК", "КАТЕГОРИЯ", "СТОИМОСТЬ", "СТАТУС", "ДАТА СОЗДАНИЯ", "ДАТА ИЗМЕНЕНИЯ", "ДЕЙСТВИЕ",]}
                        gridTemplate={"100px 5fr 5fr 3fr 3fr 3fr 3fr 92px"}
                        rowsArray={data.map((item) =>
                            <TableRow
                                key={item.orderId}
                                rowData={item}
                            />)}
                    />
            }
            <div className={style.paginationInfo}>
                <ErrorBoundary>
                    {
                        !isSearching &&
                        <Pagination
                            paginationObject={paginationObject}
                            setPaginationObject={setPaginationObject}
                            totalCount={total} hide={isLoading}
                        />
                    }
                </ErrorBoundary>
            </div>
        </div>
    )
}

export default OrderList
