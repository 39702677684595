import React from "react";
import UserInfo from "../../../components/_PAGES/UserInfo/UserInfo";
import OrdersTable from "../../../components/_PAGES/UserInfo/OrdersTable/OrdersTable";
import LogsTable from "../../../components/_PAGES/UserInfo/LogsTable/LogsTable";
import SkillsTable from "../../../components/_PAGES/UserInfo/SkillsTable/SkillsTable";
import RefTable from "../../../components/_PAGES/UserInfo/RefTable/RefTable";
import BonusHistoryTable from "../../../components/_PAGES/UserInfo/BonusHistoryTable/BonusHistoryTable";
import ReviewsTable from "../../../components/_PAGES/UserInfo/ReviewsTable/ReviewsTable";
import PaymentsTable from "../../../components/_PAGES/UserInfo/PaymentsTable/PaymentsTable";

const Teacher = () => {
    const tabs = [
        {
            label: "Заказы",
            content: <OrdersTable/>,
            link: 'orders'
        },
        {
            label: "События и логи",
            content: <LogsTable/>,
            link: 'logs'
        },
        {
            label: 'Платежи',
            content: <PaymentsTable/>,
            link: 'payments'
        },
        {
            label: "Компетенции",
            content: <SkillsTable/>,
            link: 'skills'
        },
        {
            label: 'Отзывы',
            content: <ReviewsTable/>,
            link:'reviews'
        },
        {
            label: "Реферальная программа",
            content: <RefTable/>,
            link: 'ref'
        },
        {
            label: 'Бонусы',
            content: <BonusHistoryTable/>,
            link: 'bonuses'
        },
    ]

    return (
        <UserInfo
            tabs={tabs}
            type={'teacher'}
        />
    )
}

export default Teacher