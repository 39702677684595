import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Operations from "../../components/_PAGES/Payments/Operations/Operations";


const OperationsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Банковские операции')
    }, [])

    return <Operations/>
}

export default OperationsPage