import {IUser} from "../models/IUser";
import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import {notifications} from "../index";
import {AUTH_API_URL} from "../http";

const md5 = require('md5')


export default class Store {
    user = {} as IUser;
    isAuth = false;
    isLoading = true

    constructor() {
        makeAutoObservable(this)
    }

    setAuth(bool: boolean) {
        this.isAuth = bool;
    }

    setIsLoading(bool: boolean) {
        this.isLoading = bool;
    }

    setUser(user: IUser) {
        this.user = user
    }

    async login(email: string, password: string) {
        try {
            const response = await AuthService.login(email, md5(password))
            if (response.data.user.userRoleTitleCode === 'ADMIN') {
                await localStorage.setItem('authTokenAdmin', response.data.accessToken)
                await this.setAuth(true)
                this.setUser(response.data.user)
            } else {
                notifications.addNotification("Доступ запрещен")
            }
        } catch (e: any) {
            try {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            } catch (er: any) {
                console.log(er)
            }
        }
    }


    async logout() {
        try {
            localStorage.removeItem('authTokenAdmin')
            AuthService.logout().catch(console.error)
            this.setAuth(false)
            this.setUser({} as IUser)
        } catch (e: any) {
            try {
                notifications.addNotification(e.response.data.message)
            } catch (er: any) {
                console.log(er)
            }
        }
    }

    async checkAuth() {
        this.setIsLoading(true)
        try {
            const response = await axios.get<AuthResponse>(AUTH_API_URL + '/v1/account/refresh', {withCredentials: true})
            if (response.data.user.userRoleTitleCode !== "ADMIN") {
                this.setAuth(false)
                localStorage.removeItem('authTokenAdmin')
            } else {
                localStorage.setItem('authTokenAdmin', response.data.accessToken)
                this.setUser(response.data.user)
                this.setAuth(true)
            }
        } catch (e: any) {
            this.setAuth(false)
            try {
                notifications.addNotification(e.response.data.message)
                console.log(e)
            } catch (er: any) {
                console.log(er)
                notifications.addNotification('Ошибка при соединении с сервером')
            }
        } finally {
            this.setIsLoading(false)
        }
    }
}
