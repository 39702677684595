import s from './Cards.module.scss'

import SingleCard from "../../../Dashboard/StatCards/SingleCard/SingleCard";

import {ReactComponent as Icon1} from "./Activity.svg";
import {ReactComponent as Icon2} from "./Shield.svg";
import {ReactComponent as Icon3} from "./Edit.svg";
import {ReactComponent as Icon4} from "./Graph.svg";
import {getOrdersCount} from "../../../../../services/StatisticService";

const getData = async (status, period = 'ALL_TIME') => {
    try {
        return await getOrdersCount(period, status)
    } catch (e) {
        throw e
    }
}

const Cards = () => {


    return <div className={s.wrap}>
        <SingleCard title={'Выполняются'} periods={[{text: 'Сейчас', code: 'ALL_TIME'}]}
                    theme={'blue'} icon={<Icon1/>}
                    getDataApi={() => getData('ORDER_IN_PROGRESS')}
        />
        <SingleCard title={'На гарантии'} periods={[{text: 'Сейчас', code: 'ALL_TIME'}]}
                    icon={<Icon2/>}
                    getDataApi={() => getData('WARRANTY_ORDER')}
        />
        <SingleCard title={'На корректировке'} periods={[{text: 'Сейчас', code: 'ALL_TIME'}]}
                    theme={'blue'} icon={<Icon3/>}
                    getDataApi={() => getData('ORDER_SENT_FOR_FIX')}
        />
        <SingleCard title={'Закрыто'} periods={[
            {text: 'За сегодня', code: 'DAY'},
            {text: 'За все время', code: 'ALL_TIME'}
        ]}
                    icon={<Icon4/>}
                    getDataApi={(period) => getData('ORDER_CLOSED', period)}
        />
    </div>
}

export default Cards