import s from './CloseTicket.module.scss'
import f from '../../../../static/fonts.module.scss'
import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";
import {useState, useEffect} from "react";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Table from "../../../_BASE/Layout/Table/Table";
import {
    createTicketCloseReasons,
    editTicketCloseReasons,
    getTicketCloseReasons,
    archiveTicketCloseReasons
} from "../../../../services/SupportService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../_BASE/UI/Input/Input";
import {notifications} from "../../../../index";


const getStatusTag = (status) => {
    if (status) {
        return <p className={`${f.caption} ${s.yellow} ${s.tag}`}>Активна</p>
    } else {
        return <p className={`${f.caption} ${s.red} ${s.tag}`}>Неактивна</p>
    }
}


const CreateModal = ({data, closeFunc, afterpath}) => {
    const [reasonText, setReasonText] = useState('');

    useEffect(() => {
        if (data) {
            setReasonText(data.title)
        }
    }, [data])

    const clickCreate = async () => {
        if (reasonText?.length === 0) {
            notifications.addNotification("Укажите текст причины")
            return
        }

        try {
            if (data) {
                await editTicketCloseReasons(data.id, {
                    title: reasonText.trim()
                })
            } else {
                await createTicketCloseReasons({
                    title: reasonText.trim()
                })
            }
            closeFunc(false)
            afterpath()

        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        title={data
            ? "Изменение причины"
            : "Создание новой причины"
        }
        type={"small"}
        closeFunc={closeFunc}
        primaryAction={clickCreate}
        primaryButtonIsDisabled={!reasonText.trim().length > 0}
    >
        <div className={s.modalWrap}>
            <p className={f.body1}>
                Укажите название новой причины закрытия обращения пользователя в техническую поддержку.
            </p>
            <Input value={reasonText} setValue={setReasonText}
                   styles={{maxWidth: "100%"}}/>
        </div>
    </ModalWindow>
}

const TableRow = ({rowData, activeTab, getData}) => {
    const [editModalIsVisible, setEditModalIsVisible] = useState(false);
    const [archiveModalIsVisible, setArchiveModalIsVisible] = useState(false);

    const rowActions = [
        {
            label: 'Изменить',
            func: () => setEditModalIsVisible(true)
        },
        {
            label: "Архивировать",
            func: () => setArchiveModalIsVisible(true)
        }
    ]

    const rowActionsArchive = [
        {
            label: "Разархивировать",
            func: () => setArchiveModalIsVisible(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.id}</p>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        {
            getStatusTag(rowData.status)
        }
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={activeTab === 'Активные' ? rowActions : rowActionsArchive}/>
        </div>
        {
            editModalIsVisible && <CreateModal data={rowData}
                                               closeFunc={setEditModalIsVisible}
                                               afterpath={() => getData()}
            />
        }
        {archiveModalIsVisible
            && <ModalWindow
                title={activeTab === 'Активные'
                    ? "Архивирование причины"
                    : "Разархивирование причины"
                }
                closeFunc={setArchiveModalIsVisible}
                type={'small'}
                primaryAction={async () => {
                    await archiveTicketCloseReasons(rowData.id)
                    await getData()
                    setArchiveModalIsVisible(false)
                }

                }

            >
                <p style={{width: 524}} className={f.body1}>Вы хотите изменить статус причины закрытия обращения
                    пользователя в
                    техническую поддержку. Вы уверены? </p>

            </ModalWindow>
        }
    </>
}


const List = ({status, activeTab, data, getData, isLoading}) => {

    const tableHeads = [
        "ID",
        "НАЗВАНИЕ",
        "АКТИВНОСТЬ",
        "ДЕЙСТВИЕ",
    ]

    if (isLoading) return <Preloader type={'big'}/>

    return <Table gridTemplate={'100px 1fr 2fr 92px'}
                  headsArray={tableHeads}
                  rowsArray={data.map((row) => <TableRow rowData={row} activeTab={activeTab}
                                                         getData={() => getData(status)}/>)}
    />
}

const SideButton = ({setCreateModalIsVisible}) => {
    const actions = [
        {
            label: 'Создать',
            func: () => setCreateModalIsVisible(true)

        }
    ]
    return <>
        <DropdownButton text={'Действия'} actions={actions}/>
    </>
}


const CloseTicket = () => {
    const [activeTab, setActiveTab] = useState('Активные');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [createModalIsVisible, setCreateModalIsVisible] = useState(false);


    const getData = async (status) => {
        try {
            const res = await getTicketCloseReasons(status)
            setData(res)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        getData(activeTab === 'Активные' ? 'ON' : 'OFF')
    }, [activeTab])

    const tabs = [
        {
            label: 'Активные',
            content: <List status={'ON'} key={'active-reasons'}
                           activeTab={activeTab} data={data} setData={setData}
                           isLoading={isLoading} getData={getData}
            />
        },
        {
            label: 'Неактивные',
            content: <List status={'OFF'} key={'archive-reasons'}
                           activeTab={activeTab} data={data} setData={setData}
                           isLoading={isLoading} getData={getData}
            />
        }
    ]


    return <>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab}
              tabs={tabs} sideButtons={<SideButton setCreateModalIsVisible={setCreateModalIsVisible}/>}
        />
        {
            createModalIsVisible && <CreateModal closeFunc={setCreateModalIsVisible}
                                                 afterpath={() => getData(activeTab === 'Активные' ? 'ON' : 'OFF')}/>
        }
    </>

}
export default CloseTicket
