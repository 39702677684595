import React, {useEffect, useState} from "react";
import UserList from "../../components/_PAGES/UserList/UserList";
import CreateNewTeacherModal from "../../components/_PAGES/UserList/CreateNewTeacherModal/CreateNewTeacherModal";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Tabs from "../../components/_BASE/Layout/TabsTable/Tabs";
import InactiveList from "../../components/_PAGES/UserList/InactiveList/InactiveList";
import BlockedUsers from "../../components/_PAGES/UserList/BlockedUsers/BlockedUsers";


const Teachers = () => {
    const [newTeacherModalShown, setNewTeacherModalShown] = useState(false)
    const [activeTab, setActiveTab] = useState('Активные учителя');

    const createNewUserModal = <CreateNewTeacherModal
        closeModalFunc={setNewTeacherModalShown}
        userTypes={['Школьный учитель', 'Преподаватель ВУЗа']}
    />

    useEffect(() => {
        TopNavbarTitle.setNewTitle('Учителя')
    }, [])

    const tabs = [
        {
            label: 'Активные учителя',
            content: <UserList
                name={'учителя'}
                modal={createNewUserModal}
                modalIsShown={newTeacherModalShown}
                openModalFunc={setNewTeacherModalShown}
                roleTitle={'teacher'}
                profile_link={'/teachers'}
                status={'NOT_BLOCK'}
                key={'active'}
            />
        },
        {
            label: 'Ожидают подтверждения',
            content: <InactiveList/>
        },
        {
            label: 'Заблокированные учителя',
            content: <BlockedUsers role={'teacher'}/>
        },
        {
            label: 'Удаленные учителя',
            content: <UserList
                name={'учителя'}
                modal={createNewUserModal}
                modalIsShown={newTeacherModalShown}
                openModalFunc={setNewTeacherModalShown}
                roleTitle={'teacher'}
                profile_link={'/teachers'}
                status={'DELETED'}
                key={'deleted'}
            />
        }
    ]

    return <div>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}/>
    </div>
}

export default Teachers