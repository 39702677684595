import s from './Charts.module.scss'
import f from '../../../../static/fonts.module.scss'
import {PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend} from 'recharts';
import {CustomTooltip} from "../ChartHelper/ChartHelper";
import style from "../DashboardBlock/ChartArea/ChartArea.module.scss";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

const RadialChart = ({data, isLoading=false}) => {

    const COLORS = [
        "#3C4AE4",
        "#FF426C",
        "#ffa100",
        "#36daa8",
        "#e12eff",
        "#881017",
        "#FFB8BA",
        "#A1A5B7",
        "#E6F1FF",
        "#3F4254",
    ];


    const renderColorfulLegendText = (value) => {
        return <span
            className={f.caption}
            style={{color: "#FF426C"}}
        >{value}</span>;
    };

    if (!data) return null

    return <div className={s.wrap}>
        <div className={s.chartWrap}>
            {
                isLoading &&
                <div className={style.loader}>
                    <Preloader/>
                </div>
            }
            <ResponsiveContainer  width={"100%"}>
                <PieChart>
                    <Pie
                        data={data}
                        cy={87.5}
                        innerRadius={75.5}
                        outerRadius={87.5}
                        cornerRadius={12}
                        paddingAngle={2}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                        ))}
                    </Pie>
                    <Legend
                        width={"100%"}
                        height={'calc(100% - 210px)'}
                        formatter={renderColorfulLegendText}
                        iconType={'square'}
                    />
                    <Tooltip
                        content={<CustomTooltip/>}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    </div>

}

export default RadialChart
