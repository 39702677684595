import {makeAutoObservable} from "mobx";

class TopNavbarTitle {
    title = ''

    constructor() {
        makeAutoObservable(this)
    }

    setNewTitle(text) {
        this.title = text
    }

}

export default new TopNavbarTitle()