import s from './BonusOperations.module.scss'
import f from '../../../../../static/fonts.module.scss'
import Table from "../../../../_BASE/Layout/Table/Table";
import {useEffect, useState} from "react";
import Pagination from "../../../../_BASE/UI/Pagination/Pagination";
import {getUserBonusOperationsCount, getUserBonusOperationsList} from "../../../../../services/PaymentService";
import {userStore} from "../../UserInfo";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import {observer} from "mobx-react-lite";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {timestampToDate} from "../../../../../static/utilities";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";
import {notifications} from "../../../../../index";

const BonusOperations = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    const [total, setTotal] = useState();
    const [paginationObject, setPaginationObject] = useState({
        page: 1, offset: 20
    });

    useEffect(() => {
        const getCount = async () => {
            try {
                const req = await getUserBonusOperationsCount({
                    id: userStore.userId
                })
                setTotal(req)
            } catch (e) {
                setNotFound(true)
                notifications.addNotification(e.response.data.message)
                console.log(e)
            }
        }

        getCount()
    }, [])

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getUserBonusOperationsList({
                    id: userStore.userId,
                    page: paginationObject.page,
                    offset: paginationObject.offset
                })
                setData(req)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
                console.log()
            } finally {
                setIsLoading(false)
            }
        }

        getData()
    }, [])

    if (isLoading) return <Preloader type={'big'}/>

    if (notFound) return <EmptyList/>


    return <div className={s.wrap}>
        <Table gridTemplate={'1fr 2fr 1fr 1fr 92px'}
               headsArray={["ID Заказа", "Комментарий", "Сумма", "Дата запроса", "Действие"]}
               rowsArray={data.map(row => <SingleRow row={row} key={row.id}/>)}
        />
        <Pagination paginationObject={paginationObject} setPaginationObject={setPaginationObject}
                    totalCount={total}
        />
    </div>
}

const SingleRow = ({row}) => {
    const actions = [
        {
            label: 'Просмотр заказа',
            type: 'link',
            link: `/orders/${row.orderId}`
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{row.orderId?row.orderId:'Не указан'}</p>
        <p className={f.body1}>{row.comment}</p>
        <p className={f.subtitle1Bold}>{row.cCode === "INCREASE" ? "+" : "-"}{row.amount}</p>
        <p className={f.subtitle1Bold}>{timestampToDate(row.date)}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            {row.orderId && <DropdownButton type={'icon'} actions={actions}/>}
        </div>
    </>
}

export default observer(BonusOperations)