import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Orders from "../../components/_PAGES/Stats/Orders/Orders";



const OrdersStatsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Статистика по заказам')
    }, [])

    return <Orders/>
}

export default OrdersStatsPage