import s from './Docs.module.scss'
import OtherDocuments from "./OtherDocuments/OtherDocuments";
import Tabs from "../../_BASE/Layout/TabsTable/Tabs";
import {useState} from "react";
import StaticDocuments from "./StaticDocuments/StaticDocuments";

const Docs = () => {
    const [activeTab, setActiveTab] = useState('Другие документы');

    const tabs = [
        {
            label: 'Другие документы',
            content: <OtherDocuments/>
        },
        {
            label: 'Важные документы',
            content: <StaticDocuments/>
        }
    ]

    return <div className={s.wrap}>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
    </div>
}


export default Docs
