import s from './RecordModal.module.scss'
import f from '../../../../../static/fonts.module.scss'
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Select from "../../../../_BASE/UI/Select/Select";
import {useEffect, useState} from "react";
import Input from "../../../../_BASE/UI/Input/Input";
import {
    createCompetencyRecord,
    getOptionsList,
    updateCompetencyRecord
} from "../../../../../services/CompetencyService";
import {CategoriesStore} from "../../CategoriesPage";
import Radios from "../../../../_BASE/UI/Radios/Radios";
import {notifications} from "../../../../../index";

const RecordModal = ({
                         data = null,
                         closeFunc,
                         subject,
                         getData,
                         status,
                     }) => {
    const Store = CategoriesStore

    const [selectedOption, setSelectedOption] = useState()
    const [price, setPrice] = useState('')
    const [priceIsValid, setPriceIsValid] = useState(false)
    const [activeState, setActiveState] = useState(1)
    const [originality, setOriginality] = useState(0);

    const [loadedOptions, setLoadedOptions] = useState([])
    const [optionLayout, setOptionLayout] = useState([]);
    const [disableSaveBtn, setDisableSaveBtn] = useState(true)


    useEffect(() => {
        const getData = async () => {
            try {
                if (data) {
                    setSelectedOption(data.title)
                    setPrice(data.price.toString())
                    setActiveState(status)
                    setOriginality(data.isOriginality)

                } else {
                    const optList = await getOptionsList(Store.optionsCode)
                    setLoadedOptions(optList)
                    setOptionLayout(optList.map(({title}) => title))
                }
            } catch (e) {
                throw e
            }
        }
        getData()
    }, [Store.optionsCode, data, status])

    const handleUpdateRecord = async () => {
        try {
            await updateCompetencyRecord(
                data.id,
                Store.optionsCode,
                price,
                activeState,
                originality
            )
            getData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        const opt = priceIsValid
            && selectedOption !== undefined
            && (price?.length > 0 && price !== '0')
            && (activeState === 1 || activeState === 0)
        setDisableSaveBtn(opt)

    }, [selectedOption, price, activeState, priceIsValid])

    const handleCreateClick = async () => {
        try {
            const crReq = await createCompetencyRecord(
                Store.optionsCode,
                subject.id,
                loadedOptions.filter(({title}) => title === selectedOption)[0].id,
                price,
                activeState,
                originality
            )
            if (crReq.newRecord.slice(-1) != 0) {
                notifications.addNotification(crReq.newRecord, 'OK')
            }
            if (crReq.existRecord.slice(-1) != 0) {
                notifications.addNotification(crReq.existRecord)
            }
            if (crReq.errorRecord.slice(-1) != 0) {
                notifications.addNotification(crReq.errorRecord)
            }
            getData()
            closeFunc(false)
        } catch (e) {
            throw e
        }
    }


    return <ModalWindow
        title={data ? 'Изменение связи' : 'Создание новой связи'}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={data ? 'Сохранить' : 'Создать'}
        primaryAction={data
            ? () => handleUpdateRecord()
            : () => handleCreateClick()
        }
        primaryButtonIsDisabled={!disableSaveBtn}
    >
        <div className={s.wrap}>
            <p className={f.boldH6}>Предмет: <span className={s.selectedSubjectLabel}>{subject.title}</span></p>
            {
                data &&
                <p className={f.boldH6}>Тип задания: <span className={s.selectedSubjectLabel}>{data.title}</span></p>

            }
            {

                data
                    ? null
                    : <Select title={"Укажите тип задания"}
                              options={optionLayout}
                              selectedOption={selectedOption}
                              setSelectedOption={setSelectedOption}
                    />
            }
            <Input title={'Укажите цену связи'}
                   setValue={setPrice}
                   value={price}
                   styles={{maxWidth: 524}}
                   setIsValid={setPriceIsValid}
                   validator={'number'}
            />
            <Radios
                groupName={'status'}
                checkedValue={activeState}
                setCheckedValue={setActiveState}
                size={'small'}
                data={[
                    {
                        value: 1,
                        title: 'Активная связь'
                    },
                    {
                        value: 0,
                        title: 'Неактивная связь'
                    },
                ]}
            />
            <p style={{marginBottom: -16}} className={f.subtitle1Bold}>Существует ли оригинальность работы для данной
                связи?</p>
            <Radios
                groupName={'originality'}
                checkedValue={originality}
                setCheckedValue={setOriginality}
                size={'small'}
                data={[
                    {
                        value: 1,
                        title: 'Да'
                    },
                    {
                        value: 0,
                        title: 'Нет'
                    },
                ]}
            />
        </div>
    </ModalWindow>
}

export default RecordModal