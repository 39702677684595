import s from './CategoriesPage.module.scss'

import {useState} from "react";

import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Subjects from "./Subjects/Subjects";
import SubjectModal from "./Subjects/SubjectModal/SubjectModal";
import CategoriesStoreMobx from "../../../store/categories";
import Options from "./Options/Options";
import OptionsModal from "./Options/OptionsModal/OptionsModal";
import Tabs from "../../_BASE/Layout/TabsTable/Tabs";
import Unions from "./Unions/Unions";

export const CategoriesStore = new CategoriesStoreMobx()

const CategoriesPage = ({type}) => {
    const [createSubjectModalVisible, setCreateSubjectModalVisible] = useState(false)
    const [createOptionModalVisible, setCreateOptionModalVisible] = useState(false)

    const [activeTab, setActiveTab] = useState('Предметы');


    CategoriesStore.setType(type)
    CategoriesStore.setOptionsCode(type === 'SCHOOL' ? 'CLASS' : 'TASK_TYPE')

    const actions = [
        {
            label: 'Добавить предмет',
            func: () => {
                setCreateSubjectModalVisible(true)
                setActiveTab('Предметы')
            }
        },
        {
            label: 'Добавить тип заданий',
            func: () => {
                setCreateOptionModalVisible(true)
                setActiveTab('Типы заданий')
            }
        }
    ]

    const tabsArray = [
        {
            label: 'Предметы',
            content: <Subjects/>
        },
        {
            label: 'Типы заданий',
            content: <Options/>
        },
        {
            label: 'Связи',
            content: <Unions/>
        }
    ]


    return <div className={s.wrap}>
        <Tabs tabs={tabsArray} activeTab={activeTab} setActiveTab={setActiveTab}
              sideButtons={<DropdownButton actions={actions} text={'Действия'}/>}/>

        {
            createSubjectModalVisible
            && <SubjectModal closeFunc={setCreateSubjectModalVisible}/>
        }
        {
            createOptionModalVisible
            && <OptionsModal closeFunc={setCreateOptionModalVisible}/>
        }
    </div>
}

export default CategoriesPage
