import {useEffect} from "react";
import TopNavbarTitle from "../../../store/TopNavbarTitle";
import CloseTicket from "../../../components/_PAGES/SupportCategories/CloseTicketReasons/CloseTicket";


const TicketClosePage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Список причин закрытия обращения в ТП')
    }, [])

    return <CloseTicket/>
}

export default TicketClosePage