import s from './AgentActivityTable.module.scss'
import f from '../../../../../static/fonts.module.scss'

import {useEffect, useState} from "react";

import {getAgentsActivity} from "../../../../../services/StatisticService";

import Tabs from "../../../../_BASE/Layout/TabsTable/Tabs";
import Table from "../../../../_BASE/Layout/Table/Table";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";

const AgentTable = ({period}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getAgentsActivity(period)
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period])


    if (isLoading) return <Preloader type={'big'}/>

    return <Table headsArray={['', 'Имя агента ТП', "Количество закрытых обращений", "Действие"]}
                  gridTemplate={'40px 2fr 1fr 92px'}
                  rowsArray={data.map((item) => <AgentTableRow rowData={item} key={item.id}/>)}/>
}

const AgentTableRow = ({rowData}) => {
    const actions = [{
        label: "Открыть профиль",
        func: () => window.open(`/agents/${rowData.userId}`)
    }]

    return <>
        <img className={s.avatar} src={rowData.user.avatarLink} alt=""/>
        <p className={f.subtitle1Bold}>{rowData.user.surname} {rowData.user.name} {rowData.user.middlename}</p>
        <p className={f.subtitle1Bold}>{rowData.number}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
    </>
}

const tabs = [
    {
        label: 'На этой неделе',
        content: <AgentTable key={'WeeklyActivity'} period={'WEEK'}/>
    },
    {
        label: 'За этот месяц',
        content: <AgentTable key={'MonthlyActivity'} period={'MONTH'}/>
    },
    {
        label: 'За этот год',
        content: <AgentTable key={'YearlyActivity'} period={'YEAR'}/>
    }
]

const AgentActivityTable = () => {
    const [activeTab, setActiveTab] = useState('На этой неделе');


    return <div className={s.wrap}>
        <h4 className={f.boldH4}>Самый работоспособный агент ТП</h4>
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}/>
    </div>
}

export default AgentActivityTable