import {useCallback, useState} from "react";
import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {getTicketCategories, getTicketSections} from "../../../../services/SupportService";

import Sections from "./Sections/Sections";
import Categories from "./Categories/Categories";
import Unions from "./Unions/Unions";
import {default as CreateSectionModal} from "./Sections/CreateModal/CreateModal";
import {default as CreateCategoryModal} from "./Categories/CreateModal/CreateModal";


const SupportCategories = () => {
    const [sections, setSections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeTab, setActiveTab] = useState('Секции');

    const [createSectionModal, setCreateSectionModal] = useState(false);
    const [createCategoryModal, setCreateCategoryModal] = useState(false);

    const getSections = useCallback(async () => {
        try {
            const req = await getTicketSections()
            setSections(req)
        } catch (e) {
            throw e
        }
    }, [])

    const getOptions = useCallback(async () => {
        try {
            const req = await getTicketCategories()
            setCategories(req)
        } catch (e) {
            throw e
        }
    }, [])


    const tabs = [
        {
            label: 'Секции',
            content: <Sections getSections={getSections} sections={sections}/>
        },
        {
            label: 'Категории',
            content: <Categories getCategories={getOptions} categories={categories}/>
        },
        {
            label: 'Связи',
            content: <Unions/>
        },
    ]

    const actions = [
        {
            label: 'Создать новую секцию',
            func: () => setCreateSectionModal(true)
        },
        {
            label: 'Создать новую категорию',
            func: () => setCreateCategoryModal(true)
        },

    ]


    return <>
        <Tabs tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              sideButtons={<DropdownButton actions={actions} text={"Действия"}/>}/>
        {
            createSectionModal && <CreateSectionModal
                closeFunc={setCreateSectionModal}
                updateData={getSections}
            />
        }
        {
            createCategoryModal && <CreateCategoryModal
                closeFunc={setCreateCategoryModal}
                updateData={getOptions}
            />
        }
    </>

}

export default SupportCategories