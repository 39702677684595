import ChartArea from "../../../../Dashboard/DashboardBlock/ChartArea/ChartArea";
import {useEffect, useState} from "react";
import {getTicketsCount} from "../../../../../../services/StatisticService";
import {formatDataForAreaCharts} from "../../../../../../static/utilities";


const TicketCountGraph = ({period}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getTicketsCount(period, 'CLOSE')
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [period])

    if (data?.length === 0) return null

    return <ChartArea data={formatDataForAreaCharts(data, period)} isLoading={isLoading}/>
}

export default TicketCountGraph