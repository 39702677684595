import s from './StaticDocuments.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import DocumentEditorModal from "../DocumentEditorModal/DocumentEditorModal";
import {getStaticDocuments} from "../../../../services/FileService";
import Table from "../../../_BASE/Layout/Table/Table";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";


const StaticDocuments = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            setIsLoading(true)
            const docs = await getStaticDocuments()
            setData(docs)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {

        getData()
    }, [])

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <Table rowsArray={data.map(rowData => <SingleRow data={rowData}
                                                         id={rowData.code}
                                                         getData={getData}
        />)}
               headsArray={['Code','Название',"Дата изменения", "Действие"]}
               gridTemplate={'150px 3fr 1fr 92px'}
        />
    </div>
}

const SingleRow = ({data, getData}) => {
    const [editModal, setEditModal] = useState(false);

    const actions = [
        {
            label: 'Редактировать',
            func: () => setEditModal(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{data.code}</p>
        <p className={f.subtitle1Bold}>{data.title}</p>
        <p className={f.subtitle1Bold}>{new Date(Number(data.date)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })}</p>
        <DropdownButton actions={actions} type={'icon'}/>
        {
            editModal && <DocumentEditorModal updateData={getData} closeFunc={setEditModal} data={data}/>
        }
    </>
}

export default StaticDocuments
