import f from '../../../../../static/fonts.module.scss'

import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";

import {ReactComponent as EditIcon} from "../../editIcon.svg";
import {useState} from "react";
import OptionsModal from "../OptionsModal/OptionsModal";
import {deleteOption} from "../../../../../services/CompetencyService";
import {CategoriesStore} from "../../CategoriesPage";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";

const SingleOptionRow = ({id, title}) => {
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const Store = CategoriesStore

    const deleteOptionRequest = async () => {
        try {
            setIsDeleting(true)
            await deleteOption(Store.optionsCode, id)
            await Store.getOptions()
            setIsDeleting(false)
        } catch (e) {
            console.log(e)
        } finally {
            setDeleteModal(false)
        }

    }

    const actionList = [
        {
            label: 'Изменить',
            func: () => setEditModalVisible(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        },
    ]

    return <>
        <p className={f.subtitle1Bold}>{title}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton text={<EditIcon/>} type={'icon'} actions={actionList}
                            nested_styles={{background: "white"}}/>
        </div>
        {
            editModalVisible && <OptionsModal
                closeFunc={setEditModalVisible}
                data={{
                    id: id,
                    title: title
                }
                }/>
        }
        {
            deleteModal && <ModalWindow closeFunc={setDeleteModal}
                                        title={'Удаление типа'}
                                        type={'small'}
                                        primaryButtonIsDisabled={isDeleting}
                                        primaryAction={() => deleteOptionRequest()}
                                        primaryButtonTitle={'Удалить'}>
                <p className={f.subtitle1Bold} style={{
                    width: 524
                }}>
                    Вы действительно хотите удалить тип <span>{title}</span>?
                </p>
            </ModalWindow>
        }
    </>
}
export default SingleOptionRow