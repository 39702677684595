import * as XLSX from 'xlsx'
import {notifications} from "../../../../index";

export const convertToFile = (data, apiType, docTitle = 'Export') => {
    const cleaned = formatData(data, apiType)

    if (cleaned.length === 0) {
        notifications.addNotification('Данных нет, попробуйте другой диапазон')
        return
    }

    const worksheet = XLSX.utils.json_to_sheet(cleaned);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Export");
    XLSX.writeFile(workbook, `${docTitle}.xlsx`, {compression: true});
}

const formatData = (data, type) => {
    switch (type) {

        case '/auth/s1': {
            return data.data.data.map(row => ({
                date: row[0],
                ...row[1]
            }))
        }
        case '/auth/s4': {
            return data.data.array.data.map(row => ({
                name: row.name,
                ...row.people

            }))
        }
        case '/order/s1': {
            return data.data.data.map(row => ({
                date: row[0],
                count: row[1]
            }))
        }
        case '/order/s4': {
            return data.data.data
        }
        case '/order/s5': {
            return data.data.data
        }
        case '/payment/s2': {
            return data.data.data.map(row => ({
                date: row[0],
                amount: row[1]
            }))
        }
        case '/payment/s3': {
            return data.data.data
        }
        default: {
            return {}
        }
    }
}
