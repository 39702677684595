import s from './Unions.module.scss'
import f from '../../../../static/fonts.module.scss'

import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {CategoriesStore} from "../CategoriesPage";

import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../_BASE/Layout/Table/Table";
import {getOptionsListBySubjectID, getSubjectList, updateArchiveStatus} from "../../../../services/CompetencyService";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import RecordModal from "../RecordsModal/RecordModal/RecordModal";
import Button from "../../../_BASE/UI/Button/Button";
import Input from "../../../_BASE/UI/Input/Input";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";

const Unions = () => {
    const Store = CategoriesStore

    const [selectedSubject, setSelectedSubject] = useState();
    const [subjects, setSubjects] = useState([]); //Предметы с учетом фильтра по названию
    const [initialSubjects, setInitialSubjects] = useState([]); //Исходный полученый список предметов
    const [currentSubjectUnions, setCurrentSubjectUnions] = useState([]);
    const [subjectsLoading, setSubjectsLoading] = useState(true);
    const [unionsLoading, setUnionsLoading] = useState(false);
    const [createNewUnionModal, setCreateNewUnionModal] = useState(false);

    const [filterText, setFilterText] = useState('');


    const getUnions = useCallback(async () => {
        try {
            if(selectedSubject){
                setUnionsLoading(true)
                const data = await getOptionsListBySubjectID(Store.type, selectedSubject.id)
                setCurrentSubjectUnions(data)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setUnionsLoading(false)
        }
    }, [selectedSubject, Store.type])

    const getSubjects = useCallback(async () => {
        try {
            setSubjectsLoading(true)
            const data = await getSubjectList(Store.type)
            setInitialSubjects(data)
            setSubjects(data)
        } catch (e) {
            console.log(e)
        } finally {
            setSubjectsLoading(false)
        }
    }, [Store.type])

    useEffect(() => {
        getSubjects()
    }, [getSubjects])

    useEffect(() => {
        if (selectedSubject) {
            getUnions(selectedSubject.id)
            console.log(selectedSubject)
        }
    }, [selectedSubject, getUnions])

    useEffect(() => {
        if (filterText.length > 0) {
            setSubjects(initialSubjects.filter(subject => subject.title.toUpperCase().indexOf(filterText.toUpperCase()) !== -1))
        } else {
            setSubjects(initialSubjects)
        }
    }, [filterText, initialSubjects])

    return <div className={s.wrap}>
        <div className={s.col1}>
            <p className={f.subtitle1Bold}>Список предметов</p>
            <Input
                placeholder={"Введите название предмета"}
                value={filterText} setValue={setFilterText} styles={{maxWidth: "100%"}}/>
            {
                subjectsLoading
                    ? <Preloader type={'big'}/>
                    : <Table gridTemplate={'40px 1fr 92px'}
                             headsArray={['', 'ПРЕДМЕТ', 'ДЕЙСТВИЕ']}
                             rowsArray={subjects.map(subject => <SubjectsTableRow subjectData={subject}
                                                                                  setSelectedSubject={setSelectedSubject}
                                                                                  key={subject.id}/>)}
                    />
            }
        </div>
        <div className={s.col2}>
            {selectedSubject &&
                <p className={f.subtitle1Bold}>Список связей для предмета: <span
                    className={s.selectedSubjectLabel}>{selectedSubject?.title}</span></p>
            }
            {
                unionsLoading
                    ? <Preloader type={'big'}/>
                    : <Table
                        emptyListTitle={selectedSubject
                            ? 'Связей по выбранному предмету не существует'
                            : 'Выберите предмет'}
                        headsArray={["ТИП ЗАДАЧИ", "СТОИМОСТЬ", "АКТИВНОСТЬ", "ОРИГИНАЛЬНОСТЬ", "ДЕЙСТВИЕ"]}
                        gridTemplate={"1fr 1fr 1fr 1fr 92px"}
                        rowsArray={currentSubjectUnions.map((item) => <UnionsTableRow subject={selectedSubject}
                                                                                      rowData={item}
                                                                                      key={item.id}
                                                                                      getUnionsList={getUnions}/>)}/>
            }
            {
                selectedSubject && <div>
                    <Button text={"Добавить связь"} click={() => setCreateNewUnionModal(true)}/>

                </div>
            }
            {
                createNewUnionModal && <RecordModal closeFunc={setCreateNewUnionModal}
                                                    subject={selectedSubject}
                                                    getData={getUnions}
                />
            }
        </div>
    </div>
}


const SubjectsTableRow = ({subjectData, setSelectedSubject}) => {
    const actions = [{
        label: 'Просмотр связей',
        func: () => setSelectedSubject(subjectData)
    }]
    return <>
        <img className={s.subjectIcon} src={subjectData.iconLink} alt=""/>
        <p className={f.subtitle1Bold}>{subjectData.title}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
    </>
}


const UnionsTableRow = ({rowData, subject, getUnionsList}) => {
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const Store = CategoriesStore
    const actions = [
        {
            label: 'Изменить',
            func: () => {
                setEditModal(true)
            }
        },
        {
            label: 'Удалить',
            func: () => {
                setDeleteModal(true)
            }
        }
    ]

    const deleteUnion = async () => {
        try {
            setIsDeleting(true)
            await updateArchiveStatus(rowData.id, Store.optionsCode, 0)
            await getUnionsList(subject.id)
            setDeleteModal(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsDeleting(false)
        }
    }

    return <>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        <p className={f.subtitle1Bold}>{rowData.price} ₽</p>
        {
            rowData.status === 1
                ? <p className={`${s.unionStatus} ${f.caption} ${s.active}`}>Активна</p>
                : <p className={`${s.unionStatus} ${f.caption} ${s.inactive}`}>Не активна</p>
        }
        {
            rowData.isOriginality === 1
                ? <p className={`${s.unionStatus} ${f.caption} ${s.active}`}>Включена</p>
                : <p className={`${s.unionStatus} ${f.caption} ${s.inactive}`}>Отсутствует</p>
        }
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            editModal && <RecordModal closeFunc={setEditModal}
                                      subject={subject}
                                      data={rowData}
                                      getData={getUnionsList}
                                      status={rowData.status}
            />
        }
        {
            deleteModal && <ModalWindow closeFunc={setDeleteModal}
                                        type={'small'}
                                        primaryButtonTitle={'Удалить'}
                                        title={'Удаление связи'}
                                        primaryAction={() => deleteUnion()}
                                        primaryButtonIsDisabled={isDeleting}
            >
                <p>Вы действительно хотите удалить связь <span
                    className={s.selectedSubjectLabel}>{subject.title} - {rowData.title}</span>?
                </p>
            </ModalWindow>
        }
    </>
}

export default observer(Unions)