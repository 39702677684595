import s from "./OtherDocuments.module.scss";
import f from "../../../../static/fonts.module.scss";

import {useEffect, useState} from "react";
import {getSystemDocuments} from "../../../../services/FileService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../_BASE/Layout/Table/Table";
import Button from "../../../_BASE/UI/Button/Button";
import CreateModal from "../CreateModal/CreateModal";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import DeleteModal from "../DeleteModal/DeleteModal";

const OtherDocuments = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreating, setIsCreating] = useState(false);

    const getData = async () => {
        try {
            const req = await getSystemDocuments()
            setData(req)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <Table gridTemplate={`1fr 1fr 92px`}
               headsArray={['Название', "Дата", "Действие"]}
               rowsArray={data.map(rowData => <SingleRow getData={getData} data={rowData} id={rowData.id}/>)}/>
        <div>
            <Button text={'Добавить документ'} click={() => setIsCreating(true)}/>
        </div>

        {
            isCreating && <CreateModal getData={getData} closeFunc={setIsCreating}/>
        }
    </div>
}

const SingleRow = ({data, getData}) => {
    const [deleteOpen, setDeleteOpen] = useState(false);

    const actions = [
        {
            label: 'Удалить',
            func: () => setDeleteOpen(true)
        }
    ]

    return <>
        <a href={data.file_location} target={'_blank'} rel="noreferrer" className={f.subtitle1Bold}>{data.title}</a>
        <p className={f.subtitle1Bold}>{new Date(Number(data.date)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })}</p>
        <DropdownButton type={'icon'} actions={actions}/>
        {
            deleteOpen && <DeleteModal getData={getData} closeFunc={setDeleteOpen} id={data.id}/>
        }
    </>
}

export default OtherDocuments
