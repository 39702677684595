import s from './SupportChart.module.scss'
import f from "../../../../static/fonts.module.scss";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import RadialChart from "../RadialChart/Charts";
import {useEffect, useState} from "react";
import {getPopularCategoriesByAgent} from "../../../../services/StatisticService";


const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]


const SupportChart = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const actions = periods.map((item) => {
        return {
            label: item.text,
            func: () => setCurrentPeriod(item)
        }
    })


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getPopularCategoriesByAgent(currentPeriod.code)
                const formatted = req.map((item) => {
                    return {
                        name: `${item.sTitle} / ${item.cTitle}`,
                        value: item.number
                    }
                })
                setData(formatted)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [currentPeriod.code])

    return <div className={s.chartWrap}>
        <div className={s.chartHeader}>
            <p className={f.subtitle1Bold}>Популярные категории обращений {currentPeriod.text.toLowerCase()}</p>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            data.length === 0
                ? <div className={s.emptyTickets}><p className={f.subtitle1Bold}>Обращений нет</p></div>
                : <div className={s.radialChartWrap}>
                    <RadialChart data={data} isLoading={isLoading}/>
                    <div className={s.chartCounterWrap}>
                        <h5 className={f.boldH5}>{data.reduce((total, current) => total + current.value, 0)}</h5>
                        <p className={f.caption}>обращений</p>
                    </div>
                </div>
        }

    </div>
}

export default SupportChart