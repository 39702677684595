import s from './DeleteModal.module.scss'
import f from '../../../../../../static/fonts.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useState} from "react";
import {archiveTicketSection} from "../../../../../../services/SupportService";

const DeleteModal = ({sectionId, sectionTitle, closeFunc, updateData}) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteSection = async () => {
        try {
            setIsDeleting(true)
            await archiveTicketSection(sectionId, "OFF")
            await updateData()
            setIsDeleting(false)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    return <ModalWindow
        closeFunc={closeFunc}
        title={"Удаление секции"}
        type={'small'}
        primaryAction={deleteSection}
        primaryButtonIsDisabled={isDeleting}
        primaryButtonTitle={'Удалить'}
    >
        <div className={s.wrap}>
            <p className={f.subtitle1Bold}>Вы действительно хотите удалить секцию <span
                className={s.color}>"{sectionTitle}"</span>?</p>
        </div>
    </ModalWindow>
}

export default DeleteModal