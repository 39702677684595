import s from "./Cards.module.scss";
import SingleCard from "../../../Dashboard/StatCards/SingleCard/SingleCard";
import {getGuaranteeStats, getIncomeStats} from "../../../../../services/StatisticService";
import {ReactComponent as IncomeIcon} from "../Wallet.svg";
import {ReactComponent as GuaranteeIcon} from "../Shield.svg";


const Cards = () => {
    return <div className={s.cardGrid}>
        <SingleCard theme={'blue'} title={'Прибыль'} icon={<IncomeIcon/>}
                    getDataApi={() => getIncomeStats('DAY')}
                    periods={[{
                        text: 'За сегодня',
                        code: "DAY"
                    }]}
                    style={{
                        padding:32
                    }}
        />
        <SingleCard title={'Стоимость заказов на гарантии'} icon={<GuaranteeIcon/>}
                    getDataApi={() => getGuaranteeStats()}
                    periods={[{
                        text: 'На текущий момент',
                        code: "ALL_TIME"
                    }]}
                    style={{
                        padding:32
                    }}
        />
    </div>
}

export default Cards