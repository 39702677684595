import s from './SupportTable.module.scss'
import f from '../../../../static/fonts.module.scss'

import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import TicketModal from "../../TicketModal/TicketModal";
import Table from "../../../_BASE/Layout/Table/Table";

import {getSupportTickets, getSupportTicketsCount} from "../../../../services/SupportService";
import {getColor} from "../../UserInfo/SupportTable/SupportTable";
import {useCallback, useEffect, useState} from "react";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";


const SupportTable = () => {
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 5
    });
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const res = await getSupportTickets(paginationObject.page, paginationObject.offset)
            setData(res)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [paginationObject.page, paginationObject.offset])

    const getCount = useCallback(async () => {
        try {
            setCountIsLoaded(false)
            const res = await getSupportTicketsCount()
            setTotal(res)
            setCountIsLoaded(true)
        } catch (e) {
            throw e
        }
    }, [])


    useEffect(() => {
        getCount()
    }, [getCount])

    useEffect(() => {
        if (countIsLoaded) {
            getData()
        }

    }, [countIsLoaded, paginationObject.page, paginationObject.offset, getData])


    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <Table headsArray={['', 'Агент тп', "КАТЕГОРИЯ", "ТЕМА", "ДАТА СОЗДАНИЯ", "СТАТУС", "Действие"]}
               gridTemplate={'40px 4fr 3fr 5fr 4fr 2fr 92px'}
               rowsArray={data.map((row) => <TableRow key={row.id} rowData={row}/>)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total} offsetSteps={[5, 10]}
        />
    </div>
}

const TableRow = ({rowData}) => {
    const [viewModal, setViewModal] = useState(false);

    const actions = [{
        label: 'Просмотр',
        func: () => setViewModal(true)
    }]

    return <>
        <img className={s.avatar} src={rowData.sImageLink} alt={''}/>
        <p className={f.subtitle1Bold}>{rowData.aName} {rowData.aSurname} {rowData.aMiddlename}</p>
        <div className={s.tableTags}>
            <p className={`${f.body2} ${s.tag}`}>{rowData.sTitle}</p>
            <p className={`${f.body2} ${s.tag}`}>{rowData.cTitle}</p>
        </div>
        <p className={f.body2}>{rowData.question}</p>
        <p className={f.body2}>{new Date(Number(rowData.createDate)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })}</p>
        <p className={`${f.subtitle2Bold} ${getColor(rowData.tsTitle)}`}>{rowData.tsTitle}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            viewModal && <TicketModal closeFunc={setViewModal} id={rowData.id}/>
        }
    </>
}

export default SupportTable