import s from './AreaChart.module.scss'

import {AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis,} from 'recharts';
import Preloader from "../../UI/Preloader/Preloader";
import {GRAPH_COLORS} from "../../../../static/utilities";


export const getGraphLabel = (value) => {
    switch (value) {
        case "school":
            return "Школа"
        case "university":
            return "ВУЗ"
        case "male":
            return "Мужчин"
        case "female":
            return "Женщин"

        default:
            return value
    }
}

const CustomAreaChart = ({
                             data, height = 320, isLoading = false, dataAreasNames
                         }) => {

    if (!data || data.length === 0) return <Preloader style={{height: height}}/>

    return <div className={s.wrap}>
        {
            isLoading &&
            <div className={s.loader}>
                <Preloader/>
            </div>
        }
        <ResponsiveContainer height={height} width="100%">
            <AreaChart
                height={0}
                data={data}
                margin={{
                    top: 8,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <defs>
                    {
                        dataAreasNames.map((name, index) => <linearGradient id={`${name}fill`} x1="0" y1="0" x2="0"
                                                                            y2="1" key={index}>
                            <stop offset="20%" stopColor={GRAPH_COLORS[index]} stopOpacity={.8}/>
                            <stop offset="100%" stopColor={GRAPH_COLORS[index]} stopOpacity={0}/>
                        </linearGradient>)
                    }
                </defs>
                <Tooltip formatter={(value, name, props) => [value, getGraphLabel(name)]}/>
                <XAxis axisLine={false} tickLine={false} dataKey="name"
                       tickMargin={8}
                />
                <YAxis axisLine={false} tickLine={false} type={'number'}/>
                {
                    dataAreasNames.map((areaName, index) => <Area type="monotone" dataKey={areaName}
                                                                  stroke={GRAPH_COLORS[index]}
                                                                  fillOpacity={1} fill={`url(#${areaName}fill)`}
                                                                  key={index}/>)
                }
            </AreaChart>
        </ResponsiveContainer>
    </div>

}

export default CustomAreaChart