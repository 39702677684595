import style from './UserInfo.module.scss'
import f from '../../../static/fonts.module.scss'

import {useParams} from "react-router-dom";

import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";

import TabsPano from "../../_BASE/Layout/TabsPano/TabsPano";

import UserProfileStore from "../../../store/profile";
import TopNavbarTitle from "../../../store/TopNavbarTitle";
import Profile from "./Profile/Profile";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import TitleWithBack from "../../TopNavbar/TitleWithBack/TitleWithBack";


export const userStore = new UserProfileStore()

const UserInfo = ({tabs}) => {
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);

    TopNavbarTitle.setNewTitle(<TitleWithBack
        title={`Информация о пользователе ${userStore.authProfile.name}`}
        link={
            userStore.authProfile.userRoleTitleCode === "TEACHER"
                ? "/teachers"
                : userStore.authProfile.userRoleTitleCode === "STUDENT"
                    ? "/students"
                    : "/agents"
        }
    />)

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                userStore.resetValues()
                await userStore.setUserId(id)
                await userStore.getAllData()
                setContent(<TabsPano data={tabs}/>)
            } catch (e) {
                console.log(e)
            } finally {
                setIsLoading(false)
            }
        }
        getData()
    }, [id, tabs])

    if (isLoading) return <Preloader type={'big'}/>

    return (
        <div className={style.wrap}>
            <div className={style.leftBar}>
                <Profile/>
            </div>
            <div className={style.rightPanel}>
                {
                    userStore.competencyProfile
                        ? content
                        : <div className={style.contentWrap}>
                            <p className={f.boldH5}>Создайте профиль компетенций для полноценной работы</p>
                        </div>
                }
            </div>
        </div>
    )
}

export default observer(UserInfo)
