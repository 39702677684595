import Tabs from "../../_BASE/Layout/TabsTable/Tabs";
import {useState} from "react";
import PriceSettings from "./PriceSettings/PriceSettings";
import OrderSettings from "./OrderSettings/OrderSettings";
import MailSettings from "./MailSettings/MailSettings";


const tabs = [
    {
        label: 'Параметры сервиса оплаты',
        content: <PriceSettings/>
    },
    {
        label: 'Параметры заказа',
        content: <OrderSettings/>
    },
    {
        label: 'Параметры email писем',
        content: <MailSettings/>
    }
]

const SettingsPage = () => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);
    return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
}

export default SettingsPage