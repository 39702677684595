import s from './BonusRequestsTable.module.scss'
import f from '../../../../../static/fonts.module.scss'
import m from '../../../../../static/misc.module.scss'

import {useCallback, useEffect, useState} from "react";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {
    getHelpdeskRequestInfo,
    getHelpdeskRequests,
    getHelpdeskRequestsCount, getHelpdeskUserRequests, getHelpdeskUserRequestsCount,
    operateHelpdeskRequest
} from "../../../../../services/PaymentService";
import Pagination from "../../../../_BASE/UI/Pagination/Pagination";
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import UserCard from "../../../OrderInfo/UserCard/UserCard";
import Button from "../../../../_BASE/UI/Button/Button";
import TicketModal from "../../../TicketModal/TicketModal";
import Table from "../../../../_BASE/Layout/Table/Table";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";


const returnTag = (code) => {
    return <p className={`${f.subtitle1Bold} ${s.tag} ${code === 1 ? s.success : s.rejected}`}>
        {
            code === 1
                ? 'Одобрено'
                : 'Отказано'
        }
    </p>
}
const RequestModal = ({closeFunc, id, updateData}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [ticketModal, setTicketModal] = useState(false);


    useEffect(() => {
        const getData = async () => {
            try {
                const reqData = await getHelpdeskRequestInfo(id)
                setData(reqData)
            } catch (e) {
                throw e
            } finally {
                setIsLoading(false)
            }
        }

        getData()
    }, [id])

    const handleOperateClick = async (status) => {
        try {
            await operateHelpdeskRequest(id, status)
            await updateData()
            closeFunc(false)

        } catch (e) {
            throw e
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        title={'Запрос на начисление средств'}>
        <div className={s.modalWrap}>
            {
                isLoading
                    ? <Preloader/>
                    : <>
                        <div className={s.cards}>
                            <UserCard userId={data.userId} style={{backgroundColor: "#E6F1FF"}}/>
                            <UserCard userId={data.agentUserId}/>
                        </div>
                        <div className={s.info}>
                            <Button text={'Посмотреть обращение'} type={'secondary'}
                                    nestedStyles={{height: 36, justifyContent: "center"}}
                                    click={() => setTicketModal(true)}/>
                            <div className={s.row}>
                                <h6 className={f.boldH6}>ID обращения</h6>
                                <div className={m.dotline}/>
                                <p className={f.subtitle1Bold}>{data.ticketId}</p>
                            </div>
                            <div className={s.col}>
                                <h6 className={f.boldH6}>Комментарий:</h6>
                                <p className={f.body1}>{data.comment}</p>
                            </div>
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Сумма начисления:</h6>
                                <div className={m.dotline}/>
                                <p className={f.subtitle1Bold}>{data.amount}</p>
                            </div>
                            {
                                data.status === 0 &&
                                <>
                                    <div className={s.row}>
                                        <h6 className={f.boldH6}>Результат обработки:</h6>
                                        <div className={m.dotline}/>
                                        {
                                            returnTag(data.isCompleted)
                                        }

                                    </div>
                                </>
                            }
                            <div className={s.row}>
                                <h6 className={f.boldH6}>Дата создания запроса:</h6>
                                <div className={m.dotline}/>
                                <p className={f.subtitle1Bold}>
                                    {
                                        new Date(Number(data.date)).toLocaleDateString('ru-RU', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })
                                    }
                                </p>
                            </div>
                        </div>
                        {
                            data.status !== 0 && <div className={s.buttons}>
                                <Button text={'Отклонить'} click={() => handleOperateClick("FALSE")} type={'alert'}/>
                                <Button text={'Исполнить'} click={() => handleOperateClick("TRUE")}/>
                            </div>
                        }
                        {
                            ticketModal && <TicketModal closeFunc={setTicketModal} id={data.ticketId}/>
                        }
                    </>
            }
        </div>
    </ModalWindow>
}

const TableRow = ({rowData, getData, type}) => {
    const [viewModal, setViewModal] = useState(false);

    const actions = [{
        label: 'Просмотр',
        func: () => setViewModal(true)
    }]

    return <>
        <p className={f.subtitle1Bold}>{rowData.id}</p>
        <p className={f.body1}>{rowData?.comment}</p>
        {
            rowData.status === 0
                ? returnTag(rowData.isCompleted)
                : type === 'FALSE' &&
                <p className={`${f.subtitle1Bold} ${s.tag} ${s.pending}`}>Ожидание</p>
        }
        <p className={f.subtitle1Bold}>{rowData.amount} Р</p>
        <p className={f.subtitle1Bold}>
            {
                new Date(Number(rowData.date)).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                })
            }
        </p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            viewModal && <RequestModal updateData={getData} closeFunc={setViewModal} id={rowData.id}/>
        }
    </>
}

const BonusRequestsTable = ({userId = null, type, userType}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [notFound, setNotFound] = useState(false);
    const [total, setTotal] = useState(0);

    const getCount = useCallback(async () => {
        try {
            setCountIsLoaded(false)
            const count = userId
                ? await getHelpdeskUserRequestsCount(userId, userType)
                : await getHelpdeskRequestsCount(type)
            setTotal(count)
        } catch (e) {
            setNotFound(true)
            console.log(e)
        } finally {
            setCountIsLoaded(true)
        }
    }, [userId, userType, type])

    useEffect(() => {
        getCount()
    }, [getCount])


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const reqData = userId
                    ? await getHelpdeskUserRequests(userId, paginationObject.page, paginationObject.offset, userType)
                    : await getHelpdeskRequests(paginationObject.page, paginationObject.offset, type)
                setData(reqData)
            } catch (e) {
                setNotFound(true)
                console.log(e)
            } finally {
                setIsLoading(false)
            }
        }

        if (countIsLoaded) {
            getData()
        }
    }, [total, paginationObject.page, paginationObject.offset, countIsLoaded, userId, type, userType])

    useEffect(()=>{
        console.log(data)
    },[data])

    if (isLoading) return <Preloader type={'big'}/>

    if (notFound) return <EmptyList/>

    return <div>
        <Table
            gridTemplate={`160px 4fr ${type === 'FALSE' ? '2fr' : ''} 1fr 2fr 92px`}
            headsArray={type === 'FALSE'
                ? [
                    "ID ОБРАЩЕНИЯ",
                    "КОММЕНТАРИЙ",
                    "Ответ",
                    "СУММА",
                    "ДАТА ЗАПРОСА",
                    "ДЕЙСТВИЕ",
                ]
                : [
                    "ID ОБРАЩЕНИЯ",
                    "КОММЕНТАРИЙ",
                    "СУММА",
                    "ДАТА ЗАПРОСА",
                    "ДЕЙСТВИЕ",
                ]
            }
            rowsArray={data.map((item) => <TableRow type={type} getData={async () => getCount()} rowData={item}
                                                    key={item.id}/>)}
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </div>
}

export default BonusRequestsTable