import CategoriesPage from "../../components/_PAGES/CategoriesPage/CategoriesPage";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import {useEffect} from "react";

const SchoolCategories = () => {

    useEffect(() => {
        TopNavbarTitle.setNewTitle('Школьные категории')
    }, [])

    return <CategoriesPage type={'SCHOOL'}/>
}

export default SchoolCategories