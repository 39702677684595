import React, {useEffect, useState} from "react";
import UserList from "../../components/_PAGES/UserList/UserList";
import CreateNewStudent from "../../components/_PAGES/UserList/CreateNewStudentModal/CreateNewStudent";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Tabs from "../../components/_BASE/Layout/TabsTable/Tabs";
import BlockedUsers from "../../components/_PAGES/UserList/BlockedUsers/BlockedUsers";


const Students = () => {
    const [newStudentModalShown, setNewStudentModalShownState] = useState(false)
    const [activeTab, setActiveTab] = useState('Активные ученики');

    const createNewUserModal = <CreateNewStudent
        name={'ученика'}
        closeModalFunc={setNewStudentModalShownState}
    />

    useEffect(() => {
        TopNavbarTitle.setNewTitle('Ученики')
    }, [])

    const tabs = [
        {
            label: 'Активные ученики',
            content: <UserList
                name={'ученика'}
                modal={createNewUserModal}
                modalIsShown={newStudentModalShown}
                openModalFunc={setNewStudentModalShownState}
                roleTitle={'student'}
                profile_link={'/students'}
                status={'NOT_BLOCK'}
                key={'active'}
            />
        },
        {
            label: 'Заблокированные ученики',
            content: <BlockedUsers role={'student'}/>
        },
        {
            label: 'Удаленные ученики',
            content: <UserList
                name={'ученика'}
                modal={createNewUserModal}
                modalIsShown={newStudentModalShown}
                openModalFunc={setNewStudentModalShownState}
                roleTitle={'student'}
                profile_link={'/students'}
                status={'DELETED'}
                key={'deleted'}
            />
        },
    ]

    return <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}/>

}

export default Students