import s from './Operations.module.scss'
import {useCallback, useEffect, useState} from "react";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import OperationsTable from "./OperationsTable/OperationsTable";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import {getOperationsCount, getOperationsList,} from "../../../../services/PaymentService";
import Input from "../../../_BASE/UI/Input/Input";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Button from "../../../_BASE/UI/Button/Button";
import SearchTable from "./SearchTable/SearchTable";


const Operations = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [firstDate, setFirstDate] = useState();
    const [secondDate, setSecondDate] = useState();
    const [searchText, setSearchText] = useState('');

    const [paginationObject, setPaginationObject] = useState({
        page: 1, offset: 20
    });
    const [total, setTotal] = useState();

    const [isFiltered, setIsFiltered] = useState(false);
    const [searchCode, setSearchCode] = useState();


    const searchActions = [
        {
            label: 'ID Заказа',
            func: () => {
                setIsFiltered(true)
                setSearchCode("ORDER_ID")
            }
        }, {
            label: 'ID Заказчика',
            func: () => {
                setIsFiltered(true)
                setSearchCode("CUSTOMER_ID")
            }
        }, {
            label: 'ID Исполнителя',
            func: () => {
                setIsFiltered(true)
                setSearchCode("EXECUTOR_ID")
            }
        },
    ]

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getOperationsList({
                firstDate: Date.parse(firstDate) / 1000,
                secondDate: Date.parse(secondDate) / 1000,
                page: paginationObject.page,
                offset: paginationObject.offset
            })
            setData(req)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }, [firstDate, secondDate, paginationObject.page, paginationObject.offset])

    const getCount = useCallback(async () => {
        try {
            const req = await getOperationsCount({
                firstDate: firstDate,
                secondDate: secondDate
            })
            setTotal(req)
        } catch (e) {
            console.log(e)
        }
    }, [firstDate, secondDate])

    useEffect(() => {
        if (!isFiltered) getCount()
    }, [getCount, isFiltered])

    useEffect(() => {
        if (!isFiltered) getData()

    }, [getData, isFiltered])


    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <div className={s.filterWrap}>
            <Input setValue={setSearchText}
                   value={searchText}
                   styles={{maxWidth: "100%"}}
                   placeholder={'Поиск по ID заказа или пользователя...'}
                   type={'number'}
                   disabled={(firstDate || secondDate || isFiltered)}
            />
            {
                searchText.length > 0
                    ? isFiltered
                        ? <Button text={'Сбросить'} click={() => {
                            setIsFiltered(false)
                            setSearchText('')
                        }}/>
                        : <DropdownButton actions={searchActions} text={'Поиск по'}
                                          nested_styles={{height: 52}}/>
                    : <div className={s.dates}>
                        <Input value={firstDate} setValue={setFirstDate}
                               type={'date'}
                        />
                        <Input value={secondDate} setValue={setSecondDate}
                               type={'date'}
                        />
                        {
                            (firstDate || secondDate) && <Button text={'Сбросить'} click={() => {
                                setFirstDate(null)
                                setSecondDate(null)
                            }}/>
                        }
                    </div>
            }


        </div>
        {
            isFiltered
                ? <SearchTable searchCode={searchCode} searchID={searchText}/>
                : <>
                    <OperationsTable data={data}/>
                    <Pagination paginationObject={paginationObject}
                                setPaginationObject={setPaginationObject}
                                totalCount={total}
                    />
                </>
        }


    </div>
}

export default Operations