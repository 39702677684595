import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import f from '../../../../static/fonts.module.scss'
import {deleteSystemDocument} from "../../../../services/FileService";
import {useState} from "react";


const DeleteModal = ({id, getData, closeFunc}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteDocument = async () => {
        try {
            setIsDeleting(true)
            await deleteSystemDocument(id)
            closeFunc(false)
            await getData()
        } catch (e) {
            throw e
        } finally {
            setIsDeleting(true)
        }
    }

    return <ModalWindow closeFunc={closeFunc} type={'small'} title={'Удалить документ?'}
                        primaryButtonTitle={'Удалить'}
                        primaryAction={() => deleteDocument()}
                        primaryButtonIsDisabled={isDeleting}
    >
        <p className={f.subtitle1Light}>Вы хотите удалить запись. Вы уверены? </p>
    </ModalWindow>
}

export default DeleteModal
