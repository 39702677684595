import f from '../../../../static/fonts.module.scss'
import s from "./Subjects.module.scss";
import {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {CategoriesStore} from "../CategoriesPage";
import Table from "../../../_BASE/Layout/Table/Table";

import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {ReactComponent as EditIcon} from "../editIcon.svg";
import SubjectModal from "./SubjectModal/SubjectModal";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Input from "../../../_BASE/UI/Input/Input";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {updateSubjectStatus} from "../../../../services/CompetencyService";
import {notifications} from "../../../../index";

const TableRow = ({item}) => {
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false);
    const actionList = [
        {
            label: 'Изменить предмет',
            func: () => setEditModalVisible(true)
        },
        {
            label: 'Удалить предмет',
            func: () => setDeleteModal(true)
        },
    ]
    return <>
        <div><img className={s.rowImg} src={item.iconLink} alt=""/></div>
        <p className={f.subtitle1Bold}>{item.title}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton text={<EditIcon/>} type={'icon'} actions={actionList}
                            nested_styles={{background: "white"}}/>
        </div>
        {
            editModalVisible && <SubjectModal
                closeFunc={setEditModalVisible}
                data={{
                    id: item.id,
                    iconLink: item.iconLink,
                    iconId: item.iconId,
                    title: item.title,
                    status: item.status,
                }}
            />
        }
        {
            deleteModal && <DeleteModal
                closeFunc={setDeleteModal}
                subjectId={item.id}
                subjectTitle={item.title}
            />

        }
    </>
}

const Subjects = () => {
    const Store = CategoriesStore

    const [filterText, setFilterText] = useState('');
    const [visibleSubjects, setVisibleSubjects] = useState([]);

    useEffect(() => {
        Store.getSubjects()
    }, [Store])

    useEffect(() => {
        if (filterText.length > 0) {
            setVisibleSubjects(Store.data.filter(subject => subject.title.toUpperCase().indexOf(filterText.toUpperCase()) !== -1))
        } else {
            setVisibleSubjects(Store.data)
        }
    }, [filterText, Store.data])

    useEffect(() => {
        if (Store.data.length > 0) {
            setVisibleSubjects(Store.data)
        }
    }, [Store.data])

    if (Store.isLoading) return <Preloader type={'big'}/>

    return <div className={s.wrap}>
        <Input
            placeholder={"Введите название предмета"}
            value={filterText} setValue={setFilterText} styles={{maxWidth: "100%"}}/>
        <Table gridTemplate={'40px 1fr 92px'}
               headsArray={['', 'Предмет', "Действие"]}
               rowsArray={visibleSubjects.map((item) =>
                   <TableRow item={item} key={item.id}/>
               )}/>
    </div>
}


const DeleteModal = ({subjectId, subjectTitle, closeFunc}) => {
    const Store = CategoriesStore
    const deleteSubject = async () => {
        try {
            await updateSubjectStatus(subjectId, 0)
            await Store.getSubjects()
            notifications.addNotification('Предмет успешно удален', 'OK')
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        type={'small'}
                        title={'Удаление предмета'}
                        primaryAction={() => deleteSubject()}
                        primaryButtonTitle={'Удалить'}
    >
        <p className={f.subtitle1Light} style={{maxWidth: 512}}>Вы действительно хотите удалить предмет <span
            className={f.subtitle1Bold}>{subjectTitle}</span>?</p>
    </ModalWindow>

}

export default observer(Subjects)