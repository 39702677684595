import s from './NotFoundGraph.module.scss'
import f from '../../../../../static/fonts.module.scss'

import ChartArea from "../../../Dashboard/DashboardBlock/ChartArea/ChartArea";
import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";

import {useEffect, useState} from "react";
import {getNotFoundOrdersCount} from "../../../../../services/StatisticService";
import {formatDataForAreaCharts} from "../../../../../static/utilities";

const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]


const NotFoundGraph = () => {
    const [data, setData] = useState([]);
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const [totalCounter, setTotalCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getNotFoundOrdersCount(currentPeriod.code)
                const format = formatDataForAreaCharts(req, currentPeriod.code)
                setTotalCounter(format.reduce((sum, current) => sum + current.value, 0))
                setData(format)
                setIsLoading(false)
            } catch (e) {
            }
        }

        getData()
    }, [currentPeriod])

    if (data.length === 0) return null

    return <div className={s.wrap}>
        <div className={s.headLine}>
            <div className={s.row}>
                <h4 className={f.boldH4}>Заказов, для которых не был найден исполнитель</h4>
                <h4 className={f.boldH4}>{totalCounter}</h4>
            </div>
            <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
        </div>
        <ChartArea height={316} data={data} isLoading={isLoading}/>
    </div>
}
export default NotFoundGraph