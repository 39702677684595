import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import MobileFeedback from "../../components/_PAGES/Feedback/MobileFeedback/MobileFeedback";


const MobileFeedbackPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Обратная связь (приложения)')
    }, [])
    return <MobileFeedback/>
}

export default MobileFeedbackPage