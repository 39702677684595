import s from './Unions.module.scss'
import f from '../../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import {getTicketRelationsBySectionId, getTicketSections} from "../../../../../services/SupportService";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import Table from "../../../../_BASE/Layout/Table/Table";
import {colorType} from "../Sections/Sections";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Button from "../../../../_BASE/UI/Button/Button";
import CreateModal from "./CreateModal/CreateModal";
import DeleteModal from "./DeleteModal/DeleteModal";


const Unions = () => {
    const [selectedSection, setSelectedSection] = useState();
    const [sections, setSections] = useState([]);
    const [currentUnions, setCurrentUnions] = useState([]);

    const [sectionsLoading, setSectionsLoading] = useState(true);
    const [unionsLoading, setUnionsLoading] = useState(false);

    const [createNewUnionModal, setCreateNewUnionModal] = useState(false);

    const getUnions = async (sectionId) => {
        try {
            setUnionsLoading(true)
            const data = await getTicketRelationsBySectionId(sectionId)
            setCurrentUnions(data)
            setUnionsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    const getSections = async () => {
        try {
            setSectionsLoading(true)
            const data = await getTicketSections()
            setSections(data)
            setSectionsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getSections()
    }, [])

    useEffect(() => {
        if (selectedSection) {
            console.log(selectedSection)
            getUnions(selectedSection.id)
        }
    }, [selectedSection])

    return <div className={s.wrap}>
        <div className={s.col1}>
            <p className={f.subtitle1Bold}>Список секций</p>
            {
                sectionsLoading
                    ? <Preloader type={'big'}/>
                    : <Table
                        headsArray={["", "Название", "Тип", "Действие"]}
                        gridTemplate={"40px 2fr 1fr 92px"}
                        rowsArray={sections.map(section => <SingleSectionRow
                            key={section.id}
                            sectionData={section}
                            setSelectedSection={setSelectedSection}
                        />)}
                    />
            }
        </div>
        <div className={s.col2}>
            {
                selectedSection &&
                <p className={f.subtitle1Bold}>Список связей для секции: <span
                    className={s.selectedSection}>{selectedSection?.title}</span></p>

            }
            {
                unionsLoading
                    ? <Preloader type={'big'}/>
                    : <Table headsArray={['Категория', "Действие"]}
                             emptyListTitle={selectedSection
                                 ? 'Связей по выбранной секции не существует'
                                 : 'Выберите секцию'}
                             gridTemplate={'1fr 92px'}
                             rowsArray={currentUnions.map(union => <SingleUnionRow key={union.id}
                                                                                   unionData={union}
                                                                                   selectedSection={selectedSection}
                                                                                   getUnions={getUnions}
                             />)}/>
            }
            {
                selectedSection && <div>
                    <Button text={'Добавить связь'} click={() => setCreateNewUnionModal(true)}/>
                </div>
            }
            {
                createNewUnionModal && <CreateModal closeFunc={setCreateNewUnionModal}
                                                    section={selectedSection}
                                                    updateData={getUnions}/>
            }
        </div>
    </div>
}


const SingleSectionRow = ({sectionData, setSelectedSection}) => {
    const actions = [
        {
            label: "Просмотр связей",
            func: () => setSelectedSection(sectionData)
        }
    ]


    return <>
        <img className={s.sectionIcon} src={sectionData.imageLink} alt=""/>
        <p className={f.subtitle1Bold}>{sectionData.title}</p>
        {colorType(sectionData.typeTitle)}
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
    </>
}

const SingleUnionRow = ({unionData, getUnions, selectedSection}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const actions = [
        {
            label: 'Удалить связь',
            func: () => setDeleteModal(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{unionData.cTitle}</p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <DropdownButton type={'icon'} actions={actions}/>
        </div>
        {
            deleteModal && <DeleteModal closeFunc={setDeleteModal}
                                        updateData={getUnions}
                                        section={selectedSection}
                                        union={unionData}
            />
        }
    </>
}
export default Unions