import s from './StoryList.module.scss'
import f from '../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import {getStoryList, deleteStory} from "../../../../services/NewsService";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

import {ReactComponent as EditIcon} from "../icon/editIcon.svg";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import Table from "../../../_BASE/Layout/Table/Table";

const TableRow = ({data, getData}) => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const actions = [
        {
            label: "Изменить",
            type: 'link',
            link: `/story/${data?.theme_id}/edit`
        },
        {
            label: "Удалить",
            func: () => setDeleteModalIsOpen(true)
        }
    ]

    const getTag = (tag) => {
        let style


        if (tag === 'Для учеников') {
            style = s.blueTag
        }

        if (tag === 'Для учителей') {
            style = s.yellowTag
        }

        if (tag === 'Для всех') {
            style = s.blackTag
        }

        return <p className={`${s.tag} ${style} ${f.caption}`}>{tag}</p>
    }


    return <>
        <img className={s.img} src={data.theme_image} alt={''}/>
        <p className={f.subtitle1Bold}>{data.theme_name}</p>
        <p className={f.subtitle1Bold}>{data.stories.length}</p>
        {
            getTag(data.theme_type_title)
        }
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'} nested_styles={{background: "white"}} text={<EditIcon/>} actions={actions}/>
        </div>
        {
            deleteModalIsOpen &&
            <DeleteModal closeFunc={setDeleteModalIsOpen} id={data.theme_id}
                         getData={getData}/>
        }
    </>
}

const StoryList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getData = async () => {
        try {
            setIsLoading(true)
            const req = await getStoryList()
            setData(req)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) {
        return <Preloader type={'big'}/>
    }

    return <div className={s.wrap}>
        <Table
            gridTemplate={'40px 5fr 3fr 2fr 92px'}
            headsArray={['', 'НАЗВАНИЕ БЛОКА ИСТОРИЙ', 'КОЛИЧЕСТВО ИСТОРИЙ В БЛОКЕ', 'ТИП', 'ДЕЙСТВИЕ']}
            rowsArray={data.map((item) => <TableRow getData={getData} data={item} key={item.theme_id}/>)}
        />
    </div>

}

const DeleteModal = ({id, closeFunc, getData}) => {
    const deleteHandle = async () => {
        try {
            await deleteStory(id)
            await getData()
            closeFunc(false)

        } catch (e) {
            throw e
        }
    }

    return <ModalWindow title={"Удаление истории"}
                        closeFunc={closeFunc}
                        type={'small'}
                        primaryAction={async () => await deleteHandle()}
    >
        <p className={f.subtitle1Bold}>Вы действиетльно хотите удалить историю?</p>
    </ModalWindow>
}

export default StoryList
