import f from '../../../../../static/fonts.module.scss'
import Table from "../../../../_BASE/Layout/Table/Table";
import {useEffect, useState} from "react";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import CreateModal from "./CreateModal/CreateModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import Input from "../../../../_BASE/UI/Input/Input";

const Categories = ({categories, getCategories}) => {
    const [isLoading, setIsLoading] = useState(true);

    const [filteredCategories, setFilteredCategories] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const load = async () => {
            try {
                setIsLoading(true)
                await getCategories()
                setIsLoading(false)
            } catch (e) {
                console.log(e)
            }
        }

        load()
    }, [getCategories])

    useEffect(() => {
        if (filter.length > 0) {
            setFilteredCategories(categories.filter(category => category.title.toUpperCase().indexOf(filter.toUpperCase()) !== -1))
        } else {
            setFilteredCategories(categories)
        }
    }, [filter, categories])

    if (isLoading) return <Preloader type={'big'}/>

    return <div style={{
        display: 'flex',
        flexDirection: "column",
        gap: 16
    }}>
        <Input value={filter} setValue={setFilter} styles={{maxWidth:"100%"}}/>
        <Table
            headsArray={['Название', "Действие"]}
            gridTemplate={'1fr 92px'}
            rowsArray={filteredCategories.map(category => <SingleRow
                key={category.id}
                rowData={category}
                updateData={getCategories}
            />)}
        />
    </div>
}

const SingleRow = ({rowData, updateData}) => {
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const actions = [
        {
            label: 'Изменить',
            func: () => setEditModal(true)
        },
        {
            label: 'Удалить',
            func: () => setDeleteModal(true)
        }
    ]
    return <>
        <p className={f.subtitle1Bold}>{rowData.title}</p>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
        {
            editModal && <CreateModal updateData={updateData}
                                      closeFunc={setEditModal}
                                      data={rowData}/>
        }
        {
            deleteModal && <DeleteModal closeFunc={setDeleteModal}
                                        updateData={updateData}
                                        category={rowData}/>
        }
    </>
}

export default Categories