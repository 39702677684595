import s from './Stats.module.scss'
import f from '../../../../static/fonts.module.scss'

import OrdersChart from "../OrdersChart/OrdersChart";
import TabsSelector from "../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import Graph from "./Graph/Graph";

import {useState} from "react";
import PopularCategories from "./PopularCategories/PopularCategories";
import ExportModal from "../../Stats/ExportModal/ExportModal";
import Tooltip from "../../../_BASE/UI/Tooltip/Tooltip";


const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const text = "На данном графике показаны доходы, которые уже фактически получены, то есть с заказов, у которых истек гарантийный период"
const Stats = () => {
    const [currentPeriod, setCurrentPeriod] = useState(periods[0]);
    const [sum, setSum] = useState(0);


    return <div className={s.wrap}>
        <div className={s.big}>
            <div className={s.finTop}>
                <div className={s.line}>
                    <h4 className={f.boldH4}>Финансы</h4>
                    <ExportModal title={'Финансы'} api={'/payment/s2'} params={{}}/>
                    <Tooltip text={text} title={"hi"}/>
                </div>
                <div className={s.col}>
                    <p className={f.boldH4} style={{marginBottom: 4}}>{sum} ₽</p>
                    <p className={f.body1}>Заработано {currentPeriod.text.toLowerCase()}</p>
                </div>
            </div>
            <div className={s.chartsWrap}>
                <div className={s.col}>
                    <TabsSelector tabs={periods} newTab={currentPeriod} setNewTab={setCurrentPeriod}/>
                    <Graph period={currentPeriod.code} sumSetter={setSum}/>
                </div>
                <PopularCategories period={currentPeriod.code}/>
            </div>
        </div>
        <OrdersChart period={currentPeriod.code}/>
    </div>
}

export default Stats
