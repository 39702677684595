import s from './Orders.module.scss'
import Cards from "./Cards/Cards";
import SearchTable from "./SearchTable/SearchTable";
import NotFoundTable from "./NotFoundTable/NotFoundTable";
import NotFoundGraph from "./NotFoundGraph/NotFoundGraph";
import OrdersCountGraph from "./OrdersCount/OrdersCountGraph";


const Orders = () => {

    return <div className={s.wrap}>
        <Cards/>
        <OrdersCountGraph/>
        <SearchTable/>
        <NotFoundGraph/>
        <NotFoundTable/>
    </div>
}

export default Orders