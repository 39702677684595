import OperationInfo from "../../../components/_PAGES/Payments/OperationInfo/OperationInfo";
import TopNavbarTitle from "../../../store/TopNavbarTitle";
import {useParams} from "react-router-dom";
import TitleWithBack from "../../../components/TopNavbar/TitleWithBack/TitleWithBack";


const OperationInfoPage = () => {
    const {id} = useParams()

    TopNavbarTitle.setNewTitle(<TitleWithBack title={`Информация об операции №${id}`}/>)

    return <OperationInfo/>
}

export default OperationInfoPage