import $auth from "../http";
import {notifications} from "../index";

//Загрузка изображения
export const uploadImage = async (file, type = 'other') => {
    try {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('yc_folder_id', type)
        formData.append('userId', 2)
        const res = await $auth.put('/v1/files/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return {fileId: res.data.fileId, fileLink: res.data.fileLink}
    } catch (e) {
        console.log({e})
    }
}


//Запрос на удаление файла
export const deleteFile = async (url) => {
    try {
        await $auth.delete(`/v1/files/?type=LINK&message=${url}`)
        console.log(url, "DELETED")
    } catch (e){
        throw e
    }
}

//Запрос на получение списка документов
export const getSystemDocuments = async () =>{
    try {
        const req = await $auth.get(`/v1/files/documents/get`)
        return req.data
    } catch (e){
        console.log(e)
    }
}


//Запрос на создание нового документа
export const createSystemDocument = async (name, url) =>{
    try {
        const req = await $auth.post(`/v1/files/documents/create`, {
            title: name,
            link: url,
        })
        notifications.addNotification(req.data, 'OK')
        return req.data
    } catch (e){
        console.log(e)
    }
}

//Запрос на удаление нового документа
export const deleteSystemDocument = async (id) =>{
    try {
        const req = await $auth.delete(`/v1/files/documents/delete?id=${id}`, )
        notifications.addNotification(req.data, 'OK')
        return req.data
    } catch (e){
        console.log(e)
    }
}

//Запрос на получение списка важных  документов
export const getStaticDocuments = async () =>{
    try {
        const req = await $auth.get(`/v1/files/documents/get/code/all`)
        return req.data
    } catch (e){
        console.log(e)
        throw e
    }
}


//Запрос на получение списка важных  документов
export const getStaticDocument = async (code) =>{
    try {
        const req = await $auth.get(`/v1/files/documents/get/code?code=${code}`)
        return req.data
    } catch (e){
        console.log(e)
        throw e
    }
}

//Редактирование важных  документов
export const editStaticDocument = async (data) =>{
    try {
        const req = await $auth.post(`/v1/files/documents/update/code`, data)
        notifications.addNotification(req.data.message, 'OK')
    } catch (e){
        console.log(e)
        throw e
    }
}

