import s from './OptionsModal.module.scss'
import ModalWindow from "../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../_BASE/UI/Input/Input";
import {useEffect, useState} from "react";
import {createOption, updateOptionTitle} from "../../../../../services/CompetencyService";
import {notifications} from "../../../../../index";
import {CategoriesStore} from "../../CategoriesPage";

const OptionsModal = ({closeFunc, data = null}) => {
    const [optionName, setOptionName] = useState('')
    const Store = CategoriesStore

    useEffect(() => {
        if (data) setOptionName(data.title)
    }, [data])

    const handleActionClick = async () => {
        //Редактируем
        if (data) {
            if (data.title !== optionName) {
                const res = await updateOptionTitle(
                    data.id,
                    optionName,
                    Store.type === 'SCHOOL' ? 'CLASS' : 'TASK_TYPE'
                )
                if (res.status === 200) {
                    notifications.addNotification('Название успешно изменено', 'OK')
                    await Store.getOptions()
                    closeFunc(false)
                }
            }
        }
        //Создаем
        else {
            try {
                await createOption(
                    optionName,
                    Store.type === 'SCHOOL' ? 'CLASS' : 'TASK_TYPE'
                )
                notifications.addNotification('Тип заданий успешно создан', 'OK')
                await Store.getOptions()
                closeFunc(false)
            } catch (e)
            {
                console.log(e)
                throw e
            }
        }
    }

    return <ModalWindow
        closeFunc={closeFunc}
        title={
            data
                ? 'Редактирование типа заданий'
                : 'Создание типа заданий'
        }
        primaryButtonTitle={data ? 'Сохранить' : 'Создать'}
        primaryButtonIsDisabled={optionName.length < 2}
        primaryAction={handleActionClick}
        type={'small'}
    >
        <div className={s.wrap}>
            <Input
                title={'Название типа заданий'}
                value={optionName}
                setValue={setOptionName}
                styles={{maxWidth: '100%'}}
            />
        </div>

    </ModalWindow>
}

export default OptionsModal
