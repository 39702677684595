import f from '../../../static/fonts.module.scss'
import s from './Notifications.module.scss'

import {useState, useEffect} from "react";
import {getNotifications} from "../../../services/NotificationService";

const Notifications = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await getNotifications()

                setData(res)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [])

    const NotificationRow = ({data}) => {
        const date = new Date(Number(data.timestamp)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })

        return <div className={s.rowWrap}>
            <div className={s.rowInfo}>
                <p className={s.rowTitle}>{data.message}</p>
                <p className={`${f.caption} ${s.rowCaption}`}>{date}</p>
            </div>
            <div>
                <img src={data.iconLink} alt={data.title}/>
            </div>
        </div>
    }

    return <div className={s.wrap}>
        <p className={f.subtitle1Bold}>Уведомления</p>
        <div className={s.container}>
            {
                data.map((item) => <NotificationRow key={item.id} data={item}/>)
            }
        </div>
    </div>
}

export default Notifications