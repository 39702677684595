import s from './Graphs.module.scss'
import f from '../../../../../static/fonts.module.scss'
import {useEffect, useState} from "react";
import {getCompetencyProfilesCount, getRegisteredUsers} from "../../../../../services/StatisticService";
import {formatDataForNewUsersChart} from "../../../../../static/utilities";

import TabsSelector from "../../../../_BASE/Layout/TabsTable/TabsSelector/TabsSelector";
import DropdownButton from "../../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import RadialChart from "../../../Dashboard/RadialChart/Charts";
import CustomAreaChart from "../../../../_BASE/Charts/CustomAreaChart/AreaChart";
import ExportModal from "../../ExportModal/ExportModal";
import Tooltip from "../../../../_BASE/UI/Tooltip/Tooltip";

const periods = [
    {
        text: "На этой неделе",
        code: "WEEK"
    },
    {
        text: "В этом месяце",
        code: "MONTH"
    },
    {
        text: "В этом году",
        code: "YEAR"
    },
]

const types = [
    {
        text: 'Ученики',
        code: 'STUDENT'
    }, {
        text: 'Учителя',
        code: 'TEACHER'
    }
]

const Graphs = () => {

    return <div className={s.wrap}>
        <AreaChart/>
        <UsersRadialChart/>
    </div>
}


const text = 'Счетчик в заголовке блока показывает общее количество пользователей(то есть, даже с учетом тех, кто не создал профиль компетенций'



const AreaChart = () => {
    const [totalCount, setTotalCount] = useState(0);
    const [activePeriod, setActivePeriod] = useState(periods[0]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentType, setCurrentType] = useState(types[0]);
    const [data, setData] = useState([]);

    const actions = types.map((type) => {
        return {
            label: type.text,
            func: () => setCurrentType(type)
        }
    })


    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getRegisteredUsers(activePeriod.code, currentType.code)
                const totalSum = req.reduce((sum, current) => sum + current[1].all_users, 0)
                setData(formatDataForNewUsersChart(req, activePeriod.code))
                setTotalCount(totalSum)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }
        getData()
    }, [activePeriod.code, currentType.code])

    return <div className={s.areaChartWrap}>
        <div className={s.headline}>
            <div className={s.row}>
                <div className={s.line}>
                    <h4 className={f.boldH4}>Новых пользователей</h4>

                    <ExportModal api={'/auth/s1'}
                                 params={{
                                     userType: currentType.code
                                 }}
                                 title={`Новые пользователи (${currentType.text})`}
                    />
                </div>
                <div className={s.headerWrapper}>
                    <Tooltip text={text}/>
                    <h4 className={f.boldH4}>{totalCount}</h4>
                </div>
            </div>
            <div className={s.row}>
                <TabsSelector tabs={periods} setNewTab={setActivePeriod} newTab={activePeriod}/>
                <div className={s.type}>
                    <p className={f.subtitle1Bold}>{currentType.text}</p>
                    <DropdownButton actions={actions} type={'icon'}/>
                </div>
            </div>
        </div>
        <CustomAreaChart data={data} height={350} isLoading={isLoading}
                         dataAreasNames={['school', 'university']}/>
    </div>
}

const formatData = (data) => {
    return [
        {
            name: 'Школьники',
            value: data?.student?.school
        }, {
            name: 'Студенты',
            value: data?.student?.university
        },
        {
            name: 'Школьные преподаватели',
            value: data?.teacher?.school
        }, {
            name: 'Преподователи ВУЗа',
            value: data?.teacher?.university
        },
    ]
}

const UsersRadialChart = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getCompetencyProfilesCount('ALL_USERS')
                setData(formatData(req))
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        getData()
    }, [])

    return <div className={s.radialChartWrap}>
        <h4 className={f.boldH4}>Пользователи</h4>
        <RadialChart data={data} isLoading={isLoading}/>
    </div>
}

export default Graphs
