import {makeAutoObservable} from "mobx";
import {getUserInfo, updateUserAuthProfile} from "../services/AuthService";


export default class AdminStore {
    competencyProfile = {}
    authProfile = {}
    userId
    teacherStatus = null
    isLoaded = true
    statusIsChecking = false

    constructor() {
        makeAutoObservable(this)
    }

    setCompetencyProfile(data) {
        this.competencyProfile = data
    }

    setUserId(id) {
        this.userId = id
    }

    setAuthProfile(data) {
        this.authProfile = data
    }

    setTeacherStatus(bool) {
        this.teacherStatus = bool
    }

    setIsLoaded(bool) {
        this.isLoaded = bool
    }

    setStatusIsChecking(bool) {
        this.statusIsChecking = bool
    }

    resetValues() {
        this.competencyProfile = {}
        this.authProfile = {}
        this.userId = null
        this.teacherStatus = null
    }


    async getUserAuthProfile() {
        this.setIsLoaded(false)
        const profile = await getUserInfo(this.userId)
        this.setAuthProfile(profile.data.user)
        this.setIsLoaded(true)
    }

    async updateUserAuthProfile(type, data) {
        this.setIsLoaded(false)
        await updateUserAuthProfile(type, data, this.userId)
        await this.getUserAuthProfile()
        this.setIsLoaded(true)
    }

}