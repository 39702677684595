import f from '../../../../static/fonts.module.scss'

import {useEffect, useState} from "react";
import Pagination from "../../../_BASE/UI/Pagination/Pagination";
import {getTeachers, getTeachersCount} from "../../../../services/CompetencyService";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import Table from "../../../_BASE/Layout/Table/Table";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";

const InactiveList = () => {
    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [countIsLoaded, setCountIsLoaded] = useState(false);

    useEffect(() => {
        const getCount = async () => {
            try {
                const req = await getTeachersCount('FALSE')
                setTotal(req.data.userCount)
                setCountIsLoaded(true)
            } catch (e) {
                throw e
            }
        }

        getCount()
    }, [])

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getTeachers(paginationObject.page, paginationObject.offset, 'FALSE')
                setData(req)
                setIsLoading(false)
            } catch (e) {
                throw e
            }
        }

        if (countIsLoaded) {
            getData()
        }
    }, [paginationObject.page, paginationObject.offset, countIsLoaded])


    if (isLoading) return <Preloader/>

    return <>
        <Table headsArray={['ID', "Имя", "Должность", "Действие"]}
               rowsArray={data.map((data) => <TableRow data={data} key={data.userId}/>)}
               gridTemplate="100px 300px 1fr 92px"
        />
        <Pagination
            paginationObject={paginationObject}
            setPaginationObject={setPaginationObject}
            totalCount={total}
        />
    </>
}

const TableRow = ({data}) => {
    const actions = [
        {
            label: 'Просмотр',
            type: 'link',
            link: `${data.userId}`
        },
    ]

    return <>
        <p className={f.subtitle1Bold}>{data.userId}</p>
        <p className={f.subtitle1Bold}>{data.name} {data.middlename} {data.surname} </p>
        <p className={f.subtitle1Bold}>{data.positionTitle}</p>
        <div style={{justifyContent: 'center', width: "100%", display: "flex"}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
    </>
}

export default InactiveList