import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import HelpdeskStats from "../../components/_PAGES/Stats/Helpdesk/HelpdeskStats";


const HelpdeskStatsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Статистика по технической поддержке')
    }, [])

    return <HelpdeskStats/>
}

export default HelpdeskStatsPage