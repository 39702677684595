import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import Users from "../../components/_PAGES/Stats/Users/Users";


const UsersStatsPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Статистика по пользователям')
    }, [])

    return <Users/>
}

export default UsersStatsPage