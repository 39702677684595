import s from './CreateModal.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import Input from "../../../../../_BASE/UI/Input/Input";
import {useEffect, useState} from "react";
import {createTicketCategory, editTicketCategory} from "../../../../../../services/SupportService";

const CreateModal = ({closeFunc, updateData, data}) => {
    const [name, setName] = useState('');

    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        if (data) {
            setName(data.title)
        }
    }, [data])

    const createCategory = async () => {
        try {
            setIsCreating(true)
            await createTicketCategory({
                title: name,
                status: "ON"
            })
            await updateData()
            setIsCreating(false)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    const editCategory = async () => {
        try {
            setIsCreating(true)
            await editTicketCategory(data.id, {
                title: name,
                status: "ON"
            })
            await updateData()
            setIsCreating(false)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    return <ModalWindow
        title={data
            ? "Изменение категории"
            : 'Создание новой категории'}
        closeFunc={closeFunc}
        type={'small'}
        primaryButtonTitle={data
            ? "Изменить" : 'Создать'}
        primaryButtonIsDisabled={isCreating || name.length === 0}
        primaryAction={data ? editCategory : createCategory}
    >
        <div className={s.wrap}>
            <Input value={name} setValue={setName}
                   title={'Название категории'}
                   styles={{maxWidth: "100%"}}
            />

        </div>
    </ModalWindow>
}

export default CreateModal