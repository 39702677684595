import {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import BonusRequests from "../../components/_PAGES/Payments/Bonuses/BonusRequests";


const BonusRequestPage = () => {
    useEffect(() => {
        TopNavbarTitle.setNewTitle('Работа с бонусами')
    }, [])

    return <BonusRequests/>
}

export default BonusRequestPage