import s from './CreateModal.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useEffect, useState} from "react";
import Preloader from "../../../../../_BASE/UI/Preloader/Preloader";
import {createTicketRelation, getTicketCategories} from "../../../../../../services/SupportService";
import Select from "../../../../../_BASE/UI/Select/Select";


const CreateModal = ({section, updateData, closeFunc}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [availableCategories, setAvailableCategories] = useState([]);
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async () => {
        try {
            setIsLoading(true)
            const categories = await getTicketCategories()
            setAvailableCategories(categories)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    const createUnion = async () => {
        try {
            setIsCreating(true)
            const selectedCategory = availableCategories.filter(option => selectedOption === option.title)[0]
            await createTicketRelation({
                categoryId: selectedCategory.id,
                sectionId: section.id,
                status: "ON"
            })
            await updateData(section.id)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsCreating(false)
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        title={"Создание новой связи"}
                        type={'small'}
                        primaryAction={() => createUnion()}
                        primaryButtonTitle={'Создать'}
                        primaryButtonIsDisabled={!selectedOption || isCreating}
    >
        <div className={s.wrap}>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <Select options={availableCategories.map(option => option.title)}
                              selectedOption={selectedOption}
                              setSelectedOption={setSelectedOption}
                              title={'Категория связи'}
                    />

            }
        </div>
    </ModalWindow>
}

export default CreateModal