import s from './OrderInfo.module.scss'

import Tabs from "../../../../_BASE/Layout/TabsTable/Tabs";
import OrderDetails from "../../../OrderInfo/OrderInfo/OrderDetails/OrderDetails";
import ChatView from "../../../OrderInfo/OrderInfo/ChatView/ChatView";
import {useEffect, useState} from "react";
import Preloader from "../../../../_BASE/UI/Preloader/Preloader";
import {getOrder} from "../../../../../services/OrdersService";
import UserCard from "../../../OrderInfo/UserCard/UserCard";
import ErrorBoundary from "../../../../ErrorBoundary/ErrorBoundary";
import EmptyList from "../../../../_BASE/Layout/Table/EmptyList/EmptyList";


const OrderInfo = ({orderId}) => {
    const [activeTab, setActiveTab] = useState('Информация о заказе');

    const tabs = [
        {
            label: "Информация о заказе",
            content: <Info orderId={orderId}/>
        },
        {
            label: "Чат",
            content: <ChatView orderId={orderId}/>
        },
    ]

    return <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}/>
}

const Info = ({orderId}) => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getOrder(orderId)
                setData(req)
            } catch (e) {
                console.log(e)
                setNotFound(true)
            } finally {
                setIsLoading(false)
            }
        }
        getData()
    }, [orderId])

    if (notFound) return <EmptyList title={'Заказ не найден'}/>

    if (isLoading) return <Preloader type={'big'}/>

    return <div className={s.infoWrap}>
        <ErrorBoundary>
            <div className={s.users}>
                <UserCard userId={data?.studentInfo?.userId}
                          style={{background: "#E6F1FF"}}/>
                <UserCard userId={data?.teacherInfo?.userId}/>
            </div>
        </ErrorBoundary>
        <OrderDetails orderId={orderId} data={data} editMode={false}/>
    </div>
}
export default OrderInfo