import React, {useEffect} from "react";
import TopNavbarTitle from "../../store/TopNavbarTitle";
import OrderList from "../../components/_PAGES/OrderList/OrderList";

const Orders = () => {
    useEffect(()=>{
        TopNavbarTitle.setNewTitle('Заказы')
    }, [])

    return(
        <OrderList/>
    )
}

export default Orders