import {useEffect, useState} from "react";

import style from './Input.module.scss'
import fonts from '../../../../static/fonts.module.scss'
import validate from "./validations";


const Input = ({
                   placeholder, // Плейсхолдер инпута
                   icon, // Иконка в правой части инпута
                   title, // Подпись сверху
                   value, // state для value
                   setValue, // сеттер для value
                   styles, // объект стилей
                   type = 'text', // выбор между textarea и default=text
                   step, //Шаг для типа number
                   validator,
                   setIsValid,
                   password, // Выставляет type=password
                   maxLength, // Максимальная длинна инпута
                   minHeight, // Минимаотная высота для textarea
                   disabled,
                   min, max,//ограничения для number и date
               }) => {

    const handleChange = (event) => {
        if (!maxLength) {
            setValue(event.target.value)
        } else {
            if (event.target.value.length <= maxLength) {
                setValue(event.target.value)
            }
        }
    }

    const resetToDefaultDate = () =>{
        const maxDate = new Date(max)
        const minDate = new Date(min)

        const chosenDate = new Date(value)
        if(chosenDate > maxDate){
            setValue(max)
        }
        if( chosenDate < minDate){
            setValue(min)
        }
    }

    const [isDirty, setIsDirty] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (validator) {
            const validationRes = validate(validator, value)
            if (!validationRes) {
                setIsValid(true)
                setErrorMessage('')
            } else {
                setIsValid(false)
                setErrorMessage(validationRes)
            }
        }
    }, [value, setIsValid, validator])

    const input = () => {
        switch (type) {
            case 'text':
                return (
                    <input className={fonts.body1}
                           disabled={disabled}
                           type={password ? 'password' : 'text'}
                           value={value ? value : ''}
                           placeholder={placeholder ? placeholder : 'Введите что-нибудь'}
                           onChange={handleChange}
                           onBlur={() => setIsDirty(true)}
                    />
                )
            case 'textarea':
                return (
                    <textarea className={fonts.body1}
                              disabled={disabled}
                              value={value ? value : ''}
                              placeholder={placeholder ? placeholder : 'Введите что-нибудь'}
                              onChange={handleChange}
                              style={{minHeight: minHeight ? minHeight : 300, padding: '16px'}}
                              onBlur={() => setIsDirty(true)}
                    />
                )
            case 'date':
                return (
                    <input className={fonts.body1}
                           type='date'
                           disabled={disabled}
                           value={value ? value : ''}
                           onChange={handleChange}
                           onBlur={() => {
                               resetToDefaultDate()
                               setIsDirty(true)
                           }}
                           min={min}
                           max={max}
                    />
                )
            case 'number':
                return (
                    <input className={fonts.body1}
                           type={'number'}
                           disabled={disabled}
                           step={step}
                           value={value ? value : ''}
                           onChange={handleChange}
                           onBlur={() => setIsDirty(true)}
                           placeholder={placeholder}
                           min={min}
                           max={max}
                    />
                )
            default:
                return null
        }
    }

    return (
        <label style={styles && styles} className={`${style.block}`}>
            {
                title && <p className={`${fonts.boldH6} ${style.title}`}>{title}</p>
            }
            <div className={style.wrap}>
                {
                    input()
                }
                {
                    icon && <div className={style.icon}>{icon}</div>
                }
            </div>
            {isDirty && errorMessage && <p className={fonts.subtitle2Bold} style={{color: 'red', marginTop: 4}}>
                {
                    errorMessage
                }
            </p>}
        </label>
    );
};

export default Input
