import s from './StatCards.module.scss'


import {ReactComponent as OrdersIcon} from "./Icon/Buy.svg";
import {ReactComponent as UsersIcon} from "./Icon/Add User.svg";
import {ReactComponent as IncomeIcon} from "./Icon/Graph.svg";
import {ReactComponent as ActiveIcon} from "./Icon/Chart.svg";

import SingleCard from "./SingleCard/SingleCard";
import {getActiveTeacher, getIncomeStats, getOrdersCount, getRegisteredUsers} from "../../../../services/StatisticService";


const formatOrdersCount = async (period) => {
    try {
        const counter = await getOrdersCount(period, 'ORDER_IN_PROGRESS')
        return typeof counter === 'number' ? counter : counter.reduce((sum, curr) => sum + curr[1], 0)
    } catch (e) {
        throw e
    }
}

const formatRegisteredUsers = async (period) => {
    try {
        const teacherCounter = await getRegisteredUsers(period, 'TEACHER')
        const studentCounter = await getRegisteredUsers(period, 'STUDENT')
        if (period === 'DAY') {
            return teacherCounter + studentCounter
        } else {
            const sum = (arr) => arr.reduce((total, current) => total + current[1].all_users, 0)
            return sum(teacherCounter) + sum(studentCounter)
        }
    } catch (e) {
        throw e
    }
}


const formatIncome = async (period) => {
    try {
        const counter = await getIncomeStats(period)
        return !Array.isArray(counter) ? Math.round(counter) + " ₽" :
            counter
                ? counter.reduce((sum, curr) => sum + +curr[1], 0) + " ₽"
                : 0
    } catch (e) {
        throw e
    }
}

const formatActiveTeacher = async (type) => {
    try {
        return await getActiveTeacher(type)
    } catch (e) {
        throw e
    }
}

const periods = [
    {
        text: 'Сегодня',
        code: 'DAY'
    },
    {
        text: 'За неделю',
        code: 'WEEK'
    },
    {
        text: 'За месяц',
        code: 'MONTH'
    },
    {
        text: 'За год',
        code: 'YEAR'
    },
]

const types = [
    {
        text: 'Все учителя',
        code: 'ALL'
    },
    {
        text: 'Школьные учителя',
        code: 'SCHOOL'
    },
    {
        text: 'Преподаватели ВУЗов',
        code: 'VUZ'
    },
]

const StatCards = () => {
    return <div className={s.grid}>
        <SingleCard title={'Заказы в работе'} icon={<OrdersIcon/>} theme={"blue"}
                    getDataApi={(period) => formatOrdersCount(period)}
                    periods={[
                        {
                            text: 'На даннный момент',
                            code: 'ALL_TIME'
                        }
                    ]}
        />
        <SingleCard title={'Новые пользователи'} icon={<UsersIcon/>}
                    getDataApi={async (period) => formatRegisteredUsers(period)}
                    periods={periods}
        />
        <SingleCard title={'Заработок'} icon={<IncomeIcon/>} theme={"blue"}
                    getDataApi={(period) => formatIncome(period)}
                    periods={periods}
        />
        <SingleCard title={'Активные учителя'} icon={<ActiveIcon/>}
                    getDataApi={(type) => formatActiveTeacher(type)} periods={types}/>
    </div>
}

export default StatCards