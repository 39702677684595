import s from './Tickets.Module.scss'
import f from '../../../static/fonts.module.scss'

import {useCallback, useEffect, useState} from "react";

import Table from "../../_BASE/Layout/Table/Table";
import Input from "../../_BASE/UI/Input/Input";
import DropdownButton from "../../_BASE/UI/Button/DropdownButton/DropdownButton";
import {
    getAgents,
    getSupportTickets,
    getSupportTicketsCount,
    searchTickets,
    transferTicket
} from "../../../services/SupportService";
import {getColor} from "../UserInfo/SupportTable/SupportTable";
import Pagination from "../../_BASE/UI/Pagination/Pagination";
import Preloader from "../../_BASE/UI/Preloader/Preloader";
import {notifications} from "../../../index";
import ModalWindow from "../../_BASE/Layout/ModalWindow/ModalWindow";
import Radios from "../../_BASE/UI/Radios/Radios";
import Button from "../../_BASE/UI/Button/Button";
import TicketModal from "../TicketModal/TicketModal";

const Tickets = () => {
    const [searchbarValue, setSearchbarValue] = useState('');
    const [searchDateFrom, setSearchDateFrom] = useState('');
    const [searchDateTo, setSearchDateTo] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);

    const [paginationObject, setPaginationObject] = useState({
        page: 1,
        offset: 20
    });

    const [countIsLoaded, setCountIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    const tableHeads = [
        "АГЕНТ ТП",
        "АВТОР ОБРАЩЕНИЯ",
        "КАТЕГОРИЯ",
        "ТЕМА",
        "ДАТА СОЗДАНИЯ",
        "СТАТУС",
        "ДЕЙСТВИЕ",
    ]


    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const res = await getSupportTickets(paginationObject.page, paginationObject.offset)
            setData(res)
            setIsLoading(false)
        } catch (e) {
            throw e
        }
    }, [paginationObject.page, paginationObject.offset])

    const getCount = async () => {
        try {
            setCountIsLoaded(false)
            const res = await getSupportTicketsCount()
            setTotal(res)
        } catch (e) {
            throw e
        } finally {
            setCountIsLoaded(true)
        }
    }

    const searchById = async (type) => {
        try {
            setIsLoading(true)
            const filteredData = await searchTickets(searchbarValue, type)
            setData(filteredData)

        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    }

    const searchByDate = async () => {
        try {
            if (searchDateFrom && searchDateTo) {
                setIsLoading(true)
                const filteredData = await searchTickets(`${searchDateFrom}/${searchDateTo}`, 'DATE')
                setData(filteredData)
            } else {
                notifications.addNotification('Укажите полный диапазон')
            }
        } catch (e) {
            throw e
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getCount()
    }, [])

    useEffect(() => {
        if (countIsLoaded) {
            getData()
        }
    }, [countIsLoaded, paginationObject.page, paginationObject.offset, getData])


    if (isLoading) return <Preloader type={'big'}/>

    const searchbarActions = [
        {
            label: 'По ID Агента',
            func: () => searchById('AGENT_ID')
        },
        {
            label: 'По ID Пользователя',
            func: () => searchById("USER_ID")
        },
        {
            label: 'По ФИО Агента',
            func: () => searchById("AGENT_FIO")
        },
        {
            label: 'По ФИО Автора',
            func: () => searchById("USER_FIO")
        }
    ]

    return <div className={s.wrap}>
        <div className={s.searchBar}>
            {
                searchDateFrom?.length > 0 || searchDateTo?.length > 0
                    ? <div style={{width: "100%", alignItems: "center", display: "flex"}}>
                        <p className={f.subtitle1Bold}>Поиск по дате</p>
                    </div>
                    : <Input value={searchbarValue} setValue={setSearchbarValue}
                             disabled={searchDateFrom?.length > 0 || searchDateTo?.length > 0}
                             styles={{maxWidth: "100%"}} placeholder={'Начните вводить что-нибудь...'}/>
            }

            {
                searchbarValue?.length === 0 &&
                <div className={s.searchDates}>
                    <Input value={searchDateFrom} setValue={setSearchDateFrom}
                           disabled={searchbarValue?.length > 0}
                           type={'date'}/>
                    <span>—</span>
                    <Input value={searchDateTo} setValue={setSearchDateTo}
                           disabled={searchbarValue?.length > 0}
                           type={'date'}/>
                </div>
            }

            {
                <>
                    <div style={{marginRight: 24, marginLeft: 24}}>
                        {
                            searchbarValue?.length > 0
                                ? <DropdownButton text={'Поиск по'} actions={searchbarActions}
                                                  nested_styles={{height: 48}}/>
                                : <Button text={'Поиск'} click={() => searchByDate()}/>
                        }
                    </div>
                    <Button type={'alert'} text={'Сбросить'}
                            click={() => {
                                setSearchDateFrom("")
                                setSearchDateTo("")
                                setSearchbarValue('')
                                getData()
                            }
                            }
                    />
                </>
            }
        </div>

        <Table gridTemplate={'4fr 4fr 3fr 5fr 4fr 3fr 92px'}
               headsArray={tableHeads}
               rowsArray={data.map(rowData => <TableRow getData={getData} rowData={rowData}/>)}/>
        {
            !(
                searchbarValue?.length > 0
                || searchDateFrom?.length > 0
                || searchDateTo?.length > 0
            )
            && <Pagination
                paginationObject={paginationObject}
                setPaginationObject={setPaginationObject}
                totalCount={total}
            />
        }
    </div>
}

const EditModal = ({closeFunc, ticketId, getData}) => {
    const [agentFilterSearchbar, setAgentFilterSearchbar] = useState('');
    const [agentList, setAgentList] = useState([]);
    const [selectedId, setSelectedId] = useState();

    const getAgentList = async () => {
        try {
            const list = await getAgents()
            setAgentList(list)
        } catch (e) {
            throw e
        }
    }


    useEffect(() => {
        getAgentList()
    }, [])

    const eTableHeads = [
        "ID",
        "АГЕНТ ТП",
        <span style={{float: "right"}} className={f.subtitle2Bold}>ВЫБОР</span>,
    ]

    const confirmTransfer = async () => {
        if (!selectedId) {
            notifications.addNotification('Выберите нового агента')
            return
        }
        try {
            await transferTicket(ticketId, selectedId)
            await getData()
            closeFunc(false)

        } catch (e) {
            throw e
        }
    }

    return <ModalWindow
        title={'Передача обращения'}
        closeFunc={closeFunc}
    >
        <div className={s.editModalWrap}>
            <Input value={agentFilterSearchbar} setValue={setAgentFilterSearchbar}
                   placeholder={'Поиск'} styles={{maxWidth: '100%'}}/>
            <div className={s.agentList}>
                <Table headsArray={eTableHeads}
                       gridTemplate={'100px 1fr 100px'}
                       rowsArray={
                           agentFilterSearchbar?.length > 0
                               ? agentList.filter((item) =>
                                   item?.name?.indexOf(agentFilterSearchbar) !== -1
                               ).map((row) => <AgentListRow agentRowData={row}/>)
                               : agentList.map((row) => <AgentListRow agentRowData={row} selectedId={selectedId}
                                                                      setSelectedId={setSelectedId}/>)
                       }
                       headerStyle={{padding: 16}}
                />
            </div>
            <div className={s.editButtons}>
                <Button text={'Отмена'} type={'alert'} click={() => closeFunc(false)}/>
                <Button text={'Подтвердить'} click={() => confirmTransfer()}/>
            </div>
        </div>
    </ModalWindow>
}

const TableRow = ({rowData, getData}) => {
    const [editModalIsVisible, setEditModalIsVisible] = useState(false);
    const [ticketModal, setTicketModal] = useState(false);
    const rowActionsInWork = [
        {
            label: 'Передать',
            func: () => setEditModalIsVisible(true)
        },
        {
            label: 'Просмотр',
            func: () => setTicketModal(true)
        }
    ]

    const rowActions = [
        {
            label: 'Просмотр',
            func: () => setTicketModal(true)
        }
    ]

    return <>
        <p className={f.subtitle1Bold}>{rowData.aName} {rowData.aSurname} {rowData.aMiddlename}</p>
        <p className={f.subtitle1Bold}>{rowData.name} {rowData.surname} {rowData.middlename}</p>
        <div className={s.tableTags}>
            <p className={`${f.body2} ${s.tag}`}>{rowData.sTitle}</p>
            <p className={`${f.body2} ${s.tag}`}>{rowData.cTitle}</p>
        </div>
        <p className={f.body2}>{rowData.question}</p>
        <p className={f.body2}>{new Date(Number(rowData.createDate)).toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        })}</p>
        <p className={`${f.subtitle2Bold} ${getColor(rowData.tsTitle)}`}>{rowData.tsTitle}</p>
        <div style={{display: "flex", justifyContent: "center"}}>
            <DropdownButton type={'icon'}
                            actions={rowData.tsCode === 'TICKET_IN_WORK' ? rowActionsInWork : rowActions}/>
        </div>
        {
            editModalIsVisible && <EditModal ticketId={rowData.id} closeFunc={setEditModalIsVisible} getData={getData}/>
        }
        {
            ticketModal && <TicketModal closeFunc={setTicketModal} id={rowData.id}/>
        }
    </>

}

const AgentListRow = ({agentRowData, selectedId, setSelectedId}) => {
    return <>
        <p className={f.subtitle1Bold}>{agentRowData.id}</p>
        <p className={f.subtitle1Bold}>
            {agentRowData.name} {agentRowData.middlename} {agentRowData.surname}
        </p>
        <div style={{display: "flex", justifyContent: "flex-end", marginRight: -24}}>
            <Radios
                checkedValue={selectedId}
                setCheckedValue={setSelectedId}
                size={'small'}
                groupName={'agentSelect'}
                data={[
                    {
                        value: agentRowData.id,
                        title: ''
                    }
                ]}
            />
        </div>
    </>
}

export default Tickets