import f from '../../../../static/fonts.module.scss'
import s from './ChartHelper.module.scss'
import React from "react";

export const formatYAxis = (tickItem) => {
    return tickItem / 1000 + 'K';
}

export const CustomTooltip = ({active, payload, label, unit}) => {

    if (active && payload && payload.length) {
        return <p className={`${f.body1} ${s.tooltip}`}>
            {payload[0].payload.label
                ? payload[0].payload.label
                : payload[0].payload.name
                    ? payload[0].payload.name
                    : label
            } —
            <span className={` ${f.subtitle1Bold}`}> {`${payload[0].value}`} {unit}</span>
        </p>
    }
    return null;
};