import style from './NavSingleLink.module.scss'
import fonts from './../../../../static/fonts.module.scss'

import {
    Link,
    useMatch,
} from "react-router-dom";
import {useEffect, useState} from "react";

function NavSingleLink({link, IconSVG, title, id, onClick, menuIsHidden}) {

    let match = useMatch({path: link, end: true});

    const [renderedTitle, setRenderedTitle] = useState(
        <p className={`${fonts.button} ${style.text} fadeIn`}>{title}</p>
    );

    useEffect(() => {
        if (!menuIsHidden) {
            setTimeout(() => {
                setRenderedTitle(
                    <p className={`${fonts.button} ${style.text} fadeIn`}>{title}</p>
                )
            }, 300)
        } else {
            setRenderedTitle(null)
        }
    }, [menuIsHidden, title])


    return (
        <Link onClick={onClick}
              className={`${style.wrapper} ${match && style.selected} ${menuIsHidden ? style.small : ''}`}
              id={id}
              to={link}
              title={title}
        >
            <div className={style.icon}>
                {IconSVG}
            </div>
            {
                renderedTitle
            }

        </Link>
    )
}

export default NavSingleLink