import s from './CreateModal.module.scss'
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useState} from "react";
import Input from "../../../_BASE/UI/Input/Input";
import FileInput from "../../../_BASE/UI/Input/FileInput/FileInput";
import {createSystemDocument, uploadImage} from "../../../../services/FileService";

const CreateModal = ({getData, closeFunc}) => {
    const create = async () => {
        try {
            const link = await uploadImage(fileObject)
            await createSystemDocument(name, link.fileLink)
            closeFunc(false)
            await getData()
        } catch (e){

        }
    }

    const [name, setName] = useState('');
    const [fileObject, setFileObject] = useState();
    const [fileUrl, setFileUrl] = useState();

    return <ModalWindow
        closeFunc={closeFunc}
        title={'Загрузка документа'}
        primaryAction={() => create()}
        primaryButtonTitle={"Создать"}
        type={'small'}
        primaryButtonIsDisabled={!(fileObject && name)}
    >
        <div className={s.wrap}>
            <Input title={'Название'}
                   placeholder={'Соглашение.pdf'}
                   value={name} setValue={setName}
                   styles={{maxWidth:"100%"}}
            />
            <FileInput types={['application/pdf']}
                       imageUrl={fileUrl} setImageUrl={setFileUrl}
                       imageObject={fileObject} setImageObject={setFileObject}
                       text={'Загрузите файл формата .pdf '}
            />
        </div>
    </ModalWindow>
}

export default CreateModal
