import Tabs from "../../../_BASE/Layout/TabsTable/Tabs";

import {useState} from "react";
import BonusRequestsTable from "./BonusRequestsTable/BonusRequestsTable";

const BonusRequests = ({userId}) => {
    const [activeTab, setActiveTab] = useState('Запросы на начисление бонусов от ТП');

    const tabs = [
        {
            label: "Запросы на начисление бонусов от ТП",
            content: <BonusRequestsTable userId={userId} type={'TRUE'} key={'active'}/>
        },
        {
            label: "Закрытые обращения",
            content: <BonusRequestsTable userId={userId} type={'FALSE'} key={'closed'}/>
        }
    ]

    return <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs}/>
}

export default BonusRequests

