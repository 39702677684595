import s from './PaymentModal.module.scss'
import f from '../../../../../../static/fonts.module.scss'

import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {userStore} from "../../../UserInfo";
import React, {useCallback, useEffect, useState} from "react";

import Preloader from "../../../../../_BASE/UI/Preloader/Preloader";
import {
    getUserPaymentMethods,
    getUserPaymentMethodsFromBank,
    manualDeletePayment
} from "../../../../../../services/PaymentService";
import {deleteUserPaymentModal} from "../../../../../../services/PaymentService";

import {ReactComponent as DeleteIcon} from "./icons/delete.svg";
import {ReactComponent as OtherIcon} from "./icons/other.svg";
import {ReactComponent as MirIcon} from "./icons/mir.svg";
import {ReactComponent as VisaIcon} from "./icons/visa.svg";
import {ReactComponent as UnionIcon} from "./icons/union.svg";
import {ReactComponent as MCIcon} from "./icons/mastercard.svg";
import {ReactComponent as Info} from "./icons/Info.svg";
import EmptyList from "../../../../../_BASE/Layout/Table/EmptyList/EmptyList";
import Button from "../../../../../_BASE/UI/Button/Button";
import ReactJson from "react-json-view";
import {notifications} from "../../../../../../index";
import Table from "../../../../../_BASE/Layout/Table/Table";
import Input from "../../../../../_BASE/UI/Input/Input";


const PaymentModal = ({closeFunc}) => {
    const Store = userStore

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [paymentsInfoModal, setPaymentsInfoModal] = useState(false);
    const [deletePaymentMethodModal, setDeletePaymentMethodModal] = useState(false);

    const getData = useCallback(async () => {
        try {
            setIsLoading(true)
            const req = await getUserPaymentMethods(Store.userId)
            setData(req.methodList)
            setIsLoading(false)
        } catch (e) {
            console.log(e)
        }
    }, [Store.userId])


    useEffect(() => {
        getData()
    }, [getData])

    return <ModalWindow closeFunc={closeFunc}
                        title={'Способы оплаты'}
    >
        <div className={s.wrap}>
            {
                userStore.authProfile.userRoleTitleCode === 'TEACHER' &&
                <div className={s.buttons}>
                    <Button text={'Информация о методах (Тинькофф)'}
                            type={'secondary'} icon={<Info/>}
                            click={() => setPaymentsInfoModal(true)}
                    />
                    <Button text={'Удалить метод из системы (Тинькофф)'}
                            type={'alertBorder'}
                            click={() => setDeletePaymentMethodModal(true)}
                    />
                </div>
            }
            {
                paymentsInfoModal && <PaymentInfo closeFunc={setPaymentsInfoModal}/>
            }
            {
                deletePaymentMethodModal && <ManualDeleteModal closeFunc={setDeletePaymentMethodModal}/>
            }
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : data.length > 0
                        ? <Table
                            headsArray={["Название", "Card ID", "ID", "Статус", "Действие"]}
                            gridTemplate={"3fr 1fr 1fr 1fr 92px"}
                            rowsArray={data.map(card => <SingleCardRow
                                getData={getData}
                                rowData={card}
                                key={card.id}
                            />)}
                        />
                        : <EmptyList title={'Нет информации'}/>
            }
        </div>
    </ModalWindow>
}

const SingleCardRow = ({rowData, getData}) => {
    const [deleteModal, setDeleteModal] = useState(false);

    return <>
        <div className={s.iconRow}>
            <div className={s.cardIcon}>
                {getCardIcon(rowData.cardCode)}
            </div>
            <p className={f.body1}>{rowData.cardTitle} {rowData.pan} {rowData.isMainMethod &&
                <span>(текущий метод оплаты)</span>}</p></div>
        <p className={f.subtitle1Bold}>{rowData.cardId}</p>
        <p className={f.subtitle1Bold}>{rowData.id}</p>
        <div>
            <p className={`${f.subtitle2Bold} ${s.tag} ${rowData.isLifeStatus ? s.yellow : s.red}`}>
                {
                    rowData.isLifeStatus
                        ? "Активный"
                        : "Удален"
                }
            </p>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <button className={s.deleteButton}
                    title={'Удалить метод оплаты'}
                    onClick={() => setDeleteModal(true)}
            >
                <DeleteIcon/>
            </button>
        </div>
        {
            deleteModal && <DeleteModal
                getData={getData}
                closeFunc={setDeleteModal}
                data={rowData}
            />
        }

    </>
}


const getCardIcon = (code) => {
    switch (code) {
        case "MIR" :
            return <MirIcon/>
        case "MASTERCARD" :
            return <MCIcon/>
        case "UNIONPAY" :
            return <UnionIcon/>
        case "VISA" :
            return <VisaIcon/>
        default:
            return <OtherIcon/>
    }
}

const DeleteModal = ({closeFunc, getData, data}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const Store = userStore

    const deleteRequest = async () => {
        try {
            setIsDeleting(true)
            await deleteUserPaymentModal(data.id, Store.userId)
            await getData()
            setIsDeleting(false)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        }
    }

    return <ModalWindow
        closeFunc={closeFunc}
        type={'small'}
        title={'Удаление'}
        primaryAction={deleteRequest}
        primaryButtonIsDisabled={isDeleting}
        primaryButtonTitle={'Удалить'}
    >
        <p className={f.body1}>Вы действительно хотите удалить метод оплаты {data.cardTitle}?</p>
    </ModalWindow>
}

const PaymentInfo = ({closeFunc}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                setIsLoading(true)
                const req = await getUserPaymentMethodsFromBank({id: userStore.userId})
                setData(req)
                setIsLoading(false)
            } catch (e) {
                notifications.addNotification(e.response.data.message)
            }
        }

        getData()
    }, [])


    return <ModalWindow closeFunc={closeFunc} title={'Информация о методах'}>
        <div className={s.modalWrap}>
            {
                isLoading
                    ? <Preloader type={'big'}/>
                    : <ReactJson src={data}/>
            }
        </div>
    </ModalWindow>
}


const ManualDeleteModal = ({closeFunc}) => {
    const [id, setId] = useState();
    const [isSending, setIsSending] = useState(false);

    const manualDelete = async () => {
        try {
            setIsSending(true)
            await manualDeletePayment({
                userId: userStore.userId,
                cardId: id
            })
        } catch (e) {
            notifications.addNotification(e.response.data.message)
            console.log(e)
        } finally {
            setIsSending(false)
        }
    }

    return <ModalWindow closeFunc={closeFunc}
                        title={'Ручное удаление метода оплаты'}
                        type={'small'}
                        primaryAction={manualDelete}
                        primaryButtonTitle={'Удалить'}
                        primaryButtonIsDisabled={isSending}
    >
        <div className={s.deleteModal}>
            <p className={f.subtitle1Bold}>Для того, чтобы вручную удалить метод оплаты, введите Card ID, полученный из
                ответа банка.</p>
            <Input value={id} setValue={setId} title={'Card ID'}
                   styles={{maxWidth: "100%"}}
                   type={'number'}
            />
        </div>
    </ModalWindow>
}

export default PaymentModal