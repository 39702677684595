import s from './DeleteModal.module.scss'
import ModalWindow from "../../../../../_BASE/Layout/ModalWindow/ModalWindow";
import {useState} from "react";
import {archiveTicketRelation} from "../../../../../../services/SupportService";


const DeleteModal = ({closeFunc, section, updateData, union}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteUnion = async () => {
        try {
            setIsDeleting(true)
            await archiveTicketRelation(union.id)
            await updateData(section.id)
            closeFunc(false)
        } catch (e) {
            console.log(e)
        } finally {
            setIsDeleting(false)
        }
    }

    return <ModalWindow
        closeFunc={closeFunc}
        title={'Удаление связи'}
        primaryButtonTitle={"Удалить"}
        type={'small'}
        primaryAction={() => deleteUnion()}
        primaryButtonIsDisabled={isDeleting}
    >
        <p className={s.wrap}>Вы действительно хотите удалить связь <span className={s.color}>
            {section.title} - {union.cTitle}
        </span>?</p>
    </ModalWindow>
}

export default DeleteModal