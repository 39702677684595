import {useState} from "react";
import {unbanUser} from "../../../../services/AuthService";

import s from "./TableRow.module.scss";
import f from "../../../../static/fonts.module.scss";

import BlockUserModal from "../BlockUserModal/BlockUserModal";
import DropdownButton from "../../../_BASE/UI/Button/DropdownButton/DropdownButton";
import RandomColorAvatar from "../../Dashboard/DashboardBlock/PersonList/SinglePerson/RandomColorAvatar/RandomColorAvatar";
import ModalWindow from "../../../_BASE/Layout/ModalWindow/ModalWindow";


const TableRow = ({rowData, getData}) => {
    const [blockModal, setBlockModal] = useState(false)
    const [unblockModal, setUnblockModal] = useState(false);

    const actions = [
        {
            label: 'Просмотр',
            type: 'link',
            link: `${rowData.id}`
        },
    ]

    if (!rowData.isDeleted) {
        actions.push({
            label: rowData.isBanned === 0 ? "Заблокировать" : "Разблокировать",
            func: () => rowData.isBanned === 0 ? setBlockModal(true) : setUnblockModal(true),
        })
    }



    return <>
        <div><RandomColorAvatar url={rowData.avatarLink}/></div>
        <p className={`${s.innerContent} ${f.subtitle1Bold}`}>{rowData.surname} {rowData.name} {rowData.middlename}</p>
        <p className={`${s.innerContent} ${f.subtitle1Bold}`}>{rowData.id}</p>
        <p className={`${s.innerContent} ${f.subtitle1Bold}`}>{rowData.email}</p>
        <p className={`${s.innerContent} ${f.subtitle1Bold}`}>{rowData.phone}</p>
        <p className={`${s.innerContent} ${f.subtitle1Bold}`}>{rowData.registrationDate}</p>

        <div style={{justifyContent: 'center', width: "100%", display: "flex"}}>
            <DropdownButton actions={actions} type={'icon'}/>
        </div>
        {
            blockModal && <BlockUserModal getData={getData} closeModalFunc={setBlockModal} userId={rowData.id}/>

        }
        {
            unblockModal && <ModalWindow
                type={'small'}
                closeFunc={setUnblockModal}
                title={`Разблокировать пользователя ${rowData.name}`}
                primaryButtonTitle={'Разблокировать'}
                primaryAction={async () => {
                    try {
                        await unbanUser(rowData.id)
                        setUnblockModal(false)
                        await getData()
                    } catch (e) {
                        throw e
                    }
                }
                }
            >
                <p className={`${f.body1}`}>Вы уверены?</p>
            </ModalWindow>
        }
    </>
}

export default TableRow
