import s from './SingleNew.module.scss'

import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import {getRecord, updateRecord, createRecord as createNews} from "../../../../services/NewsService";
import {uploadImage} from "../../../../services/FileService";
import {useNavigate} from "react-router";

import {notifications} from "../../../../index";
import FileInput from "../../../_BASE/UI/Input/FileInput/FileInput";
import Input from "../../../_BASE/UI/Input/Input";
import Button from "../../../_BASE/UI/Button/Button";
import Preloader from "../../../_BASE/UI/Preloader/Preloader";
import Select from "../../../_BASE/UI/Select/Select";
import TopNavbarTitle from "../../../../store/TopNavbarTitle";
import TitleWithBack from "../../../TopNavbar/TitleWithBack/TitleWithBack";


const SingleNew = ({edit}) => {
    const [isLoading, setIsLoading] = useState(true);

    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    const [imageObject, setImageObject] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [originData, setOriginData] = useState();
    const [selectedType, setSelectedType] = useState();

    const [type, setType] = useState();

    let {id} = useParams()
    const navigation = useNavigate()


    TopNavbarTitle.setNewTitle(
        <TitleWithBack title={edit
            ? "Изменение новости"
            : "Создание новости"}
        />
    )

    const getData = useCallback(async () => {
        const data = await getRecord(id)
        setImageUrl(data.imageLink)
        setTitle(data.title)
        setDesc(data.body)
        setOriginData(data)
        setType(
            data.category === "Новости"
                ? "news"
                : selectedType === "Помогаем учиться"
                    ? "help"
                    : 'all'
        )
    }, [id, selectedType])

    useEffect(() => {
        if (edit) {
            setIsLoading(true)
            getData()
            setIsLoading(false)
        } else {
            setIsLoading(false)
        }

    }, [edit, getData])

    const editRecord = async () => {
        try {
            if (imageObject) {
                const icon = await uploadImage(imageObject)
                await updateRecord(title, desc, {
                    imageId: icon.fileId,
                    imageLink: icon.fileLink
                }, type, id)
                navigation('/blog#news')
            } else {
                await updateRecord(title, desc,
                    {
                        imageId: originData.imageId,
                        imageLink: originData.imageLink
                    },
                    type, id
                )
                navigation('/blog#news')
            }
        } catch (e) {
            throw e
        }
    }

    const createRecord = async () => {
        try {
            if (imageObject) {
                const icon = await uploadImage(imageObject)
                if (title?.length < 3) {
                    notifications.addNotification("Слишком короткий заголовок")
                    return
                }
                if (desc?.length < 3) {
                    notifications.addNotification("Слишком короткий текст")
                    return
                }
                if (!selectedType) {
                    notifications.addNotification("Не указан раздел")
                    return
                }
                await createNews(title, desc, icon,
                    selectedType === "Новости"
                        ? "news"
                        : selectedType === "Помогаем учиться"
                            ? "help"
                            : 'all'
                )
                navigation('/blog#news')
            } else {
                notifications.addNotification('Укажите изображение')
            }

        } catch (e) {
            throw e
        }
    }

    if (isLoading) {
        return <Preloader type={'big'}/>
    }

    return (
        <div className={s.wrap}>
            <FileInput imageObject={imageObject} setImageObject={setImageObject}
                       imageUrl={imageUrl} setImageUrl={setImageUrl} size={64}/>
            {
                !edit && <Select title={'Укажите тип создаваемой записи'} options={
                    ["Помогаем учиться", "Новости", "Для всех"]
                } selectedOption={selectedType} setSelectedOption={setSelectedType}/>
            }
            <Input title={"Укажите заголовок записи"} styles={{maxWidth: 800}} value={title} setValue={setTitle}/>
            <Input title={'Укажите описание записи'} styles={{maxWidth: 800}} type={'textarea'} value={desc}
                   setValue={setDesc}/>

            <div>
                <Button text={edit ? "Изменить запись" : 'Создать запись'}
                        click={
                            edit
                                ? () => editRecord()
                                : () => createRecord()
                        }
                />
            </div>
        </div>
    )
}

export default SingleNew