import $auth from "../http";
import {notifications} from "../index";

//Получение списка активных предметов по типу заведения
export const getSubjectList = async (type, status = 1) => {
    try {
        const res = await $auth.get(`/v1/competency/list/subject?workCode=${type}&status=${status}`,)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получение списка опций по предмету с SubjectId
export const getOptionsListBySubjectID = async (subjectType, subjectId) => {
    try {
        const res = await $auth.get(`/v1/competency/list/subject/options?subjectType=${subjectType}&subjectId=${subjectId}&status=1`,)
        return res.data
    } catch (e) {
        throw e
    }
}

//Регистрация нового профиля компетенций
export const registerNewCompetencyProfile = async (data) => {
    try {
        const res = await $auth.post(`/v1/competency/signup`, data)
        notifications.addNotification(res.data.message, "OK")
        return res
    } catch (e) {
        throw e
    }
}

//Создание компетенций для пользователя
export const createNewUserCompetencies = async (profileId, compArray, userId) => {
    try {
        return await $auth.post(`/v1/competency/teacher/create/${profileId}?competency=${compArray.join(',')}&userId=${userId}`)
    } catch (e) {
        throw e
    }
}

//Создание нового предмета
export const createNewSubject = async (workCode, iconId, iconLink, title, status) => {
    try {
        return await $auth.post(`/v1/competency/subject/create`, {
            workCode: workCode,
            iconId: iconId,
            iconLink: iconLink,
            title: title,
            status: status
        })
    } catch (e) {
        throw e
    }
}

//Изменение статуса активности предмета
export const updateSubjectStatus = async (subjectId, status) => {
    try {
        return await $auth.put(`/v1/competency/subject/update/status/${subjectId}`, {
            status: status
        })
    } catch (e) {
        throw e
    }
}

//Изменение информации у предмета
export const updateSubjectInfo = async (subjectId, iconId, iconLink, title) => {
    try {
        return await $auth.put(`/v1/competency/subject/update/${subjectId}`, {
            iconId: iconId,
            iconLink: iconLink,
            title: title
        })
    } catch (e) {
        throw e
    }
}

//Получение списка опций
export const getOptionsList = async (optionsCode) => {
    try {
        const res = await $auth.get(`/v1/competency/subject/options/list?optionsCode=${optionsCode}&status=1`,)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получение компетенций учителя
export const getUserCompetencies = async (profileId) => {
    try {
        const res = await $auth.get(`/v1/competency/teacher/get/${profileId}`)
        if (typeof res.data === 'string') {
            notifications.addNotification(res.data)
            return {data: []}
        }
        return res
    } catch (e) {
        throw e
    }
}

//Получение профиля компетенций пользователей
export const getUserCompetenciesProfile = async (userId, position) => {
    try {
        return await $auth.get(`/v1/competency/users/${userId}?workPosition=${position}`)
    } catch (e) {
        throw e
    }
}

//Получение файлов, которые загрузил учитель для подтверждения компетенции
export const getTeacherCompetencyDocuments = async (userId) => {
    try {
        const data = await $auth.get(`/v1/competency/users/documents/${userId}`)
        return data.data
    } catch (e) {
        throw e
    }
}

//Изменение информации у опции предмета
export const updateOptionTitle = async (optionId, title, optionsCode) => {
    try {
        return await $auth.put(`/v1/competency/subject/options/update/${optionId}`, {
            title: title,
            optionsCode: optionsCode
        })
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Создание новых опций у предмета (классов и типов задач)
export const createOption = async (title, optionsCode) => {
    try {
        return await $auth.post(`/v1/competency/subject/options/create`, {
            title: title,
            optionsCode: optionsCode
        })
    } catch (e) {
        notifications.addNotification(e.response.data.message)
        throw e
    }
}

//Удаление компетенций учителя
export const deleteCompetency = async (profileID, userId, compIdArray) => {
    try {
        const res = await $auth.delete(`/v1/competency/teacher/remove/${profileID}?userId=${userId}&competency=${compIdArray.join(',')}`)
        notifications.addNotification(res.data.removeRecord, 'OK')
        return res
    } catch (e) {
        throw e
    }
}


//Проверка статуса подтверждения профиля компетенций учителя
export const checkTeacherCompetencyStatus = async (id) => {
    try {
        const res = await $auth.get(`/v1/competency/users/confirm/${id}`)
        return res.data.status
    } catch (e) {
        throw e
    }
}

//Изменение статуса подтверждения статуса профиля компетенций учителя
export const updateTeacherCompetencyStatus = async (id, status) => {
    try {
        const res = await $auth.put(`/v1/competency/users/confirm/update/${id}?status=${status}`)
        notifications.addNotification(res.data.status, 'OK')
    } catch (e) {
        throw e
    }
}


//Получение списка архивированных агрегационных связей
export const getArchiveRecords = async (optionsCode, page, offset) => {
    try {
        const res = await $auth.get(`/v1/competency/subject/aggregation/archive/list?optionsCode=${optionsCode}&page=${page}&offset=${offset}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получение количества архивированных агрегационных записей и количества страниц
export const getArchivePaginationInfo = async (optionsCode, offset) => {
    try {
        const res = await $auth.get(`/v1/competency/subject/aggregation/archive/list/page?optionsCode=${optionsCode}&offset=${offset}`)
        return res.data
    } catch (e) {
        throw e
    }
}


//Архивирование и разархивирование агрегационных записей
export const updateArchiveStatus = async (recordId, optionsCode, status) => {
    try {
        const res = await $auth.put(`/v1/competency/subject/aggregation/archive/${recordId}?optionsCode=${optionsCode}&status=${status}`)
        notifications.addNotification(res.data.status, 'OK')
    } catch (e) {
        throw e
    }
}

//Получение количества архивированных агрегационных записей и количества страниц
export const getCompetencyBanReasons = async () => {
    try {
        const res = await $auth.get(`/v1/competency/teacher/block/reasons/list`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Создание новой причины блокировки компетенции учителя
export const createCompetencyBanReason = async (title) => {
    try {
        const res = await $auth.post(`/v1/competency/teacher/block/reasons/create`, {
            title: title
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        throw e
    }
}

//Обновление причины блокировки компетенции учителя
export const updateCompetencyBanReason = async (id, title) => {
    try {
        const res = await $auth.put(`/v1/competency/teacher/block/reasons/update`, {
            title: title,
            id: id
        })
        notifications.addNotification(res.data.message, 'OK')
    } catch (e) {
        throw e
    }
}

//Архивирование причины блокировки компетенции учителя
export const deleteCompetencyBanReason = async (id) => {
    try {
        const res = await $auth.put(`/v1/competency/teacher/block/reasons/archive/${id}`,)
        notifications.addNotification(res.data.status, 'OK')
    } catch (e) {
        throw e
    }
}

//Блокировка компетенции у учителя
export const blockTeacherCompetency = async (teacherId, reasonsId, competencyCode, competencyId, comment) => {
    try {
        const res = await $auth.post(`/v1/competency/teacher/block`, {
            teacherId: teacherId,
            reasonsId: reasonsId,
            competencyCode: competencyCode,
            competencyId: competencyId,
            comment: comment,
        })
        if (res.status === 200) {
            notifications.addNotification('Компетенция успешно заблокирована', 'OK')
        }
    } catch (e) {
        throw e
    }
}

//Получение списка причин блокировки компетенции у учителя
export const getCompetencyBlockReasons = async () => {
    try {
        const res = await $auth.get(`/v1/competency/teacher/block/reasons/list`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получение списка заблокированных компетенций у учителя
export const getTeacherBlockedCompetencies = async (id) => {
    try {
        const res = await $auth.get(`/v1/competency/teacher/block/${id}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Разблокировка компетенции у учителя
export const unblockTeacherCompetency = async (id) => {
    try {
        const res = await $auth.delete(`/v1/competency/teacher/unblock/${id}`)
        if (res.status === 200) notifications.addNotification('Запись успешно разблокирована', 'OK')
    } catch (e) {
        throw e
    }
}

//Получение списка ТОП-6 популярных услуг
export const getTop6Competencies = async (studentCode) => {
    try {
        const res = await $auth.get(`/v1/competency/top?studentCode=${studentCode}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Удаление популярных услуг из ТОП-6
export const deleteTop6Competencies = async (studentCode, recordId) => {
    try {
        const res = await $auth.delete(`/v1/competency/top/remove?studentCode=${studentCode}&recordId=${recordId}`)
        notifications.addNotification("Запись успешно удалена", 'OK')
        return res
    } catch (e) {
        throw e
    }
}


//Создание новой записи в популярных услугах
export const createTop6Competencies = async (studentCode, aggRelId) => {
    try {
        const res = await $auth.post(`/v1/competency/top/create`, {
            studentCode: studentCode,
            recordId: aggRelId
        })
        if (res.status === 200) {
            notifications.addNotification('Запись успешно создана', 'OK')
        }
    } catch (e) {
        throw e
    }
}


//Получение списка профилей компетенций учителей
export const getTeachers = async (page, offset, activeCode) => {
    try {
        const res = await $auth.get(`/v1/competency/teacher/list?page=${page}&offset=${offset}&activeCode=${activeCode}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Получение количества профилей компетенций учителей и количества страниц
export const getTeachersCount = async (activeCode) => {
    try {
        return await $auth.get(`/v1/competency/teacher/list/page?offset=20&activeCode=${activeCode}`)
    } catch (e) {
        throw e
    }
}

//Получение списка опций у предмета
export const getSubjectOptions = async (subjectType, subjectId, status) => {
    try {
        const data = await $auth.get(`/v1/competency/list/subject/options?subjectType=${subjectType}&subjectId=${subjectId}&status=${status}`)
        return data.data
    } catch (e) {
        throw e
    }
}


//Обновление статуса и цены у связей ПРЕДМЕТ-КЛАСС или ПРЕДМЕТ-ТИП_ЗАДАЧИ
export const updateCompetencyRecord = async (recordID, optionsCode, price, status, originality) => {
    try {
        const res = await $auth.put(`/v1/competency/subject/aggregation/update/${recordID}`, {
            optionsCode: optionsCode,
            price: price,
            status: status,
            originality: originality
        })
        notifications.addNotification(res.data.status, 'OK')
    } catch (e) {
        throw e
    }
}

//Создание новой агрегации (Предмета и Опций)
export const createCompetencyRecord = async (optionsCode, subjectId, optionId, price, status, originality) => {
    try {
        const res = await $auth.post(`/v1/competency/subject/aggregation/create?optionsCode=${optionsCode}&subjectId=${subjectId}&optionsId=${optionId}&price=${price}&status=${status}&originality=${originality}`)
        return res.data
    } catch (e) {
        throw e
    }
}

//Обновление статуса у опции предмета (Удаление или восстановление)
export const deleteOption = async (optionsCode, optionId) => {
    try {
        await $auth.delete(`/v1/competency/subject/options/delete/${optionId}?optionsCode=${optionsCode}`)
        notifications.addNotification('Запись успешно удалена', 'OK')
    } catch (e) {
        throw e
    }
}

export const deletePersonalDocuments = async (userAuthId) => {
    try {
        await $auth.delete(`/v1/competency/users/documents/${userAuthId}`)
        notifications.addNotification('Запись успешно удалена', 'OK')
    } catch (e) {
        throw e
    }
}

export const createPersonalDocuments = async (photoOneId, photoOneLink, photoTwoId, photoTwoLink, userId) => {
    try {
        const req = await $auth.post(`/v1/competency/users/documents`,{
            photoOneId: photoOneId,
            photoOneLink: photoOneLink,
            photoTwoId: photoTwoId,
            photoTwoLink: photoTwoLink,
            userId: userId
        })
        notifications.addNotification('Документы загружены', 'OK')
        return req.data
    } catch (e) {
        throw e
    }
}